import React, {useContext, useEffect, useState} from "react";
import {MainContextProvider} from "../../../context/MainContext";
import {getAccessToken} from "../../../service/token/token";
import axios from "axios";
import {ReactSelect} from "../../../components/UI";
import customAxios from "../../../service/api/interceptors";
import {useLocation} from "react-router-dom";
import {intercomCatchErrorMessage} from "../../../helpers/intercomCatchErrorMessage";
import {useIntercom} from "react-use-intercom";

const Months = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' },
]

export const MonthlyClientReport: React.FC = () => {
    const month = (new Date()).getMonth() + 1
    //const month = 4
    const year = (new Date()).getFullYear()
    const location = useLocation()
    const search = location.search;
    const params = new URLSearchParams(search);

    const { setLoading } = useContext(MainContextProvider)
    const [file, setFile] = useState(null)
    const [startDate, setStartDate] = useState(1)
    const [endDate, setEndDate] = useState(month)
    const [associations, setAssociations] = useState<{value: string, label: string}[]>([])
    const [association, setAssociation] = useState(null)
    const { showNewMessages } = useIntercom()

    const getAssociations = async () => {
        try {
            const { status, data } = await customAxios.get(`company/company/company-associations`)
            if (status === 200) {
                setAssociations((data || []).map((el: any) => ({ value: el.id, label: el.name })))
                setAssociation(data[0].id)
            }
        } catch (e) {
            await showNewMessages(intercomCatchErrorMessage(e))
        }
    }

    useEffect(() => {
        getAssociations().then()
    }, [])

    const getFile = async () => {
        setLoading(true)
        const accessToken = getAccessToken()
        const startDate1 = '2023-' + startDate + '-01'
        const endDate1 = '2023-' + (+endDate + 1) + '-01'

        try {
            const { status, data } =
                await axios.get(`${process.env.REACT_APP_BASE_API}/report/report/monthly-client-report?start_date=${(+(new Date('2023-' + startDate + '-01')) / 1000) + 7200}&finish_date=${(+(new Date('2023-' + (+startDate + 1) + '-1')) / 1000) + 72000}&company_association_id=${association}`, {
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            })
            if(status === 200) {
                setFile(data)
            }
        } catch (e) { await showNewMessages(intercomCatchErrorMessage(e)) }
        setLoading(false)
    }

    useEffect(() => {

    }, [])

    const downloadFile = () => {
        // @ts-ignore
        const url = window.URL.createObjectURL(new Blob([file]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'report.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
    }

    return (
        <div>
            <p className={'mt15'} style={{ fontSize: '13px', lineHeight: '21px' }}>This report details your VLO's billable work undertaken in the selected calendar month.
                Please note that it may take up to 48 hours for details to populate.
                You can view other monthly reports by contacting <a href="mailto:invoicing@legalnodes.com">invoicing@legalnodes.com.</a></p>
            {
                file && <button onClick={downloadFile} className={'btn-black w100 mt25 text-uppercase'}>Download Report (PDF)</button>
            }
            <div className={'mt15'}>
                {associations.length > 1 && !file && <ReactSelect
                    label={'Company'}
                    value={association + ''}
                    options={associations}
                    onChange={el => {
                        setAssociation(el.value)
                    }}
                />}
            </div>
            {!file && <div className={'flex mt15'}>
                <div className="w100">
                    <ReactSelect
                        label={'Select a Month'}
                        value={+startDate}
                        options={Months.map(el => ({ ...el, label: `${el.label} ${year}` })).filter(item => item.value <= endDate)}
                        onChange={el => {
                            setStartDate(el.value)
                        }}
                    />
                </div>
                {/*<div className="w50" style={{ paddingLeft: '5px' }}>
                    <ReactSelect
                        label={'End date'}
                        value={+endDate}
                        options={Months.map(el => ({ ...el, label: `${el.label} ${year}` })).filter(item => item.value <= month)}
                        onChange={el => {
                            setEndDate(el.value)
                            if (startDate > el.value) setStartDate(el.value)
                        }}
                    />
                </div>*/}
            </div>}
            {
                !file && <button onClick={getFile} className={'btn-black w100 mt15 text-uppercase'}>Get link</button>
            }
        </div>
    )
}