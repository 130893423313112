import React, {useContext, useEffect, useState} from "react";
import {getCompanyStatus} from "../../../components/other/ClientsSearchDropdown/ClientsSearchDropdown";
import moment from "moment";
import {MainContextProvider} from "../../../context/MainContext";
import {CompanyType} from "../../../types/company.type";
import {clientSubscriptionType} from "./ManagerKanban";
import customAxios from "../../../service/api/interceptors";

type Props = {
    clients: CompanyType[]
    searchParams: CustomSearchParams
}

type CreditsType = {
    total_count: number,
    covered_by_monthly_plan: number,
    purchased: number,
    expiration_purchased: number
    expiration_covered_by_monthly_plan: number | null
}

interface CustomSearchParams extends URLSearchParams {
    get(name: 'client'): string | null;
}

export const TabSubscriptionInfo: React.FC<Props> = ({ clients, searchParams }) => {
    const { userData } = useContext(MainContextProvider)
    const [clientSubscription, setClientSubscription] = useState<clientSubscriptionType>({} as clientSubscriptionType)
    const [credits, setCredits] = useState<CreditsType>({} as CreditsType)
    const [expirationDate, setExpirationDate] = useState("")

    const getClientSubscriptionData = async () => {
        const { data: date } = await customAxios.get(`/company/${searchParams.get('client')}/subscription/expiration-date`)
        const { data: creditData } = await customAxios.get(`/company/${searchParams.get('client')}/credits`)
        const { status, data } = await customAxios.get(`/company/${searchParams.get('client') || clients[0].id}/subscription`)
        if (status === 200) {
            setClientSubscription(data)
            setCredits(creditData)
            setExpirationDate(date?.expiration_date)
        }
    }

    useEffect(() => {
        if (clients.length === 0) {
            return
        }
        getClientSubscriptionData().then()
    }, [clients, searchParams.get('client')])

    return (
        <div className={"flex alignCenter"} style={{ flexGrow: 1, justifyContent: "space-around" }}>
            {
                <div
                    style={{
                        color: getCompanyStatus(clients.find((el) => String(el.id) === (searchParams.get('client') || String(clients[0].id)))?.debt || '').color,
                        background: getCompanyStatus(clients.find(el => String(el.id) === (searchParams.get('client') || String(clients[0].id)))?.debt || '').background,
                        borderRadius: '20px',
                        padding: '8px 18px',
                        fontSize: '13px',
                        textTransform: 'uppercase'
                    }}>{getCompanyStatus(clients.find(el => String(el.id) === (searchParams.get('client') || String(clients[0].id)))?.debt || '').label}</div>
            }
            <span style={{
                fontSize: '12px',
                fontFamily: 'Inter',
                lineHeight: "15px"
            }}><div>Credit Balance:</div><div>{credits.total_count}</div></span>
            <span style={{
                fontSize: '12px',
                fontFamily: 'Inter',
                lineHeight: "15px"
            }}><div>Monthly credits:</div><div>{credits.covered_by_monthly_plan} {credits.expiration_covered_by_monthly_plan && <em>(Expires <b className={"text-weight-600"}>{moment(new Date(+(credits.expiration_covered_by_monthly_plan || 0) * 1000)).format('DD/MM/YYYY')})</b></em>}</div></span>
                {clientSubscription?.activate_date && userData?.status.type_code !== 100 &&
                    <>
                     <span style={{ fontSize: '12px', fontFamily: 'Inter', marginLeft: '15px', lineHeight: "15px" }}><div>{clientSubscription?.title}</div>
                         <div>Expires <b className={"text-weight-600"}>{expirationDate}</b></div>
                     </span>
                    </>
                }
        </div>
    )
}