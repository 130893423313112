import React from "react";
import loaderIcon from "./loaderIcon.svg"
import "./custom_loader.scss"

export const CustomLoader: React.FC = () => {
    return (
        <div className={'custom_loader'}>
            <div>
                <img src={loaderIcon} alt=""/>
            </div>
            <span>Looking for results...</span>
        </div>
    )
}