import { useContext, useState, useEffect } from 'react'
import { MainContextProvider } from '../context/MainContext'
import { UserType } from '../enums/userType'

const useIsUserTypeClient = () => {
  const { role } = useContext(MainContextProvider)

  const [isUserTypeClient, updateIsUserTypeClient] = useState<Boolean>(false)

  useEffect(() => {
    const isClient = role === UserType.client
    updateIsUserTypeClient(isClient)
  }, [role])

  return {
    isUserTypeClient,
  }
}

export default useIsUserTypeClient
