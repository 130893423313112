import React, {useContext, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'

import TasksKanbanItem from './TasksProjectKanbanItem'

import { TasksProjectKanbanGridType } from './tasksProjectKanbanGrid.type'

import './tasksProjectKanbanGrid.scss'
import {ClientKanbanItem} from "../../../../../components/other/ClientKanbanBoard/ClientKanbanItem/ClientKanbanItem";
import {ClientKanbanCreateButton} from "../../../../../components/other/ClientKanbanBoard/ClientKanbanCreateButton/ClientKanbanCreateButton";
import {useHistory} from "react-router-dom";
import {MainContextProvider} from "../../../../../context/MainContext";
import leftArrow from "../../../../../assets/images/kanbanIcons/black-arrow-to-left.svg"
import rightArrow from "../../../../../assets/images/kanbanIcons/black-arrow-to-right.svg"
import burgerIcon from "../../../../../assets/images/kanbanIcons/burger-icon.svg"
import {MobileHeading} from "../../../../../components/other/Mobile/MobileHeading/MobileHeading";

export const switchNextSteps = (accepted: number) => {
  switch (accepted) {
    case 0: return 'Preparing Offer';
    case 1: return 'Awaiting VLO’s reply';
    case 2: return 'Offer has to be accepted';
    case 3: return 'Awaiting payment';
    case 5: return 'Preparing document';
    default: return ''
  }
}

const TasksProjectKanbanGrid:React.FC<any> = ({ kanbanTasks, kanbanTasksCount, kanbanStatuses, isCreateLegalRequestButton, isCreateOfferButton, modalKey, updateBoard }) => {
  const { t } = useTranslation()
  const { userData, setModalType, isUnSubscribe } = useContext(MainContextProvider)
  const [issTouchStart, setIsTouchStart] = useState(false)
  const [firstPosition, setFirstPosition] = useState(null)
  const [lastPosition, setLastPosition] = useState(null)
  const [activeColumn, setActiveColumn] = useState(0)
  const history = useHistory()
  const containerWidth = window.innerWidth - 60;
  const columnInlineStyle = window.innerWidth < 460 ? { width: (activeColumn === 0) ? (containerWidth + 30) : containerWidth, flexShrink: 0, padding: '0 0px' } : {}
  const [count, setCount] = useState(0)
  const isShowCreateButton = !isUnSubscribe && isCreateLegalRequestButton

  const changePosition = (e: any) => {
    if(e && e.touches && e.touches[0]) {
      if(!firstPosition) {
        setFirstPosition(e.touches[0].clientX)
      } else {
        setLastPosition(e.touches[0].clientX)
      }
    }
  }

  const endTouch = () => {
    if(!firstPosition || !lastPosition) return;
    if((firstPosition > lastPosition) && ((firstPosition - lastPosition) > 50) && activeColumn !== 3) {
      setActiveColumn(prevState => prevState + 1)
    } else if(firstPosition < lastPosition && ((lastPosition - firstPosition) > 50) && activeColumn !== 0){
      setActiveColumn(prevState => prevState - 1)
    }
    setIsTouchStart(false)
    setFirstPosition(null)
    setLastPosition(null)
  }

  function getTotalCount() {
    let kanbanCount = 0
    Object.keys(kanbanTasks).map(key => {
      kanbanCount += kanbanTasks[key].length
    })
    return kanbanCount
  }

  return (
      <div>
        <div className={'tasks-project-kanban-grid__mobile-nav'}>
          <button disabled={activeColumn === 0} onClick={() => setActiveColumn(activeColumn - 1)}>
            <img src={leftArrow} alt=""/>
          </button>
          <span>{Array.isArray(Object.keys(kanbanTasks)) && Object.keys(kanbanTasks)[activeColumn]}</span>
          <button disabled={activeColumn === Object.keys(kanbanTasks).length - 1} onClick={() => setActiveColumn(activeColumn + 1)}>
            <img src={rightArrow} alt=""/>
          </button>
        </div>
        <div className={`tasks-project-kanban-grid total-count-${getTotalCount()}`} style={{ marginLeft: `-${activeColumn * (containerWidth - (activeColumn === 1 ? 30 : 15))}px` }}>
          <div style={columnInlineStyle}
               onTouchStart={e => setIsTouchStart(true)}
               onTouchMove={e => changePosition(e)}
               onTouchEnd={endTouch}
               className={'tasks-project-kanban-grid__item tasks-project-kanban-grid-item'}>
            <div className={'tasks-project-kanban-grid-item__header'}>
              <h5 className={'tasks-project-kanban-grid-item__title'}>
                New requests from client
              </h5>
              <span className={'tasks-project-kanban-grid-item__count'}>
            {kanbanTasks["business need to discuss"].length}/{kanbanTasksCount["business need to discuss"]?.count}
          </span>
            </div>
            <div className={'tasks-project-kanban-grid-item__body '}>
              {isShowCreateButton && <ClientKanbanCreateButton
                  modalKey={modalKey}
                  onClick={userData?.status.type_code === 300 ? () => setModalType('create_legal_request') : false}
              />}
              {
                kanbanTasks["business need to discuss"].map((item: any, index: number) =>
                    <ClientKanbanItem
                        colIndex={index}
                        className={'status_business need to discuss'}
                        to={`/legal-request/${item.id}`}
                        key={item.id}
                        { ...item }
                        statusText={'-1'}
                        nextStep={item.status === 300 ? 'Preparing Offer' : 'Discuss with VLO'}
                        stars={[1,0,0,0,0]}
                        onClick={() => history.push(`/legal-request/${item.id}`)}
                        isWaitIcon={userData?.status.type_code === 100 && +item.status === 300}
                        notifications={+item.notifications}
                        comments={+item.comments}
                        updateBoard={updateBoard}
                    />)
              }
            </div>
          </div>

          <div
              onTouchStart={e => setIsTouchStart(true)}
              onTouchMove={e => changePosition(e)}
              onTouchEnd={endTouch}

              style={columnInlineStyle} className={'tasks-project-kanban-grid__item'}>
            <div className={'tasks-project-kanban-grid-item__header'}>
              <h5 className={'tasks-project-kanban-grid-item__title'}>
                New offers from VLO
              </h5>
              <span className={'tasks-project-kanban-grid-item__count'}>
            {kanbanTasks["offers (pending)"].length}/{kanbanTasksCount["offers (pending)"]?.count}
          </span>
            </div>
            <div className={'tasks-project-kanban-grid-item__body'}>
              {/* {isCreateOfferButton && <ClientKanbanCreateButton modalKey={modalKey} />}*/}
              {
                kanbanTasks["offers (pending)"].map((item: any, index: number) =>
                    <ClientKanbanItem
                        colIndex={index}
                        to={`/offer/${item.id}`}
                        key={item.id}
                        { ...item }
                        statusText={(userData?.status.type_code === 300 || userData?.status.type_code === 400) && item.accepted}
                        nextStep={switchNextSteps(+item.accepted)}
                        stars={[1,1,0,0,0]}
                        onClick={() => history.push(`/offer/${item.id}`)}
                        isWaitIcon={userData?.status.type_code === 100 && (+item?.accepted === 1 || +item?.accepted === 0)}
                        notifications={+item.notifications}
                        comments={+item.comments}
                        updateBoard={updateBoard}
                    />)
              }
            </div>
          </div>

          <div
              onTouchStart={e => setIsTouchStart(true)}
              onTouchMove={e => changePosition(e)}
              onTouchEnd={endTouch}

              style={columnInlineStyle} className={'tasks-project-kanban-grid__item'}>
            <div className={'tasks-project-kanban-grid-item__header'}>
              <h5 className={'tasks-project-kanban-grid-item__title'}>
                Tasks in progress
              </h5>
              <span className={'tasks-project-kanban-grid-item__count'}>
            {kanbanTasks["tasks assigned"].length}/{kanbanTasksCount["tasks assigned"]?.count}
          </span>
            </div>
            <div className={'tasks-project-kanban-grid-item__body'}>
              {
                kanbanTasks["tasks assigned"].map((item: any, index: number) =>
                    <ClientKanbanItem
                        colIndex={index}
                        to={`/offer/${item.id}`}
                        key={item.id}
                        { ...item }
                        statusText={'9'}
                        nextStep={switchNextSteps(+item.accepted)}
                        stars={[1,1,1,0,0]}
                        onClick={() => history.push(`/offer/${item.id}`)}
                        isWaitIcon
                        notifications={+item.notifications}
                        comments={+item.comments}
                        updateBoard={updateBoard}
                    />)
              }
            </div>
          </div>

          <div
              onTouchStart={e => setIsTouchStart(true)}
              onTouchMove={e => changePosition(e)}
              onTouchEnd={endTouch}

              style={columnInlineStyle} className={'tasks-project-kanban-grid__item'}>
            <div className={'tasks-project-kanban-grid-item__header'}>
              <h5 className={'tasks-project-kanban-grid-item__title'}>
                Tasks complete
              </h5>
              <span className={'tasks-project-kanban-grid-item__count'}>
            {kanbanTasks["tasks done"].length}/{kanbanTasksCount["tasks done"]?.count}
          </span>
            </div>
            <div className={'tasks-project-kanban-grid-item__body'}>
              {
                kanbanTasks["tasks done"].map((item: any, index: number) =>
                    <ClientKanbanItem
                        colIndex={index}
                        to={`/offer/${item.id}`}
                        key={item.id}
                        { ...item }
                        statusText={'4'}
                        nextStep={''}
                        stars={[1,1,1,1,1]}
                        onClick={() => history.push(`/offer/${item.id}`)}
                        notifications={+item.notifications}
                        comments={+item.comments}
                        is_done={true}
                        updateBoard={updateBoard}
                    />)
              }
            </div>
          </div>
        </div>
      </div>
  )
}

export default TasksProjectKanbanGrid
