import React, {useContext} from "react";
import plusIcon from '../../../../assets/images/createIcon.svg'
import './ClientKanbanCreateButton.scss'
import {MainContextProvider} from "../../../../context/MainContext";

export const ClientKanbanCreateButton: React.FC<any> = ({ modalKey, onClick }) => {
    const { setModalType, userData} = useContext(MainContextProvider)

    return (
        <>
            {!userData?.isTrial &&
                <div onClick={onClick ? onClick : () => {
                    setModalType(modalKey || 'client_kanban_request')
                }} className={'client-kanban-create-button'}>
                    <div className="client-kanban-create-button__container">
                        <div className="client-kanban-create-button__icon">
                            <img src={plusIcon} alt=""/>
                        </div>
                        <div className="client-kanban-create-button__text">
                            NEW REQUEST
                        </div>
                    </div>
                </div>
            }
        </>
    )
}