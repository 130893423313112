import React from 'react'
import checkboxIcon from '../../../assets/images/checkbox.svg'
import './checkbox.scss'
import { SingleCheckboxItem } from './Checkbox.type'
import notificationItem from '../../../assets/images/icons/notification-item.svg'

const CheckboxItem:React.FC<SingleCheckboxItem> = ({
  active, label, onChange, className, disabled,
}) => (
  <div
    className={`${className} checkbox__item`}
    onClick={() => onChange()}
  >
    <div className={`checkbox__item-element ${active && 'checkbox__item-active'} ${disabled && 'checkbox__item-disabled'}`}>
      {active && !disabled && <img src={checkboxIcon} alt={''} />}
      {disabled && active && <img style={{ opacity: !active ? 0.2 : 1 }} src={notificationItem} alt={''} />}
    </div>
    <span dangerouslySetInnerHTML={{ __html: label || '' }} className={'checkbox__item-label'} />
  </div>
)

export default CheckboxItem
