import React from 'react'
import { CSSTransition } from 'react-transition-group'
import { ModalContainerType } from './modal_types'
import closeIcon from '../../assets/images/modal-close.svg'
import './modal.scss'

const ModalContainer:React.FC<ModalContainerType> = ({
  close,
  children,
  isOpen,
  heading,
  headingClass,
  modalClass,
  modalData,
}) => {
  const {
    configTitle = '',
    configHeadingClass = '',
  } = modalData || {}

  return (
    <CSSTransition in={isOpen} timeout={{ enter: 200, exit: 200 }} classNames={'my-node'} unmountOnExit>
      <div className={`${modalClass} modal`}>
        <div className={'modal__container'}>
          <div className={'modal__container__header'}>
            <h2 className={configHeadingClass || headingClass}>{configTitle || heading}</h2>
            {!modalData?.hideClose && <img onClick={close} className={'modal__container__header-close'} src={closeIcon} alt={''} />}
          </div>
          <div className={'modal__body'}>
            {children}
          </div>
        </div>
        <div onClick={close} className={'modal__bg'} />
      </div>
    </CSSTransition>
  )
}

export default ModalContainer
