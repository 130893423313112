import React, { useContext } from 'react'
import { MainContextProvider } from '../../context/MainContext'
import ModalContainer from './ModalContainer'
import ALL_MODALS from './AllModals'

const ModalConfig:React.FC<any> = (props) => {
  const {
    modalType, modalData, setModalData, setModalType,
  } = useContext(MainContextProvider)

  const closeModal = () => {
    if (modalData && modalData.hideClose) {
      return false
    }
    setModalType('')
    setTimeout(() => setModalData(null), 200)
  }

  const chModalType = props.modalType || modalType;
  const chModalData = modalData || props.modalData

  return (
    <>
      {
       ALL_MODALS.map(({ type, Component, modalProps }) => (
         <ModalContainer
           key={type}
           close={props.closeModal || closeModal}
           isOpen={chModalType === type}
           {...modalProps}
           modalData={chModalData}
           {...props}
         >
           <Component
             {...chModalData}
             modalData={chModalData}
             {...props}
           />
         </ModalContainer>
       ))
      }
    </>
  )
}

export default ModalConfig
