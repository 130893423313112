import React, {useContext, useEffect, useState} from "react";
import {MainContextProvider} from "../../../context/MainContext";
import {CommentsContainer} from "../../../components/other/CommentsContainer/CommentsContainer";
import {OfferHeader} from "../../../components/other/ClientKanbanData/OfferHeader/OfferHeader";
import {OfferHeading} from "../../../components/other/ClientKanbanData/OfferHeading/OfferHeading";
import {LegalTeam} from "../../../components/other/ClientKanbanData/LegalTeam/LegalTeam";
import {TaskTabList} from "../../../components/other/ClientKanbanData/TaskTabList/TaskTabList";
import Tabs from "../../../components/other/Tabs";
import {OfferFooter} from "../../../components/other/ClientKanbanData/OfferFooter/OfferFooter";
import {
    createLegalRequestCommentService,
    createOfferFromRequestService,
    getLegalRequestService
} from "../../../service/client/legalRequest";
import {LegalRequestFooter} from "../../../components/other/ClientKanbanData/LegalRequestFooter/LegalRequestFooter";
import {useHistory} from "react-router-dom";
import bottomIcon from "../../../assets/images/backBlackIcon.svg";
import pushNotifications from "../../../helpers/pushNotifications";
import {ClientKanbanStepNotifications} from "../../../components/other/ClientKanbanBoard/ClientKanbanStepNotifications/ClientKanbanStepNotifications";
import {FrozenController} from "../../../components/other/FrozenController/FrozenController";
import customAxios from "../../../service/api/interceptors";
import {toastifySuccess} from "../../../service/toastify/toastify";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";
import {useIntercom} from "react-use-intercom";
import {intercomCatchErrorMessage} from "../../../helpers/intercomCatchErrorMessage";
import waitIcon from '../../../assets/images/stepsIcons/wait-white.svg'
import {GroupComments} from "../../../components/other/GroupComments/GroupComments";
import readMoreIcon from "../../../assets/images/icons/readMoreBlue.svg";
import {TaskDeliverablesForOffer} from "../Offer/ccomponents/TaskDeliverablesForOffer/TaskDeliverablesForOffer";

export type LegalRequestType = {
    id: number
    description: string
    status: number
    title: string
    manager: {
        name: string
        photos: string[]
    }
    legalRequestComments: any[]
}

export const LegalRequest: React.FC<any> = ({ match }) => {
    const [loading, setContainerLoading] = useState(true)
    const { setLoading, userData, centrifuge, modalData, setModalData, headerTimer, setHeaderTimer, setCurrentTimer, currentTimer } = useContext(MainContextProvider)
    const history = useHistory()
    const [itemData, setItemData] = useState<any>({})
    const [notifications, setNotifications] = useState<any[]>([])
    const [tasks, setTasks] = useState<any[]>([])
    const [isUnSubscribe, setIsUnSubscribe] = useState(false)
    const [fullScreenChat, setFullScreenChat] = useState(false)
    const [isHideContent, setIsHideContent] = useState(true)
    const { params: { id } } = match
    let subscriptionData: any;
    const { showNewMessages } = useIntercom()

    const connectSocket = () => {
        const offer_comment_channel = `legal-request-${id}#${userData?.user_id}`;
        // console.log(`channel name legal request #${id}: ` + offer_comment_channel)
        subscriptionData = centrifuge.subscribe(offer_comment_channel, function(ctx: any) {
            if(ctx && ctx.data && ctx.data.comments) {
                pushNotifications(
                    'New message',
                    ctx.data.comments.text.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, ''), `${process.env.REACT_APP_SITE_LINK}/legal-request/${id}`
                )
                setItemData((prevState: any) => ({
                    ...prevState,
                    legalRequestComments: [ctx.data.comments, ...prevState.legalRequestComments]
                }))
            }
        });
    }

    useEffect(() => {
        if(centrifuge) connectSocket()
    }, [centrifuge])

    useEffect(() => {
        return () => {
            if(subscriptionData) subscriptionData.unsubscribe()
            setCurrentTimer('redirect')
        }
    }, [])



    const showLegalRequest = async () => {
        setContainerLoading(true)
        try {
            const { data, status } = await getLegalRequestService(id)
            if(status === 200 && data) {
                setItemData({
                    ...data,
                    ...data.offer,
                    client: data.client,
                    company: data.company,
                    // legalRequestComments: (data.legalRequestComments || []).reverse()
                })

                setTasks(data.tasks)

                const timerData = {
                    company_id: data.company.id,
                    offer_id: data.offer.id,
                    company_name: data.company.name,
                    type: ` Communication re Project #${data?.offer?.id}`,
                    created_at: null,
                    timer_id: null
                }

                if (!headerTimer && currentTimer !== "loading") {
                    setHeaderTimer(timerData)
                }

                document.title = data.company?.name + " - " + data.offer.title
                setIsUnSubscribe(data.company.debt === 'unsubscribed')
                setNotifications([...data.notifications.seen, ...data.notifications.unseen].sort(function (a, b) {
                    if (a.id > b.id) {
                        return 1;
                    }
                    if (a.id < b.id) {
                        return -1;
                    }
                    return 0;
                }).reverse())
            }
        } catch (e) {
            await showNewMessages(intercomCatchErrorMessage(e))
        }
        setContainerLoading(false)
    }

    useEffect(() => {
        if (!currentTimer && !headerTimer && Object.keys(itemData).length > 0) {
            const timerData = {
                company_id: itemData?.company?.id,
                company_name: itemData?.company?.name,
                offer_id: itemData?.id,
                type: `Communication re Project #${itemData?.id}`,
                created_at: null,
                timer_id: null
            }

            if (!headerTimer && currentTimer !== "loading") {
                setHeaderTimer(timerData)
            }
        }
    }, [currentTimer, headerTimer, itemData])

    useEffect(() => {
        if (modalData?.update) {
            showLegalRequest().then()
            setModalData(null)
        }
    },[modalData])

    const createRequestFromOffer = async () => {
        if(userData?.status.type_code !== 300) {
            return;
        }
        setContainerLoading(true)
        try {
            const { status, data } = await createOfferFromRequestService(id)
            if(status === 200) {
                history.push(`/offer/${data.id}?edit=true`)
            }
        } catch (e) { await showNewMessages(intercomCatchErrorMessage(e)) }
        setContainerLoading(false)
    }

    const deleteRequest = async () => {
        if (!window.confirm('Are you sure?')) return;

        setContainerLoading(true)
        try {
            const { status } = await customAxios.delete(`company/offers/${id}`)
            if (status === 204) {
                history.push(`/dashboard`)
            }
        } catch (e) {
            console.log(e)
            await showNewMessages(intercomCatchErrorMessage(e))
        }
        setContainerLoading(false)
    }

    useEffect(() => {
        setLoading(false)
        showLegalRequest().then()
    }, [id])


    const [file, setFile] = useState(null)
    const createComment = async (text: string) => {
        setContainerLoading(true)
        let bodyFormData = new FormData();
        // @ts-ignore
        bodyFormData.set('UploadStorageForm[documents]', file)
        bodyFormData.set('legal_request_id', id)
        bodyFormData.set('text', text)

        try {
            const { status, data } = await customAxios.post(`/company/comment/comment/create-legal-request`, bodyFormData, {
                headers:{ 'Content-Type': 'multipart/form-data' }
            })
            if(status === 200 || status === 201) {
                setFile(null)
                toastifySuccess('Message has been sent')
                await showLegalRequest()
            }
        } catch (e) {
            await showNewMessages(intercomCatchErrorMessage(e))
        }
        setContainerLoading(false)
    }

    const moveToArchive = async (key: number) => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.put(`company/offers/${id}`, { is_archived: key })
            if (status === 200) {
                history.push(key === 1 ? `/archive` : `/dashboard`)
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <div style={{ position: 'relative' }} className={`${fullScreenChat && 'full-screen-chat'}`}>
            {loading && <ModalLoader />}
            <FrozenController debt={itemData?.company?.debt} />
            <div onClick={() => history.goBack()}
                 className={'mobile-back-to-all-task'}>
                <img src={bottomIcon} alt=""/>
                ALL TASKS
            </div>
            <div className={'singleOffer task-view'}>
                <div className="task-view__left-side">
                    <div className="task-body">
                        {userData?.status.type_code === 100 ? <ClientKanbanStepNotifications
                            place={'offer'} accepted={itemData.accepted} is_done={itemData['is done']} hasTasks={0} /> :
                        <OfferHeader
                            statusText={'REQUEST'}
                            statusBackground={'#A8B3CF'}
                            updated_at={itemData?.updated_at}
                            isCreateRequestFromOffer={userData?.status.type_code === 300}
                            createRequestFromOffer={createRequestFromOffer}
                            isWaitIcon={userData?.status.type_code === 100 && itemData.status === 300}
                            notifications={notifications}
                            isAccessDelete={userData?.status.type_code === 300 && itemData.status !== 300}
                            deleteItem={deleteRequest}
                            statusData={{
                                text: 'REQUEST',
                                color: '#FFFFFF',
                                background: '#8E9EC8',
                                icon: waitIcon
                            }}
                            id={match.params.id}
                            isUpdateLegalRequest={userData?.status.type_code === 300}
                            heading={itemData?.title}
                            text={itemData?.description}
                            is_archived={itemData.is_archived}
                            moveToArchive={moveToArchive}
                        />}
                        <OfferHeading
                            heading={itemData?.title}
                            step={itemData.status === 6 ? 'Preparing Offer' : 'Discuss with VLO'}
                            text={itemData?.description}
                            isNextStep={true}
                            data={itemData}
                            isUpdateLegalRequest={userData?.status.type_code === 300}
                            id={match.params.id}
                            isHideContent={isHideContent} setIsHideContent={setIsHideContent}
                        />
                        {!isHideContent && <LegalTeam
                            items={[
                                {
                                    id: itemData?.manager?.id,
                                    avatar_path: itemData?.manager?.avatar_path,
                                    fullname: itemData?.manager?.fullname,
                                    title: 'VLO',
                                    type: '300'
                                },
                            ]}
                        />}
                        {!isHideContent &&
                            <div onClick={() => setIsHideContent(true)}
                                 className={'offerHeading__show-more'}>
                            Show less <img src={readMoreIcon} alt=""/>
                        </div>}
                        {/*<LegalRequestFooter
                        timeData={'11-12-11 16:30 Monday'}
                        link={'http://'}
                    />*/}
                    </div>
                    <div className={'mt15'} style={{ maxWidth: "790px" }}>
                        <GroupComments
                            id={id}
                            entity={'offer'}
                            isUnSubscribe={isUnSubscribe}
                            setTabsCounter={() => {}}
                            tabsCounter={0}
                            updateChatIndex={() => {}}
                            setChatCount={() => {}}
                        />
                    </div>

                </div>
                {tasks.length > 0 && <div style={{width: '480px', flexShrink: 0}}>
                    <TaskDeliverablesForOffer>
                        <div>
                            <TaskTabList
                                offer_id={id}
                                project={itemData.project}
                                tasks={tasks}
                                showStatus={false}
                                deleteTask={() => {
                                }}
                                isAcceptToDeleteTask={false}
                                accepted={false}
                                activeIndex={0}
                                setActiveIndex={() => {
                                }}
                            />
                        </div>
                    </TaskDeliverablesForOffer>
                </div>}

                {/*<div className="task-view__right-side">
                    <div className="task-deliverables-for-offer">
                        <div className="task-tab-list">
                            <div className="tabs__header" style={{margin: '0 0 20px'}}>
                                <span className={'tabs__header-item'}>Tasks</span>
                            </div>
                            <div className="task-tab-list__no-task">
                                <h2>NO TASKS YET</h2>
                                <p>This offer is being prepared by your VLO. The list of tasks and estimates will become
                                    visible to you once the offer is ready.</p>
                            </div>
                        </div>
                    </div>
                </div>*/}
            </div>
        </div>
    )
}