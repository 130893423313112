import { AxiosResponse } from 'axios'
import { methodGet, methodPost } from './api/api'
import {
  PostCommentData, PostTaskType, SubTaskType, TaskType,
} from '../types/task.type'
import customAxios from './api/interceptors'

const config = {

}

export const getSingleTask = async (id:number) => methodGet({
  path: `company/tasks/${id}?expand=executors,project,client_actions,milestones,messages,subscription,clients,client,provider,deliverables`,
})

export const getAllTasks = async () => methodGet({
  path: 'company/tasks?expand=executors,project,client_actions,milestones,messages',
})

export const createTask = async (data: PostTaskType):Promise<AxiosResponse> => customAxios.post('company/tasks', data, config)

export const updateTask = async (id:number, data: PostTaskType):Promise<AxiosResponse> => customAxios.put(`company/tasks/${id}`, data, config)

export const deleteSingleTask = async (id:number):Promise<AxiosResponse> => customAxios.delete(`company/tasks/${id}`, config)

export const createSubTask = (data:SubTaskType, link: string):Promise<AxiosResponse> => customAxios.post(`company/subtasks?type=${link}`, data, config)

export const updateSubTask = (id: number, data:SubTaskType, link: string):Promise<AxiosResponse> => customAxios.put(`company/subtasks/${id}?type=${link}`, data, config)

export const deleteSubTask = (id:number, link: string):Promise<AxiosResponse> => customAxios.delete(`company/subtasks/${id}?type=${link}`)

export const createComment = (data:PostCommentData):Promise<AxiosResponse> => customAxios.post('company/task-comments', data, config)
