import React, {useContext} from "react";
import {Ticker} from "../../../../components/other/Ticker/Ticker";
import Header from "../Header";
import LeftNav from "../LeftNav";
import {MainContextProvider} from "../../../../context/MainContext";
import {useHistory} from "react-router-dom";
import "./client-layout.scss"

export const ClientLayout: React.FC = ({ children }) => {
    const { isShowMainHeader, userData } = useContext(MainContextProvider)
    const history = useHistory()

    return (
        <div className={'layout'}>
            <Ticker history={history} />
            <div style={{ maxWidth: '1600px', margin: '0 auto', width: '100%' }}>
                {isShowMainHeader && <Header />}
            </div>
            <div className={'layout__container'}>
                <div className={'layout__wrap'}>
                    <LeftNav/>
                    <div className={'layout__container'}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}