import React, { useContext, useEffect, useState } from 'react'
import { CheckboxItem } from '../../../../../../components/UI'
import editIcon from '../../../../../../assets/images/pen.svg'
import '../Milestones/milestones.scss'
import { MainContextProvider } from '../../../../../../context/MainContext'
import Button from '../Button/Button'
import { deleteElement, pushElement, updateElement } from '../../../../../../helpers/helperFunctions'
import { updateSubTask, deleteSubTask } from '../../../../../../service/tasks'
import { SubTaskType } from '../../../../../../types/task.type'
import { toastifySuccess } from '../../../../../../service/toastify/toastify'
import { useTranslation } from 'react-i18next'

export type ClientActionItem = {
  id: string;
  title: string;
  status: string;
  sort: string;
  sp_price?: number
}

type ClientActionType = {
  task_id: number;
  items: ClientActionItem[];
  setItems: (key:ClientActionItem[]) => void;
  executor: any; // FIX_ME
  other?: any; // FIX_ME
  role: string;
}

const ClientAction: React.FC<ClientActionType> = ({
  items, setItems, task_id, executor, other,
}) => {
  const {
    modalType,
    setModalType,
    setModalData,
    modalData,
  } = useContext(MainContextProvider)
  const { t } = useTranslation()

  const changeStatusItem = async (id:number, index:number, data:SubTaskType) => {
    data.status = '1'
    const res = await updateSubTask(id, data, other.link)
    if (res.status === 200) {
      toastifySuccess(t('taskBody.clientActionHaveUpdated'))
      const copyItems = JSON.parse(JSON.stringify(items))
      copyItems[index].status = '1'
      setItems(copyItems)
    }
  }

  const refreshData = (items:any) => {
    setItems(items)
    setModalType('')
    setModalData(null)
  }

  const deleteMilestone = async (id:number) => {
    if (!window.confirm(t('taskBody.confirmMilestoneDelete'))) return false

    const res = await deleteSubTask(id, other.link)
    if (res.status === 204) {
      toastifySuccess(t('taskBody.clientActionWasDeleted'))
      setItems(items.filter((el) => String(el.id) !== String(id)))
    }
  }

  useEffect(() => {
    if (modalType === 'milestones-modal' && modalData.type) {
      switch (modalData.type) {
        case 'create':
          refreshData(pushElement(items, modalData.item)); break
        case 'update':
          refreshData(updateElement(items, modalData.item, modalData.item.id)); break
        case 'push':
          refreshData(pushElement(items, modalData.item)); break
        case 'delete':
          refreshData(deleteElement(items, modalData.item.id)); break
        default:
          console.log('empty type')
      }
    }
  }, [modalData])

  return (
    <div className={'milestones'}>
      {other.buttonAllow && (
        <Button
          label={other.button}
          onChange={() => {
            setModalType('milestones-modal')
            setModalData({
              type: 'createModal',
              prefix: other.prefix,
              configTitle: other?.modalAddTitle,
              link: other?.link,
              item: {
                task_id,
                executor_type: other?.type, // FIX_ME
                executor_id: executor?.id,
              },
            })
          }}
        />
      )}
      <div className={'milestones__items'}>
        {items.length > 0 && <div className={'milestones__row'}>
          <span style={{ width: '80%', paddingLeft: '25px' }}>{'Title'}</span>
          {other.isPages && <span style={{ width: '10%' }}>{'Pages'}</span>}
          {!!other.buttonAllow && <span style={{ width: '10%' }}>{'Options'}</span>}
        </div>}
        {
          items.map(({
            id, title, status, sort, sp_price,
          }, index) => (
            <div key={id} className={'milestones__item'}>
              <CheckboxItem
                disabled={!other.checkboxAllow}
                active={`${status}` === '1'}
                className={'milestones__item-checkbox'}
                label={title}
                onChange={() => {
                  if (`${status}` === '0' && other.checkboxAllow && window.confirm(`${t('taskBody.changeStatusFirst')}${other.title}${t('taskBody.changeStatusLast')}`)) {
                    changeStatusItem(+id, index, {
                      title,
                      task_id,
                      sort: +sort,
                      executor_type: other.type,
                      // executor_id: executor.id,
                    }).then()
                  }
                }}
              />
              {other.isPages && (
              <span className={'milestones__item-price'}>
                {sp_price || '-'}
              </span>
              )}
              {other.buttonAllow && (
              <a
                className={'milestones__item-edit'}
              >
                <div className={'flex-center'}>
                  <img
                    onClick={() => {
                      setModalType('milestones-modal')
                      setModalData({
                        type: 'updateModal',
                        prefix: other.prefix,
                        link: other.link,
                        configTitle: other.modalUpdateTitle,
                        item: {
                          id,
                          title,
                          task_id,
                          sp_price,
                          executor_type: other.type,
                          executor_id: executor.id,
                        },
                      })
                    }}
                    src={editIcon}
                    alt={''}
                  />
                  <a
                    onClick={() => deleteMilestone(+id)}
                    className={'milestones__delete'}
                  >
                    &#x2715;
                  </a>
                </div>
              </a>
              )}
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default ClientAction
