// @ts-ignore FIX_ME
import is from 'is_js'
import { ControlValidType } from './ControlValid.type'


let controlValid: ControlValidType

controlValid = function (value, validation) {
  let isValid = true

  if (!validation) {
    return true
  }

  if (validation.required) {
    if (!value) {
      return false
    }
    if (typeof value === 'string') isValid = value.trim() !== '' && isValid
    else isValid = value > 0 && isValid
  }

  if (validation.email && typeof value === 'string' && value.trim() !== '') {
    isValid = is.email(value) && isValid
  }

  if (validation.minLength && typeof value === 'string') {
    isValid = value.length >= validation.minLength && isValid
  }

  if (validation.number && typeof value === 'number') {
    isValid = is.number(+value) && isValid
  }

  if (validation.url && typeof value === 'string' && value.trim() !== '') {
    isValid = is.url(value) && isValid
  }

  return isValid
}

export default controlValid
