import {AuthModal} from "../AuthModal/AuthModal";
import React, {useContext, useEffect, useState} from "react";
import {CheckboxItem} from "../../../../components/UI";
import ArrowIcon from "../../../../assets/images/arrow.svg";
import {getConsentGiven, setConsentGiven} from "../../../../service/settings";
import {toastifyError} from "../../../../service/toastify/toastify";
import {MainContextProvider} from "../../../../context/MainContext";

export const PrivacyPolicy = () => {
    const { userData, isLoggedIn } = useContext(MainContextProvider)
    const [firstPoint, setFirstPoint] = useState(false)
    const [secondPoint, setSecondPoint] = useState(false)
    const [showConsentGiven, setShowConsentGiven] = useState(false);

    const getConsentGivenData = async () => {
        const { data } = await getConsentGiven();
        if(data && (JSON.parse(data).status !== 'ok' || JSON.parse(data).message !== '1')) {
            setShowConsentGiven(true) // IMPORTANT_FIX
        }
    }

    useEffect(() => {
        (async () => {
            if(isLoggedIn && userData && userData.status.type_code === 100) {
                await getConsentGivenData()
            }
        })()
    }, [isLoggedIn, userData])

    const setData = async () => {
        const { data } = await setConsentGiven()
        if(data && JSON.parse(data).status === 'ok') {
            setShowConsentGiven(false)
        } else {
            toastifyError('Something went wrong')
        }
    }

    return (
        <AuthModal isOpen={showConsentGiven} close={() => {} }>
            <div>
                <div className="auth-modal__container">
                    <div style={{ minWidth: '470px', maxWidth: '600px' }} className="auth-modal__body">
                        <div className="auth-modal__hr-header">
                            <h1>Privacy Policy</h1>
                            <hr />
                        </div>
                        <p className={'auth-modal__description'}>
                            Please use this Privacy Notice ("Notice") before using the https://legalnodes.com
                            website ("Website"). In this Notice you can find information on how the Company
                            collects and uses different types of information on the Website.
                        </p>
                        <br /><br />
                        <CheckboxItem
                            active={firstPoint}
                            onChange={() => setFirstPoint(!firstPoint)}
                            label={'Check here to indicate that you have read and agree to the <a style="color: #3761f0" target="_blank" href="https://legalnodes.com/uploads/terms-and-conditions.pdf">Terms Of Use</a>'}
                        />
                        <CheckboxItem
                            active={secondPoint}
                            onChange={() => setSecondPoint(!secondPoint)}
                            label={'Check here to indicate that you have read and agree to the <a style="color: #3761f0" target="_blank" href="https://legalnodes.com/uploads/privacy-policy.pdf">Privacy Policy</a>'}
                        />
                        <button
                            disabled={!firstPoint || !secondPoint}
                            style={{ opacity: !firstPoint || !secondPoint ? '0.7' : '1' }}
                            onClick={setData}
                            className={'auth-modal__button'}>
                            <img src={ArrowIcon} alt=""/>
                            {'Send'}
                        </button>
                    </div>
                </div>
            </div>
        </AuthModal>
    )
}