import customAxios from "../api/interceptors";

export const getSingleOfferService = (id: number) => customAxios.get(`/company/client/kanban/${id}?expand=manager,lawyers,next_step,price_estimate,offer_price,project,notifications,client,company`)

export const acceptOfferService = (id: number) => customAxios.post(`/company/offer/accept-offer`, { offer_id: id })

export const sendKanbanOfferCommentService = (offer_id: number, text: string) => customAxios.post(`/company/comment/comment/create-offer`, {offer_id, text})

export type CreateOfferData = {
    title: string
    description: string
    company_id?: number
    status: number
}

export const createOfferService = (data: CreateOfferData) => customAxios.post(`/company/offer/create-with-company`, data)

export const updateOfferService = (data: { title: string, description: string, company_id?: number, accepted?: string }, id: number) => customAxios.put(`/company/offers/${id}`, data)

export const updateOfferDeliverablesService = (data: { offer_id: number, deliverables: string }) => customAxios.post(`/company/offer/deliverables`, data)