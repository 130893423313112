import React, {useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import createIcon from "../../icons/createKanbanIcon.svg"
import descLeftIcon from "../../icons/desc-left-icon.svg"
import descRightIcon from "../../icons/desc-right-icon.svg"
import dropdownIcon from "../../icons/dropdown.svg"
import customAxios from "../../../../service/api/interceptors";
import {ProjectSuggestionsType} from "../../ProjectSuggestions.type";
import {CardDropdown} from "../../../../components/other/CardDropdown/CardDropdown";
import ModalContainer from "../../../../components/ModalContainer";
import {CreateProjectSuggestion, UpdateProjectSuggestion} from "../../modals/CreateProjectSuggestion";
import {CustomLoader} from "../../../../components/UI/CustomLoader/CustomLoader";
import {BusinessGoalType} from "../../BusinessGoal.type";
import {CreateRequestFromBG} from "../../modals/CreateRequestFromBG";
import {MainContextProvider} from "../../../../context/MainContext";
import logoIcon from "../../../../assets/images/logo.svg";

type Props = {
    BusinessGoal: BusinessGoalType
}

export const ProjectSuggestions: React.FC<Props> = ({ BusinessGoal }) => {
    const { id }: { id: string } = useParams()
    const { userData, creditCount } = useContext(MainContextProvider)
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState<ProjectSuggestionsType[]>([])
    const [projectSuggestions, setProjectSuggestions] = useState<ProjectSuggestionsType | null>(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [projectSuggestionModal, setProjectSuggestionModal] = useState<ProjectSuggestionsType | null>(null)
    const [requestProjectSuggestions, setRequestProjectSuggestions] = useState<ProjectSuggestionsType | null>(null)

    const getItems = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`business-goal/${id}/project-suggestions`)
            if (status === 200) {
                setItems(data)
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        getItems().then()
    }, [id])

    const getPriority = (priority: number) => {
        switch (priority) {
            case 1: return { value: 1, label: 'Low', color: '#000' }
            case 2: return { value: 2, label: 'Medium', color: '#FF9E45' }
            case 3: return { value: 3, label: 'High', color: '#F00' }
            default: return { value: '', label: 'Not Selected', color: '#ad5700' }
        }
    }

    const deleteItem = async (id: number) => {
        if (!window.confirm('Are you sure?')) return;

        try {
            const { status } = await customAxios.delete(`/project-suggestion/${id}`)
            if (status === 200) {
                await getItems()
            }
        } catch (e) {}
    }

    const BsStatus = ({ item }: { item: ProjectSuggestionsType }) => {
        if (+item.is_done === 1) {
            return <em style={{ color: '#07B56C' }}>Done</em>
        } else if (item.offer && !Array.isArray(item.offer)) {
            return <em style={{ color: '#2361FF' }}>In Progress</em>
        } else {
            return <em style={{ color: '#000' }}>Not Started</em>
        }
    }

    const getEstimate = (item: ProjectSuggestionsType) => {
        if (item.is_covered_by_monthly_plan) {
            return 'Covered by Plan'
        }

        if (item.is_price_estimate_not_applicable) {
            return 'N/A'
        }

        return `From $${item.price_estimate}`
    }

    if (loading) {
        return <CustomLoader />
    }

    return (
        <div className={'project_suggestions'}>
            {userData?.status.type_code === 100 && <div className="project_suggestions__client-head">
                <div className="project_suggestions__client-head-heading">
                    Suggested requests
                </div>
                <div className={'project_suggestions__client-head-desc'}>
                    {!creditCount.isCreditSubscription && <>
                        <p>
                            Explore our recommended legal works to achieve your business goals. Use this list to track
                            ongoing
                            tasks and initiate new ones by clicking “Get Started”.
                        </p>
                        <p>
                            <b>
                                Please note that all estimates mentioned here are indicative only.<br/>
                                Final prices vary and are set by service providers from the Legal Nodes Network.
                            </b>
                        </p>
                    </>}

                    {creditCount.isCreditSubscription && <p>
                        Your VLO has suggested new requests to help you reach your business goals.
                        If you’d like to accept a suggestion, you can click «Get Started» and this will instruct your VLO to begin work on the request.
                        Starting work on a new request costs 1 Credit (except some requests Covered by Plan). All estimates are approximate.
                        Your VLO will provide more precise estimates after beginning to work on the request.
                    </p>}
                </div>
            </div>}
            {userData?.status.type_code === 100 && creditCount.isCreditSubscription && <div style={{
                background: '#2361FF',
                color: '#FFFFFF',
                fontSize: '11px',
                textAlign: 'center',
                padding: '7px',
                textTransform: 'uppercase'
            }}>
                1 CREDIT WILL BE CHARGED TO GET STARTED WITH YOUR REQUEST (EXCEPT COVERED BY PLAN REQUESTS)
            </div>}
            {(userData?.status.type_code === 300 || userData?.status.type_code === 400) && <div onClick={() => setIsModalOpen(true)} className="project_suggestions__create">
                <div>
                    <img src={createIcon} alt=""/>
                    <span>ADD Suggestion</span>
                </div>
            </div>}
            <div className="project_suggestions__items">
                {
                    (items || []).map(item => (
                        <div className="project_suggestions__item">
                            <div className="project_suggestions__item-top">
                                <p>{item.title}</p>
                                <div className={'flex'}>
                                    <div onClick={() => setProjectSuggestions(item)} className="project_suggestions__item-top-button mr5">
                                        <img src={descLeftIcon} alt=""/>
                                        DESCRIPTION
                                        <img src={descRightIcon} alt=""/>
                                    </div>
                                    {userData?.status.type_code === 300 && <CardDropdown
                                        items={[
                                            {label: 'Edit', func: () => setProjectSuggestionModal(item)},
                                            {label: 'Delete', func: () => deleteItem(item.id)},
                                        ]}
                                    />}
                                </div>
                            </div>
                            {
                                item.credit_price_estimate > 0 ? <div className="project_suggestions__item-bottom">
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <div className="project_suggestions__item-bottom-status w100">
                                            <span>Priority:</span> <em className={'mr15'} style={{ color: getPriority(+item.importance).color }}>{getPriority(+item.importance).label}</em>
                                            <span>Cost estimate:</span> <em className={'mr5'}>from {item.credit_price_estimate} credits</em>
                                            {item.price_estimate > 0 && <><span>+add-on</span> <em>from ${item.price_estimate}</em></>}
                                        </div>
                                        {/*<div className="project_suggestions__item-bottom-status w70">
                                            <span>Cost estimate:</span> <em>from {item.credit_price_estimate} credits</em>
                                            <span>+add-on</span> <em>from ${item.price_estimate}</em>
                                        </div>*/}
                                        <div className="project_suggestions__item-bottom-status w50 mt5">
                                            <span>Status:</span> <BsStatus item={item} />
                                        </div>
                                        {item.time_estimate > 0 && <div className="project_suggestions__item-bottom-status w50 mt5">
                                            <span>Time estimate:</span> <em>from {item.time_estimate} days</em>
                                        </div>}
                                    </div>
                                    {(item.offer && !Array.isArray(item.offer)) && <div
                                        onClick={() => history.push(`${+item.offer.accepted === 6 ? '/legal-request' : '/offer'}/${item.offer.id}`)}
                                        className="project_suggestions__item-bottom-button project_suggestions__item-bottom-button-white flex-center">
                                        View Project
                                    </div>}
                                    {(!item.offer || Array.isArray(item.offer)) && (userData?.status.type_code === 100 || userData?.status.type_code === 300) && <div
                                        onClick={() => setRequestProjectSuggestions(item)}
                                        className="project_suggestions__item-bottom-button project_suggestions__item-bottom-button-black flex-center">
                                        Get Started
                                    </div>}
                                </div>
                                    :
                                    <div className="project_suggestions__item-bottom">
                                        <div className="project_suggestions__item-bottom-status">
                                            <span>Priority:</span> <em style={{ color: getPriority(+item.importance).color }}>{getPriority(+item.importance).label}</em>
                                        </div>
                                        <div className="project_suggestions__item-bottom-status">
                                            <span>Status:</span> <BsStatus item={item} />
                                        </div>
                                        <div className="project_suggestions__item-bottom-status">
                                            <span>Estimate:</span> <em>{getEstimate(item)}</em>
                                        </div>
                                        {(item.offer && !Array.isArray(item.offer)) && <div
                                            onClick={() => history.push(`${+item.offer.accepted === 6 ? '/legal-request' : '/offer'}/${item.offer.id}`)}
                                            className="project_suggestions__item-bottom-button project_suggestions__item-bottom-button-white flex-center">
                                            View Project
                                        </div>}
                                        {(!item.offer || Array.isArray(item.offer)) && (userData?.status.type_code === 100 || userData?.status.type_code === 300) && <div
                                            onClick={() => setRequestProjectSuggestions(item)}
                                            className="project_suggestions__item-bottom-button project_suggestions__item-bottom-button-black flex-center">
                                            Get Started
                                        </div>}
                                    </div>
                            }
                        </div>
                    ))
                }
            </div>
            <UpdateProjectSuggestion
                isOpen={!!projectSuggestionModal}
                updateItems={getItems}
                close={() => setProjectSuggestionModal(null)}
                obj={projectSuggestionModal}
            />
            <CreateProjectSuggestion
                isOpen={isModalOpen}
                updateItems={getItems}
                close={() => setIsModalOpen(false)}
            />
            <ProjectSuggestionsDescriptionModal
                projectSuggestion={projectSuggestions}
                close={() => setProjectSuggestions(null)}
            />
            <CreateRequestFromBG
                isOpen={!!requestProjectSuggestions}
                close={() => setRequestProjectSuggestions(null)}
                project_suggestion={requestProjectSuggestions}
                company_id={BusinessGoal.company_id}
            />
        </div>
    )
}

const ProjectSuggestionsDescriptionModal: React.FC<{ projectSuggestion: ProjectSuggestionsType | null, close: ()  => void }> = ({ projectSuggestion, close }) => {
    return (
        <div className={'project-suggestion-description-modal'}>
            <ModalContainer isOpen={!!projectSuggestion} close={close}>
                <div>
                    <h2 className={'project-suggestion-description-modal__heading'}>{projectSuggestion?.title}</h2>
                    <p className={'project-suggestion-description-modal__description task-body__description task-body__description-block'} dangerouslySetInnerHTML={{ __html: projectSuggestion?.description || '' }} />
                </div>
            </ModalContainer>
        </div>
    )
}