import React, { useContext, useState } from 'react'
import userIcon from '../../../../../../assets/images/userIcon.svg'
import clipIcon from '../../../../../../assets/images/clip.svg'
import sendIcon from '../../../../../../assets/images/send.svg'
import clientIcon from '../../../../../../assets/images/client.png'
import './comments.scss'
import { dateFormatter } from '../../../../../../helpers/dateFormatter'
import { createComment } from '../../../../../../service/tasks'
import { MainContextProvider } from '../../../../../../context/MainContext'
import { PostCommentData } from '../../../../../../types/task.type'
import { toastifySuccess, toastifyError } from '../../../../../../service/toastify/toastify'
import { useTranslation } from 'react-i18next'
import ModalContainer from "../../../../../../components/ModalContainer";
import {ReactEditor} from "../../../../../../components/UI";

const Comments:React.FC<any> = ({ messages, updateMessages, task_id }) => {
  const { userData } = useContext(MainContextProvider)
  const [text, setText] = useState('')
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const postMessage = async () => {
    if (!text.trim().length) {
      toastifyError('Message is empty')
      return
    }

    if(text.trim().length > 1000) {
      toastifyError('Max length is 1000 symbols')
      return
    }

    setLoading(true)
    try {
      const data: PostCommentData = {
        text,
        task_id: +task_id,
        author_id: Number(userData?.id),
        author_type: String(userData?.status.type_code) || '200',
      }
      const res = await createComment(data)
      if (res.status === 200 || res.status === 201) {
        const data = JSON.parse(JSON.stringify(messages))
        res.data.datetime = res.data.datetime || res.data.created_at
        res.data.author = userData
        data.unshift(res.data)
        setText('')
        updateMessages(data)
        toastifySuccess('Comment have been sent')
      }
      setLoading(false)
    } catch (e) {
      console.error('createComment Error', e)
      setLoading(false)
    }
  }

  return (
    <>
      <div className={'comment-list'}>
        <div className={'comment-list__header'}>
          <h2 className={'comment-list__title'}>{t('taskBody.commentsTitle')}</h2>
          <img style={{ opacity: 0 }} className={'comment-list__icon'} src={userIcon} alt={''} />
        </div>
        <div className={'comment-list__body'}>
          { messages.map(({ author, text, datetime }:any) => (
            <div className={'user-messages'}>
              <div className={'user-messages__avatar'}>
                <div className={'user-messages__img'}>
                  <img style={{ height: '36px', width: '36px' }} className={'img'} src={author ? author.avatar_url : clientIcon} alt={''} />
                </div>
                <div className={'user-messages__text'}>
                  <p><b>{author && author.name}</b></p>
                  <p dangerouslySetInnerHTML={{ __html: text }} />
                </div>
              </div>

              <div className={'user-messages__sending-time'}>
                <span className={'user-messages__sending-time__span'}>
                  <em>{dateFormatter(datetime)}</em>
                  <br />
                  <em>{dateFormatter(datetime, true)}</em>
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className={'comment-list__input-futter'}>
          {/*<textarea
            value={text}
            onChange={(e) => setText(e.target.value)}
            className={'comment-list__textarea'}
            placeholder={t('taskBody.enterYourMessage')}
                        // onKeyDown={(e:React.KeyboardEvent) => postMessage(e.keyCode)}
            disabled={loading}
          />*/}
          <ReactEditor className={'comment-list__editor'} value={text} onChange={(e) => setText(e)} height={200} valid={true} />
          <button onClick={postMessage} className={'comment-list__send-button'}>
            <img src={sendIcon} alt={''} />
          </button>
          {/* <input className='comment-list__input-image' type="image" src={clipIcon} /> */}
        </div>
      </div>
    </>
  )
}

export default Comments
