import React, {useContext, useEffect, useState} from "react";
import {Input, ReactSelect} from "../../../components/UI";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";
import customAxios from "../../../service/api/interceptors";
import {CompanyType} from "../../../types/company.type";
import {controlErrorFunc} from "../../../components/UI/ControlValid/controlErrorValid";
import {toastifyError} from "../../../service/toastify/toastify";
import {MainContextProvider} from "../../../context/MainContext";
import {useLocation} from "react-router-dom";

const validationData = {
    email: { required: true, email: true, maxLength: 255 },
    companyId: { required: true },
}

const isShowCreateLegalRequestButton = (clients: CompanyType[], client_id: string) => {
    const client_object: any = clients.find((el) => +el.id === +client_id)
    return client_object?.debt !== "unsubscribed";
}

export const InviteTeamMate: React.FC = () => {
    const { setModalType } = useContext(MainContextProvider)
    const [isFinish, setFinish] = useState(false)
    const [companies, setCompanies] = useState<CompanyType[]>([])
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState<{[key: string]: string}>({})
    const [values, setValues] = useState({
        companyId: '',
        email: ''
    })

    const getCompanies = async () => {
        try {
            const { data, status } = await customAxios.get(`/company/company/get-companies`)
            if (status === 200) {
                setCompanies(data)
                setValues({ ...values, companyId: data[0].id })
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        getCompanies().then()
    }, [])

    const sendInvite = async () => {
        const validReq = controlErrorFunc({ obj: values, config: validationData })

        if(Object.keys(validReq).length >= 1) {
            setErrors(validReq)
            toastifyError('Please correctly fill all fields in the form')
            return false;
        }
        setErrors({})

        setLoading(true)
        try {
            const { data, status } = await customAxios.get(`/v1/user/client/invite/email?email=${values.email}&companyId=${values.companyId}`)
            if (status === 200 && data.token) {
                setFinish(true)
            } else {
                toastifyError('Something went wrong!')
            }
        } catch (e) {}
        setLoading(false)
    }

    const finishTestStyle = { fontSize: '18px', margin: '10px 0 25px 0', lineHeight: '23px' }
    if(isFinish) {
        return (
            <div>
                <p style={finishTestStyle}>
                    An invitation has been sent to your teammate`s email address to join this Legal Nodes workspace.
                </p>
                <button onClick={() => setModalType('')} style={{ width: '100%' }} className="btn-black">CLOSE</button>
            </div>
        )
    }

    return (
        <div style={{ position: 'relative' }}>
            {loading && <ModalLoader />}
            <p className={'create_legal_request__description'}>Invite your teammate to the workspace by entering their email.</p>
            <Input
                className={'mt5'}
                otherProps={{
                    placeholder: 'Enter Email',
                    value: values.email,
                    onChange: event => setValues({ ...values, email: event.target.value })
                }}
                errorMessage={errors?.email} valid={!errors.email}
            />
            <ReactSelect
                className={'mt15'}
                value={values.companyId}
                options={companies.map(({ id, name }) => ({ value: id, label: name }))}
                onChange={e => setValues({ ...values, companyId: e.value })}
            />
            {
                <button
                    title={!isShowCreateLegalRequestButton(companies, values.companyId) ? 'You do not have an active Monthly Plan so this feature is unavailable. Please activate the Monthly Plan to unlock this feature.' : ''}
                    disabled={!isShowCreateLegalRequestButton(companies, values.companyId)}
                    onClick={sendInvite} style={{ width: '100%', marginTop: '40px' }} className={'btn-black'}>Send Invitation</button>
            }
        </div>
    )
}