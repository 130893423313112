import {CommentsContainer, MessageItem} from "../CommentsContainer/CommentsContainer";
import React, {useContext, useEffect, useRef, useState} from "react";
import customAxios from "../../../service/api/interceptors";
import {UserType} from "../../../enums/userType";
import {MainContextProvider} from "../../../context/MainContext";
import {UserProfileResponse} from "../../../service/userProfile/userProfile.type";
import {useLocation} from "react-router-dom";
import pushNotifications from "../../../helpers/pushNotifications";

const GroupCommentsPhoto = ({ users, currentUserId }: { users: UserProfileResponse[], currentUserId: string }) => {
    const allUsers = users.filter((user: UserProfileResponse) => String(user.user_id) !== currentUserId)

    if (allUsers.length === 1) {
        return (
            <div className={'comment-list__group-photo'}>
                <div className={"comment-list__group-photo-item"}>
                    <img src={allUsers[0].avatar_url} alt=""/>
                </div>
            </div>
        )
    } else {
        return (
            <div className={'comment-list__group-photo'}>
                <div className="comment-list__group-photo-items">
                    {
                        allUsers.map((user, i) => {
                            if (allUsers.length === 4 ? i <= 3 : i <= 2) {
                                return (
                                    <div>
                                        <img src={user.avatar_url} alt=""/>
                                    </div>
                                )
                            }
                        })
                    }
                    {allUsers.length > 4 && <b>{allUsers.length - 3}+</b>}
                </div>
            </div>
        )
    }
}

export const GroupComments: React.FC<any> = ({ id, entity, isUnSubscribe, setTabsCounter, tabsCounter, updateChatIndex, setChatCount, offer_id, isHideContent }) => {
    const { userData, centrifuge } = useContext(MainContextProvider)
    const [items, setItems] = useState<{ id: string, name: string, users: UserProfileResponse[], count: number }[]>([])
    const [chats, setChats] = useState<any[]>([])
    const [activeChatId, setActiveChatId] = useState<string | null>(null)
    const [unSeenMessages, setUnSeenMessages] = useState<{ id: string, new_comments_count: string }[]>([])
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const chatRef: any = useRef()

    const activeChatIdRef = useRef<null | string>(null)
    const unSeenMessagesRef = useRef<{ id: string, new_comments_count: string }[]>([])

    const getChats = async () => {
        const initialChatId = searchParams.get('chat_id')

        try {
            const { data: prData } = entity === 'partner-request' && userData?.status.type_code === 200 ? await customAxios.get(`/chat/general/partner-request/${id}`) : { data: [] }

            const { status, data } = await customAxios.get(`/chat/${entity}/${id}`)
            if (status === 200) {
                setItems([...data, ...(prData || [])].map((el: any) => ({ ...el, count: 0 })))
                if (data.length > 0) {
                    if (data.find((el: any) => String(el.id) === String(initialChatId))) {
                        setActiveChatId(initialChatId)
                        activeChatIdRef.current = initialChatId
                    } else {
                        setActiveChatId([...data, ...(prData || [])][0].id)
                        activeChatIdRef.current = [...data, ...(prData || [])][0].id
                    }
                }
                setChatCount && setChatCount([...data, ...(prData || [])].length)
                setChats([...data, ...(prData || [])])
                connectSocket([...data, ...(prData || [])])
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getUnSeenMessages = async () => {
        try {
            const { status, data } = await customAxios.get(`/${entity}/${id}/new-comments-count`)

            if (status === 200) {
                const saveData = data.map((el: any) => {
                    if (+el.id === +(activeChatId || '0')) {
                        return { ...el, new_comments_count: 0 }
                    } else {
                        return el
                    }

                })
                setUnSeenMessages(saveData)
                unSeenMessagesRef.current = saveData

            }
        } catch (e) {}
    }

    const subscriptionData: any = []

    const connectSocket = (data: any[]) => {
        data.map(el => {
            const offer_comment_channel = `chat-${el.id}#${userData?.user_id}`;
            //console.log(offer_comment_channel)
            centrifuge.subscribe(offer_comment_channel, function(ctx: { data: { comments: MessageItem } }) {
                //console.log(1233, ctx.data)
                if(ctx && ctx.data && ctx.data.comments) {

                    if (String(el.id) !== String(activeChatIdRef.current)) {
                        const saveData = unSeenMessagesRef.current.map(item => {
                            if (+item.id === +el.id) {
                                return { ...item, new_comments_count: String(+item.new_comments_count + 1) }
                            } else {
                                return item
                            }
                        })


                        setUnSeenMessages(saveData)
                        unSeenMessagesRef.current = saveData
                        // getUnSeenMessages().then()
                    }
                }
            });
        })
    }

    const [isUnSeenTouched, setIsUnSeenTouched] = useState(false)
    useEffect(() => {
        if (activeChatId && !isUnSeenTouched) {
            getUnSeenMessages().then()
            setIsUnSeenTouched(true)
        }
    }, [activeChatId])

    useEffect(() => {
        getChats().then()

        return () => {
            if (subscriptionData.length > 0) {
                subscriptionData.map((el: any) => {
                    el?.unsubscribe()
                })
            }
        }
    }, [updateChatIndex, entity, id])

    const setActiveChat = (chat_id: string) => {
        const currentChatId = activeChatIdRef.current
        setActiveChatId(chat_id)
        activeChatIdRef.current = chat_id

        const saveData = unSeenMessagesRef.current.map(chat => {
            return (String(chat.id) === String(chat_id) ? { id: chat_id, new_comments_count: '0' } : chat)
        })
        setUnSeenMessages(saveData)
        unSeenMessagesRef.current = saveData
    }

    return (
        <div>
            {items.length > 1 && <div className={'comment-list__header'}>
                {
                    (items || []).map(chat => (
                        <>
                            <div
                                onClick={() => setActiveChat(chat.id)}
                                className={`comment-list__header-el ${String(chat.id) === String(activeChatId) && 'comment-list__header-el-active'}`}>
                                <GroupCommentsPhoto
                                    users={chat.users}
                                    currentUserId={String(userData?.user_id) || ''}
                                />
                                <span>
                                {chat.name}
                                    {(Number(unSeenMessages.find(el => el.id === chat.id)?.new_comments_count) > 0) && (+chat.id !== +(activeChatId || 1)) &&
                                        <em>{unSeenMessages.find(el => el.id === chat.id)?.new_comments_count}</em>}
                            </span>
                            </div>
                        </>
                    ))
                }
            </div>}
            {
                activeChatId && (
                    <CommentsContainer
                        chats={items}
                        chat_id={activeChatId}
                        entity_id={id}
                        postMessage={(text) => {}}
                        setMessages={offerComments => {}}
                        link={'/company/comment/'}
                        linkToGetMessages={'offer'}
                        linkToSend={`/comment/chat/${activeChatId}`}
                        isUnSubscribe={isUnSubscribe}
                        setCounterData={(count) => setTabsCounter({ ...tabsCounter, offers: count })}
                        fullScreenChat={false}
                        setFullScreenChat={() => {}}
                        pr_offer_id={offer_id}
                        isHideContent={isHideContent}
                    />
                )
            }
        </div>
    )
}