import React, { useContext, useEffect, useState } from 'react'
import { ReactEditor } from '../../../components/UI'
import { MainContextProvider } from '../../../context/MainContext'
import './deliverables-modal.scss'
import { updateTask } from '../../../service/tasks'
import { PostTaskType } from '../../../types/task.type'
import { useTranslation } from 'react-i18next'
import controlErrorValid from "../../../components/UI/ControlValid/controlErrorValid";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";

const DeliverablesModal = () => {
  const { setModalData, modalData } = useContext(MainContextProvider)
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const { t } = useTranslation()

  const updateElement = async (type:string = 'update') => {
    const validRes = controlErrorValid(value, {required: true, maxLength: 10000})
    if(validRes) {
      setErrorMessage(validRes + '')
      return false;
    }
    setErrorMessage('')

    const data:PostTaskType = {
      budget: modalData.item.budget,
      deadline: modalData.item.deadline,
      time_estimate: modalData.item.time_estimate,
      price_estimate: modalData.item.price_estimate,
      project_id: modalData.item.project_id,
      title: modalData.item.title,
      client_story: modalData.item.client_story,
      status: modalData.item.status,
      type: modalData.item.type,
      deliverables: type === 'delete' ? '' : value,
    }

    setLoading(true)
    const res = await updateTask(modalData.item.id, data)
    if (res.status === 200) {
      if (type === 'update') setModalData({ type: 'update', item: value })
      else setModalData({ type: 'delete' })
    }
    setLoading(false)
  }

  useEffect(() => {
    if (modalData && modalData.item) {
      setValue(modalData.item.deliverables || '')
    }
    setLoading(false)
  }, [])

  return (
    <div className={'create_milestones__body'} style={{ position: 'relative' }}>
      {loading && <ModalLoader />}
      <ReactEditor
        value={value}
        onChange={(event:string) => setValue(event)}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (modalData.type === 'updateModal' && event.keyCode === 13 && value.length === 0) {
            if (window.confirm(t('deliverablesModal.confirmDelete'))) updateElement('delete').then()
          }
        }}
        valid={!errorMessage}
        errorMessage={errorMessage}
      />
      <button
        onClick={() => updateElement('update')}
        className={'create_milestones__save btn-black'}
      >
        {t('deliverablesModal.button')}
      </button>
    </div>
  )
}

export default DeliverablesModal
