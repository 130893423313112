import React from "react";
import {ServiceProviderKanbanCountType, ServiceProviderKanbanType} from "../ServiceProviderKanban";
import "../service-provider-kanban.scss"
import {ServiceProviderKanbanItem} from "./ServiceProviderKanbanItem";
import requestIcon from "../../../../assets/images/partnerRequest/draft.svg"
import waitIcon from "../../../../assets/images/partnerRequest/clickIconWhite.svg"
import doneIcon from "../../../../assets/images/partnerRequest/done.svg"
import {partner_request_statuses} from "../../partner_request_statuses";

const getHeading = (status: string, accepted: number) => {
    return {
        background: partner_request_statuses(status, accepted, '0').background,
        icon: partner_request_statuses(status, accepted, '0').icon,
        text: partner_request_statuses(status, accepted, '0').title
    }
}

export const ServiceProviderKanbanItems: React.FC<{ kanban: ServiceProviderKanbanType, kanbanCount: ServiceProviderKanbanCountType }> = ({ kanban, kanbanCount }) => {
    return (
        <div className={'service-provider-kanban-items'}>
            <div className="service-provider-kanban-column">
                <h4>New Requests&nbsp;&nbsp;&nbsp;{(kanban['new'] || []).length}/{kanbanCount['new']?.count}</h4>
                {(kanban['new'] || []).map(el =>
                    <ServiceProviderKanbanItem el={el} heading={getHeading(el.status, el.offer.accepted)} />)}
            </div>
            <div className="service-provider-kanban-column">
                <h4>AWAITING CLIENT’s APPROVAL&nbsp;&nbsp;&nbsp;{(kanban['manager accepted'] || []).length}/{kanbanCount['manager accepted']?.count}</h4>
                {(kanban['manager accepted'] || []).map(el =>
                    <ServiceProviderKanbanItem el={el} heading={getHeading(el.status, el.offer.accepted)} />)}
            </div>
            <div className="service-provider-kanban-column">
                <h4>Tasks in progress&nbsp;&nbsp;&nbsp;{(kanban['client accepted'] || []).length}/{kanbanCount['client accepted']?.count}</h4>
                {(kanban['client accepted'] || []).map(el =>
                    <ServiceProviderKanbanItem el={el} heading={getHeading(el.status, el.offer.accepted)} />)}
            </div>
            <div className="service-provider-kanban-column">
                <h4>DONE&nbsp;&nbsp;&nbsp;{(kanban['done'] || []).length}/{kanbanCount['done']?.count}</h4>
                {(kanban['done'] || []).map(el =>
                    <ServiceProviderKanbanItem el={el} heading={{ background: '#5D8C51', icon: doneIcon, text: 'DONE' }} />)}
            </div>
        </div>
    )
}