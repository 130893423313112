import logoImage from '../../../../assets/images/onBoardingLogo.svg'
import React, {useContext} from "react";
import {MainContextProvider} from "../../../../context/MainContext";
import './welcomeOnBoarding.scss'

export const WelcomeOnBoarding: React.FC = () => {
    const { setModalType } = useContext(MainContextProvider)

    return (
        <div className={'welcome_onBoarding'}>
            <img className={'welcome_onBoarding__img'} src={logoImage} alt=""/>
            <h2 className={'welcome_onBoarding__heading'}>Welcome to your Legal Nodes Profile</h2>
            <p className={'welcome_onBoarding__description'}>Let’s get you onboard. Please follow the instructions</p>
            <button onClick={() => setModalType('')} className={'welcome_onBoarding__button btn-black'}>CONTINUE</button>
        </div>
    )
}