import "./mobile-heading.scss"
import React, {useContext, useState} from "react";
import burgerIcon from "../../../../assets/images/kanbanIcons/burger-icon.svg";
import closeIcon from "../../../../assets/images/kanbanIcons/closeIcon.svg";
import LeftNav from "../../../../containers/Layout/Layout/LeftNav";
import {NavLink, useLocation} from 'react-router-dom'
import boardIcon from '../../../../assets/images/leftNav/board.svg'
import reportsIcon from '../../../../assets/images/leftNav/archive.svg'
import invoiceIcon from '../../../../assets/images/invoicesIcon.svg'
import settingsIcon from '../../../../assets/images/nav/settings-black.svg'
import {MainContextProvider} from "../../../../context/MainContext";

function switchHeading(location: string) {
    if (location.includes('onboarding')) {
        return 'Onboarding'
    } else if (location.includes('offer') || location.includes('legal-request')) {
        return 'Legal Board'
    } else if (location.includes('settings/history')) {
        return 'Billing'
    } else if (location.includes('settings')) {
        return 'Settings'
    } else if (location.includes('reports')) {
        return 'Reports'
    } else {
        return 'Legal Board'
    }
}

export const MobileHeading: React.FC = () => {
    const { userData, setModalType } = useContext(MainContextProvider)
    const [isOpen, setIsOpen] = useState(false)
    const location = useLocation()

    if (userData?.status.type_code !== 100) {
        return <></>
    }

    if (userData.flows.onboarding === 0) {
        return (
            <>
                <div onClick={() => setIsOpen(true)} className={'mobile-legal-board-heading'}>
                    <img src={burgerIcon} alt=""/>
                    <h2>{switchHeading(location.pathname)}</h2>
                </div>
                {isOpen && <div className="mobile-nav-modal-window__container">
                    <div className="mobile-nav-modal-window">
                        <div onClick={() => setIsOpen(false)} className="mobile-nav-modal-window__heading">
                            <img src={closeIcon} alt=""/>
                            Legal Board
                        </div>
                        <div className="mobile-nav-modal-window__nav">
                            <NavLink onClick={() => setIsOpen(false)} to={'/onboarding'} className={'mobile-nav-modal-window__link'}>
                                <div>
                                    <img src={boardIcon} alt=""/>
                                    <span>Onboarding</span>
                                </div>
                            </NavLink>
                            <NavLink onClick={() => setIsOpen(false)} to={'/settings/history'} className={'mobile-nav-modal-window__link'}>
                                <div>
                                    <img src={invoiceIcon} alt=""/>
                                    <span>Billing</span>
                                </div>
                            </NavLink>
                        </div>
                        <div className="mobile-nav-modal-window__footer">
                            <button
                                disabled={userData.isTrial}
                                onClick={() => {
                                    setModalType('client_kanban_request')
                                    setIsOpen(false)
                                }}
                                className={'mobile-nav-modal-window__footer-btn-black'}>New REQUEST</button>
                            {userData?.status.type_code === 100 && +userData?.flows.onboarding === 1 && <button
                                onClick={() => {
                                    setModalType('invite_team_mate')
                                    setIsOpen(false)
                                }}
                            >Invite teammate</button>}
                        </div>
                    </div>
                </div>}
            </>
        )
    }

    return (
        <>
            <div onClick={() => setIsOpen(true)} className={'mobile-legal-board-heading'}>
                <img src={burgerIcon} alt=""/>
                <h2>{switchHeading(location.pathname)}</h2>
            </div>
            {isOpen && <div className="mobile-nav-modal-window__container">
                <div className="mobile-nav-modal-window">
                    <div onClick={() => setIsOpen(false)} className="mobile-nav-modal-window__heading">
                        <img src={closeIcon} alt=""/>
                        Legal Board
                    </div>
                    <div className="mobile-nav-modal-window__nav">
                        <NavLink onClick={() => setIsOpen(false)} to={'/dashboard'} className={'mobile-nav-modal-window__link'}>
                            <div>
                                <img src={boardIcon} alt=""/>
                                <span>Legal Board</span>
                            </div>
                        </NavLink>
                        <NavLink onClick={() => setIsOpen(false)} to={'/reports'} className={'mobile-nav-modal-window__link'}>
                            <div>
                                <img src={reportsIcon} alt=""/>
                                <span>Reports</span>
                            </div>
                        </NavLink>
                        <NavLink onClick={() => setIsOpen(false)} to={'/settings/history'} className={'mobile-nav-modal-window__link'}>
                            <div>
                                <img src={invoiceIcon} alt=""/>
                                <span>Billing</span>
                            </div>
                        </NavLink>
                        <a className={'mobile-nav-modal-window__link'} style={{ marginBottom: '18px' }}>
                            <div>
                                <img src={settingsIcon} alt=""/>
                                <span>Settings</span>
                            </div>
                        </a>
                        <NavLink onClick={() => setIsOpen(false)} to={'/settings/account'} className={'mobile-nav-modal-window__sub-link'}>
                            <div><span>Account Information</span></div>
                        </NavLink>
                        <NavLink onClick={() => setIsOpen(false)} to={'/settings/subscriptions'} className={'mobile-nav-modal-window__sub-link'}>
                            <div><span>Your Monthly Plans</span></div>
                        </NavLink>
                        <NavLink onClick={() => setIsOpen(false)} to={'/settings/history'} className={'mobile-nav-modal-window__sub-link'}>
                            <div><span>Invoices</span></div>
                        </NavLink>
                    </div>
                    <div className="mobile-nav-modal-window__footer">
                        <button
                            disabled={userData.isTrial}
                            onClick={() => {
                                setModalType('client_kanban_request')
                                setIsOpen(false)
                            }}
                            className={'mobile-nav-modal-window__footer-btn-black'}>New REQUEST</button>
                        {userData?.status.type_code === 100 && +userData?.flows.onboarding === 1 && <button
                            onClick={() => {
                                setModalType('invite_team_mate')
                                setIsOpen(false)
                            }}
                        >Invite teammate</button>}
                    </div>
                </div>
            </div>}
        </>
    )
}