import React, {useState} from "react";
import avatar from '../../../../assets/images/profileIcons/avatar.svg'
import bio from '../../../../assets/images/profileIcons/bio.svg'
import cup from '../../../../assets/images/profileIcons/cup.svg'
import education from '../../../../assets/images/profileIcons/education.svg'
import location from '../../../../assets/images/profileIcons/location.svg'
import service from '../../../../assets/images/profileIcons/service.svg'
import verify from '../../../../assets/images/profileIcons/verify.svg'
import langIcon from '../../../../assets/images/profileIcons/emailIcon.svg'

type ManagerProfileType = {
    name: string
    avatar_url: string
    bio: string
    education: {
        education: string
        id: number
        user_id: number
        years: string
    }[]
    location: {
        id: number
        location: string
        user_id: number
    }[]
    experience: string
    fullname: string
    hourly_pay: string
    languages: string
    projects: {project: string}[]
    service: string
    title: string
}

type Props = {
    provider: ManagerProfileType
}

export const ProviderInfo: React.FC<Props> = ({ provider }) => {
    const managerInfo: ManagerProfileType = provider

    return (
        <div className={'provider-profile-for-vlo'}>
            <h1>Profile</h1>
            <div className="manager_profile__heading" style={{ marginTop: '20px' }}>
                <img style={{ height: '96px' }} src={managerInfo?.avatar_url || avatar} alt=""/>
                <div className={'manager_profile__main'}>
                    <div className={'manager_profile__title'}>
                        <div>
                            <div className={'manager_profile__title-name'}>{managerInfo?.name}</div>
                            {/*<img src={managerInfo?.avatar_path || verify} alt=""/>*/}
                        </div>
                        <div className={'manager_profile__sum'}>
                            {managerInfo?.hourly_pay}
                        </div>
                    </div>
                    <div className="manager_profile__position">
                        {managerInfo?.title}
                    </div>
                    {
                        managerInfo?.location && managerInfo?.location[0] && managerInfo.location.map((item) => (
                            <div className="manager_profile__location">
                                <img src={location} alt=""/>
                                <span>{item.location}</span>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="manager_profile__body" style={{ height: 'initial' }}>
                <div style={{ display: 'flex' }}>
                    {managerInfo?.languages && <div className="manager_profile__info" style={{ width: '50%' }}>
                        <div>
                            <img src={langIcon} alt=""/>
                            <b>Languages</b>
                        </div>
                        <p>{managerInfo?.languages}</p>
                    </div>}
                    {managerInfo?.experience && <div className="manager_profile__info" style={{ width: '50%' }}>
                        <div>
                            <img src={cup} alt=""/>
                            <b>Years of experience</b>
                        </div>
                        <p>{managerInfo?.experience}</p>
                    </div>}
                </div>
                {managerInfo?.service && <div className="manager_profile__info">
                    <div>
                        <img src={service} alt=""/>
                        <b>SERVICE</b>
                    </div>
                    <p>{managerInfo?.service}</p>
                </div>}
                {managerInfo?.education && managerInfo?.education[0] && <div className="manager_profile__info">
                    <div>
                        <img src={education} alt=""/>
                        <b>EDUCATION</b>
                    </div>
                    {
                        managerInfo?.education.map((item) => (
                            <p>
                                {item.education}
                                <em>{item.years}</em>
                            </p>
                        ))
                    }
                </div>}
                {managerInfo?.projects && managerInfo?.projects[0] && <div className="manager_profile__info">
                    <div>
                        <img src={education} alt=""/>
                        <b>Projects</b>
                    </div>
                    {
                        managerInfo?.projects.map((item) => (
                            <p>
                                {item.project}
                            </p>
                        ))
                    }
                </div>}
                {managerInfo?.bio && <div className="manager_profile__info">
                    <div>
                        <img src={bio} alt=""/>
                        <b>BIO</b>
                    </div>
                    <p>{managerInfo?.bio}</p>
                </div>}
            </div>
        </div>
    )
}