import React from "react";
import './client_kanbanStep_notifications.scss'
import nodeIcon from '../../../../assets/images/stepsIcons/node.svg'
import waitIcon from '../../../../assets/images/stepsIcons/wait.svg'
import cursorIcon from '../../../../assets/images/stepsIcons/cursor.svg'
import clockIcon from '../../../../assets/images/stepsIcons/clock.svg'
import doneIcon from '../../../../assets/images/stepsIcons/done.svg'
import {DropDownNotifications} from "../../ClientKanbanData/DropDownNotifications/DropDownNotifications";
import clockNewIcon from '../../../../assets/images/icons/clientKanbanIcons/clock.svg'
import doneNewIcon from '../../../../assets/images/icons/clientKanbanIcons/done.svg'
import handNewIcon from '../../../../assets/images/icons/clientKanbanIcons/heand.svg'
import lrNewIcon from '../../../../assets/images/icons/clientKanbanIcons/legalRequest.svg'
import penNewIcon from '../../../../assets/images/icons/clientKanbanIcons/pen.svg'
import waitNewIcon from '../../../../assets/images/icons/clientKanbanIcons/wait.svg'
import noNewIcon from '../../../../assets/images/icons/clientKanbanIcons/noIcon.svg'


type Props = {
    place: 'kanban' | 'offer'
    accepted: string
    is_done: number
    hasTasks: number
    notifications?: any[]
    isWaitIcon?: boolean
}

export const switchNextSteps = (accepted: string, is_done: number, hasTasks: number) => {
    if(is_done === 1) {
        return {title: 'DONE', background: '#5D8C51', icon: doneNewIcon, color: '#FFF'}
    }

    if(hasTasks >= 1) {
        return {title: 'Deliverable has to be approved', background: '#FF7C04', icon: handNewIcon, color: '#FFF'}
    }

    switch (+accepted) {
        // case 6: return {title: 'Discuss with VLO', background: 'rgba(0, 18, 66, 0.1)', icon: nodeIcon};
        case 200: return {title: 'no data', background: 'rgba(33, 88, 234, 0.1)', icon: waitIcon};
        case 300: return {title: 'VLO is Preparing the offer', background: 'rgba(33, 88, 234, 0.1)', icon: waitIcon, color: '#000'};
        case 0: return {title: 'Preparing Offer', background: '#E4EAF8', icon: penNewIcon, color: '#000'};
        case 1: return {title: 'Wait FOR VLO’s response', background: '#E4EAF8', icon: clockNewIcon, color: '#000'};
        case 2: return {title: 'Please accept the offer', background: '#FF7C04', icon: handNewIcon, color: '#FFF'};
        case 3: return {title: 'Awaiting payment', background: '#FF7C04', icon: noNewIcon, color: '#FFF'};
        case 5: return {title: 'Work In-Progress', background: '#2361FF', icon: waitNewIcon, color: '#FFF'};
        case 6: return {title: 'Discuss with VLO', background: '#FFF', icon: lrNewIcon, color: '#000'};
        default: return { title: 'no data' , background: '', icon: noNewIcon, color: '#FFF'};
    }
}

export const ClientKanbanStepNotifications: React.FC<Props> = ({ place, accepted, is_done, hasTasks, isWaitIcon, notifications }) => {
    return (
        <div
            style={{ background: switchNextSteps(accepted, is_done, hasTasks).background }}
            className={`client_kanbanStep_notifications client_kanbanStep_notifications__${place}`}>
            <div className="client_kanbanStep_notifications__info">
                <img className={'client_kanbanStep_notifications__img'} src={switchNextSteps(accepted, is_done, hasTasks).icon} alt=""/>
                <span style={{ color: switchNextSteps(accepted, is_done, hasTasks).color }}>{window.location.host === 'localhost:3000' ? `${accepted} ` : ''}{switchNextSteps(accepted, is_done, hasTasks).title}</span>
            </div>
            <div className="offerHeader__right">
                <DropDownNotifications
                    show={{
                        //wait: isWaitIcon,
                        comments: 0,
                        notifications: (notifications || []).length
                    }}
                    isShowNotificationsDropDown
                    notificationsItems={notifications}
                />
            </div>
        </div>
    )
}