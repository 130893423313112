export const TASK_STATUSES = [
  {
    value: '2', label: 'To Do', color: '#293DED', textColor: '#000000',
  },
  {
    value: '1', label: 'In Progress', color: '#293DED', textColor: '#000000',
  },
  {
    value: '5', label: 'Awaiting client approval', color: '#C4C4C4', textColor: '#e3a105',
  },
  {
    value: '3', label: 'Done', color: '#C4C4C4', textColor: '#27a788',
  },
]
