import React, {useContext, useEffect, useState} from "react";
import customAxios from "../../../service/api/interceptors";
import {ReactSelect} from "../../../components/UI";
import {CompanyType} from "../../../types/company.type";
import {CustomCheckbox} from "../../../components/other/Registration/CustomCheckbox/CustomCheckbox";
import "./red_flags.scss"
import {MainContextProvider} from "../../../context/MainContext";
import Loader from "../../../components/other/Loader";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";

type RedFlagType = {
    id: number
    name: string
    slug: string
}

export const RedFlags = () => {
    const { setModalType } = useContext(MainContextProvider)
    const [loading, setLoading] = useState(true)
    const [companies, setCompanies] = useState<CompanyType[]>([])
    const [companyId, setCompanyId] = useState('')
    const [redFlagsTypes, setRedFlagsTypes] = useState<RedFlagType[]>([])
    const [checkedItems, setCheckedItems] = useState<number[]>([])
    const [tch, setTch] = useState(false)
    
    const getCompanyFlagsByCompany = async (id: number) => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`/company/company/${id}/red-flags`)
            if (status === 200 && Array.isArray(data)) {
                setCheckedItems(data.filter(el => +el.status === 1).map(el => (el.red_flag_id)))
            }
        } catch (e) {}
        setLoading(false)
    }

    const getData = async () => {
        try {
            const { data: clientsData, status: clientStatus } = await customAxios.get(`/company/company/get-companies`)
            const { status, data } = await customAxios.get(`/company/company/all-red-flags`)

            if (clientStatus === 200) {
                setCompanies(clientsData)
                setCompanyId(clientsData[0]?.id)
                getCompanyFlagsByCompany(clientsData[0]?.id)
            }

            if (status === 200) {
                setRedFlagsTypes(data)
            }
        } catch (e) {}
        setLoading(false)
    }
    
    const postData = async () => {
        setLoading(true)
        try {
            const postData = redFlagsTypes.map(el => ({ id: el.id, status: checkedItems.includes(el.id) ? 1 : 0 }))
            const { status, data } = await customAxios.post(`/company/company/${companyId}/red-flags`, {red_flag: postData})
            if (status === 200) {
                setModalType('')
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        getData().then()
    }, [])
    
    const setDataItems = (id: number) => {
        if (checkedItems.includes(id)) {
            setCheckedItems(checkedItems.filter(el => el !== id))
        } else {
            setCheckedItems([ ...checkedItems, id ])
        }
    }

    if (loading) {
        return <div className={'red_flags'} style={{ position: 'relative', minHeight: '200px' }}>
            {loading && <ModalLoader />}
        </div>
    }

    return (
        <div className={'red_flags'}>
            <div className="create_legal_request__description">
                Choose the client and user the fields to submit an Account Health Report.
            </div>

            <ReactSelect
                className={'create_manager_offer__react-select'}
                value={companyId}
                options={companies.map(item => ({ value: item.id, label: item.name }))}
                onChange={e => {
                    setCompanyId(e.value)
                    getCompanyFlagsByCompany(e.value)
                }}
                valid={true}
            />
            <div className={'mt10'} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {
                    (redFlagsTypes || []).map(el => (
                        <div className={'w50 mt5 red_flags__item'}>
                            <CustomCheckbox
                                checked={checkedItems.includes(el.id)}
                                onChange={() => {
                                    setDataItems(el.id)
                                    setTch(true)
                                }}
                                label={el.name}
                                isStopPropagation={false}
                            />
                        </div>
                    ))
                }
            </div>

            <button disabled={!tch} className={'btn-black w100 mt25'} onClick={postData}>SUBMIT</button>
        </div>
    )
}