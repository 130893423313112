import logoIcon from "../../../../../assets/images/logo.svg";
import ModalContainer from "../../../../../components/ModalContainer";
import React from "react";
import moment from "moment";

export const UnsubscribeModalConfirm: React.FC<{
    isOpen: boolean, close: () => void, subscription: any, ok: () => void
}> = ({ isOpen, close, subscription, ok }) => {
    return (
        <div className={'payment-plan__modal'}>
            <ModalContainer isOpen={isOpen} close={close}>
                <div>
                    <div className={'payment-plan-information-modal__logo'}>
                        <img src={logoIcon} alt=""/>
                    </div>
                    <div className={'payment-plan__modal__text'}>
                        <em>UNSUBSCRIBE</em>
                        <h4>Are you sure you want <br />
                            to cancel your subscription?</h4>
                    </div>
                    <div className={'payment-plan__modal-info'}>
                        <span>{subscription.title}  <em>expires ({subscription.date})</em></span>
                    </div>
                    <div className={'payment-plan-information-modal__text mt10'}>
                        <button onClick={ok} className={'btn-black w100 mt20'}>YES</button>
                        <button
                            onClick={close}
                            style={{ border: '1px solid #000', background: '#fff', color: '#000' }}
                            className={'btn-black w100 mt5'}>NO</button>
                    </div>
                </div>
            </ModalContainer>
        </div>
    )
}