import React, {useEffect, useState} from "react";
import ModalContainer from "../../../components/ModalContainer/ModalContainer";
import Input from "../../../components/UI/Input/Input";
import {all_countries} from "../../../helpers/all_countries";
import {CSSTransition} from "react-transition-group";

type SelectBillingCountryProps = {
    isOpen: boolean
    initialValue?: string
    onChange: (key: string) => void
    close: () => void
}

export const SelectBillingCountry: React.FC<SelectBillingCountryProps> = ({ isOpen = false, initialValue = 'GB', onChange, close }) => {
    const [isDropDownOpen, setIsDropDownOpen] = useState(false)
    const [value, setValue] = useState('GB')
    const [search, setSearch] = useState('')

    useEffect(() => {
        setValue(initialValue)
    }, [isOpen, initialValue])

    return (
        <ModalContainer heading={'Select your country'} isOpen={isOpen} modalClass={'create_legal_request'} close={close}>
            <div style={{ paddingTop: '20px' }}>
                <Input
                    otherProps={{
                        placeholder: isDropDownOpen ? 'Search by country from options' : all_countries.find(el => el.code === value)?.name,
                        value: search,
                        onChange: e => setSearch(e.target.value),
                        onFocus: () => setIsDropDownOpen(true),
                        onBlur: () => setIsDropDownOpen(false)
                    }}
                />
                <CSSTransition in={true} classNames={'my-node'} timeout={200} unmountOnExit>
                    <div className="create_legal_request__dropdown" style={{ padding: '10px' }}>
                        <div className="create_legal_request__need-items" style={{ marginTop: 0 }}>
                            {
                                all_countries.filter(el => el.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())).map(el => (
                                    <div onClick={() => {
                                        setValue(el.code)
                                        setSearch('')
                                        setIsDropDownOpen(false)
                                    }} className="create_legal_request__need-item" style={{ marginTop: 0, padding: '5px 0' }}>
                                        <div className="create_legal_request__radio">
                                            {el.code === value && <div className="create_legal_request__radio-active"/>}
                                        </div>
                                        <span>{el.name}</span>
                                    </div>
                                ))
                            }
                            {
                                all_countries.filter(el => el.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())).length === 0 &&
                                    <p style={{ fontSize: '14px', padding: '5px' }}>No results</p>
                            }
                        </div>
                    </div>
                </CSSTransition>
                <button className={'btn-black w100 mt15 text-uppercase'} onClick={() => onChange(value)}>Submit</button>
            </div>
        </ModalContainer>
    )
}