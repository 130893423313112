import React from "react";
import icon from "../../../../assets/images/partnerRequest/clickIconWhite.svg"

type Props = {
    confirmPayment: () => void
}

export const ConfirmClientPayment: React.FC<Props> = ({ confirmPayment }) => {
    return (
        <div className={'partner-request-payment-confirm'}>
            <div className="partner-request-payment-confirm__heading">
                <img src={icon} alt=""/>
                <span>PLEASE CONFIRM THE RECEIVING OF THE PAYMENT FROM THE CLIENT</span>
            </div>
            <div className="partner-request-payment-confirm__body">
                <div className="partner-request-payment-confirm__body-table">
                    <div className="partner-request-payment-confirm__body-td" style={{ padding: '35px 0' }}>
                        {/*<p>
                            The client has confirmed having made the payment according to your invoice.
                            Please confirm whether the payment has already arrived?
                        </p>*/}
                        <p>
                            We are arranging the payment from the client. Please confirm the payment once it arrives to your receiving account.
                        </p>
                    </div>
                    <div style={{ padding: '30px' }}>
                        <button onClick={confirmPayment} className={'btn-black text-uppercase w100'}>CONFIRM PAYMENT</button>
                    </div>
                </div>
            </div>
        </div>
    )
}