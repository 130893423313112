import React, {useContext} from "react";
import {MainContextProvider} from "../../../../context/MainContext";
import creditIcon from "../../../../assets/images/planAndCredits/addCredit.svg"
import {useHistory} from "react-router-dom";

export const HeaderCreditBalance: React.FC = () => {
    const { userData, creditCount } = useContext(MainContextProvider)
    const history = useHistory()

    if (!creditCount.isCreditSubscription) {
        return <></>
    }

    return (
        <div className={'header-credit-balance'}>
            <span>Credits Balance: <b>{creditCount?.total_count || 0}</b></span>
            <img onClick={() => history.push(`/settings/payment-plan`)} src={creditIcon} alt=""/>
        </div>
    )
}