import {useContext, useEffect} from "react";
import {MainContextProvider} from "../../../context/MainContext";

export const ProviderTermsUsePrivacyPolicy = () => {
    const { setModalType, setModalData, userData } = useContext(MainContextProvider)
    const getInitialData = () => {
        if (!Number(userData?.is_terms_accepted)) {
            setModalType('terms_use_privacy_policy')
            setModalData({ hideClose: true })
        }
    }

    useEffect(() => {
        getInitialData()
    }, [])

    return (
        <></>
    )
}