import "./header_timer.scss"
import icon from "../../../assets/images/image-20230828-223905.png"
import arrow from "../../../assets/images/arrowBlack.svg"
import pen from "../../../assets/images/pen.svg"
import React, {useContext, useEffect, useRef, useState} from "react";
import Notification from "../Notification/Notification";
import {CSSTransition} from "react-transition-group";
import customAxios from "../../../service/api/interceptors";
import {CompanyType} from "../../../types/company.type";
import {useHistory} from "react-router-dom";
import {useOnClickOutside} from "../../../hooks/useOnClickOutside";
import {MainContextProvider} from "../../../context/MainContext";

type TimelyItemType = {
    note: string
    duration: {
        hours: number
        minutes: number
        seconds: number
    }
    project: {
        id: number
    }
    updated_at: number
    created_at: number
}

export const AllUserTimers = () => {
    const { headerTimer } = useContext(MainContextProvider)
    const [isOpen, setIsOpen] = useState(false)
    const [items, setItems] = useState<TimelyItemType[]>([])
    const [companies, setCompanies] = useState<CompanyType[]>([])
    const dropdownRef = useRef<HTMLDivElement>(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })

    const getData = async () => {
      try {
          const { data: companyItems } = await customAxios.get(`/company/company/get-companies?expand=client,company`)
          setCompanies(companyItems)
          const { status, data } = await customAxios.get(`/timely/events`)
          if (status === 200) {
              setItems(data)
          }
      } catch (e) {
          
      }
    }
    
    useEffect(() => {
        getData().then()
    }, [headerTimer])

    function getMinutesByDate(created_at: number) {
        const date = (new Date(created_at * 1000)).getMinutes()
        if (date < 9) {
            return `0${date}`;
        } else {
            return date
        }
    }

    return (
        <div className={'all_timely_events'} ref={dropdownRef}>
            {items.length > 0 && <a id={`header-timely-button`} className="header__right-link" onClick={() => setIsOpen(!isOpen)}>
                <img src={icon} alt=""/>
                <span style={{background: '#8e9ec8'}} className={'header__right-counter'}>{items.length}</span>
            </a>}
            <CSSTransition in={isOpen} timeout={200} classNames={'my-node'} unmountOnExit>
                <div>
                    <div className={'header__right-link-squire'} />
                    <div className={'notification'}>
                        <a style={{ display: 'block', textDecoration: 'none' }} className="notification__container all_timely_events__container" href={`https://app.timelyapp.com/979957/calendar/day`} target={"_blank"}>
                            <div className="all_timely_events__heading">
                                <img src={pen} alt=""/>
                                <span>Edit tracked time</span>
                            </div>
                            {
                                (items || []).map((el) => (
                                    <div className="all_timely_events__item">
                                        <img style={{ transform: 'rotate(90deg)' }} src={arrow} alt=""/>
                                        <div>
                                            <span><b>{(companies || []).find(company => +company.timely_project_id === +el.project.id)?.name}</b> - {el.note}</span><br />
                                            <em>{(new Date(el.created_at * 1000)).getHours()}:{getMinutesByDate(el.created_at)} - {el.duration.hours}h {el.duration.minutes}m</em>
                                        </div>
                                    </div>
                                ))
                            }
                        </a>
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
}