import React from "react";
import icon from "../../../../assets/images/partnerRequest/notInterested.svg"

export const PartnerNotInterested: React.FC = () => {
    return (
        <div className={'pr-not-interested'}>
            <div className="pr-not-interested__heading">
                <img src={icon} alt=""/>
                <span>The provider is not interested</span>
            </div>
            <div className="pr-not-interested__body">
                <p>
                    The provider has stated that they are NOT INTERESTED in working with this project.
                    This request will be automatically removed from the system, no further actions are required.
                </p>
            </div>
        </div>
    )
}