import React, {useContext} from "react";
import {MainContextProvider} from "../../../context/MainContext";

export type TickerTypes = "clientWarnTicker" | "managerWarnTicker" | "managerFrozenTicker" | "managerUnsubscribedTicker" | "clientUnPainAllCompanies" | 'trial' | 'unsubscribed' | ''

const tickerConfig = (type: string) => {
    switch (type) {
        case 'managerWarnTicker':
            return 'THIS COMPANY HAS UNPAID INVOICES. IT MAY BECOME FROZEN IF THE INVOICES ARE NOT PAID BEFORE THE DUE DATE.'
        case 'managerFrozenTicker':
            return 'THIS COMPANY HAS BEEN FROZEN DUE TO UNPAID INVOICES. PLEASE AVOID PROVIDING SERVICES TO THIS COMPANY UNTIL THE DEBT IS PAID. THANK YOU.'
        case 'managerUnsubscribedTicker':
            return 'THIS COMPANY DOES NOT HAVE AN ACTIVE MONTHLY PLAN. PLEASE AVOID PROVIDING SERVICES TO THIS COMPANY UNTIL THEIR SUBSCRIPTION IS ACTIVATED. THANK YOU.'
        case 'clientWarnTicker':
            return 'You have unpaid invoices on your account. Please pay them immediately to avoid any disruption to your services.'
        case 'clientUnPainAllCompanies':
            return 'You have unpaid invoices on your account. Please pay them immediately to avoid any disruption to your services.'
        case 'trial':
            return 'You are using Trial Version of Legal Nodes Workspace. Please go to "Billing" to Upgrade to Full Service.'
        case 'unsubscribed':
            return 'Your subscription has ended so some features are unavailable. You can still view previous correspondence and deliverables. To unlock all features contact sales here.'
        default:
            return '';
    }
}

const tickerColors = (type: string) => {
    switch (type) {
        case 'managerFrozenTicker':
            return '#ff602e'
        case 'managerWarnTicker':
            return '#ffcf23'
        case 'managerUnsubscribedTicker':
            return '#caced9'
        case 'clientWarnTicker':
            return '#FFDF6C'
        case 'clientUnPainAllCompanies':
            return '#FFDF6C'
        case 'trial':
            return '#5e93ff'
        case 'unsubscribed':
            return 'grey'
        default:
            return '';
    }
}

const tickerLinks = (type: string) => {
    if (type === 'unsubscribed') {
        return '/settings/subscriptions'
    } else {
        return '/settings/history'
    }
}

export const Ticker: React.FC<any> = ({ history }) => {
    const { headerNotification, userData, setModalType } = useContext(MainContextProvider)

    if (userData?.isTrial && userData.flows.onboarding === 1) {
        return <div onClick={() => setModalType('choose_payment_plan')} style={{ backgroundColor: '#5e93ff', cursor: 'pointer' }} className="marquee_block">
            <p>Unlock all features and add-ons with a monthly plan - Upgrade now!</p>
        </div>
    }

    if (!headerNotification) return <></>

    return (
        <div
            onClick={() => userData?.status.type_code === 100 && history.push(tickerLinks(headerNotification))}
            style={{ backgroundColor: tickerColors(headerNotification), cursor: 'pointer' }}
            className="marquee_block"
        ><p>
            {tickerConfig(headerNotification)}
        </p></div>
    )
}