import React from "react";
import logoIcon from "../../../../../assets/images/logo.svg"
import ModalContainer from "../../../../../components/ModalContainer";

type Props = {
    isOpen: boolean
    close: () => void
    ok: () => void
}

export const ConfirmDeletePlannerItem: React.FC<Props> = ({
    isOpen, close, ok
}) => {
    return (
        <ModalContainer isOpen={isOpen} close={close}>
            <div>
                <div className={'flex justifyCenter'}>
                    <img src={logoIcon} alt=""/>
                </div>
                <div className={'mt40 text-center color-blue font-size-11 letter-spacing-1'}>
                    DELETE
                </div>
                <div className="heading3 text-center mt15">
                    Are you sure you want to delete this planned request?
                </div>
                <button onClick={ok} className={'btn btn-black w100 text-uppercase mt40'}>
                    YES
                </button>
                <button onClick={close} className={'btn-white w100 text-uppercase mt10'}>
                    NO
                </button>
            </div>
        </ModalContainer>
    )
}