import './stripe-payment-methods.scss'
import {CustomCheckbox} from "../../../../components/other/Registration/CustomCheckbox/CustomCheckbox";
import React, {useContext, useEffect, useState} from "react";
import {useHistory, useParams, useRouteMatch} from "react-router-dom";
import axios from "axios";
import * as Process from "process";
import {MainContextProvider} from "../../../../context/MainContext";

type SubscriptionType = {
    customer: string,
    default_payment_method: string
}

type PaymentItemsType = {
    id: string
    card: {
        last4: string
        exp_month: number
        exp_year: number
        brand: string
    }
}

export const CardPaymentMethod: React.FC<any> = ({ match }) => {
    const { params: { id } } = match
    const { setLoading } = useContext(MainContextProvider)
    const [subscription, setSubscription] = useState<SubscriptionType>({} as SubscriptionType)
    const [paymentItems, setPaymentItems] = useState<PaymentItemsType[]>([])
    const history = useHistory()

    const getPaymentItems = async (customer: string) => {
        setLoading(true)
        try {
            const { status, data } = await axios.get(`${process.env.REACT_APP_STRIPE_API_URL}/stripe-other/customer-payment-methods/${customer}?token=${localStorage.getItem('accessToken')}`)
            if (status === 200) {
                setPaymentItems(data.items.data)
            }
        } catch (e) {}
        setLoading(false)
    }
    
    const getSubscription = async () => {
        setLoading(true)
        try {
            const { status, data, request } = await axios.get(`${process.env.REACT_APP_STRIPE_API_URL}/stripe-other/subscription/${id}?token=${localStorage.getItem('accessToken')}`)
            if (status === 200) {
                setSubscription(data)
                await getPaymentItems(data.customer)
            }
            console.log(request)
        } catch (e: any) {
            if (e.response.status === 404) {
                history.push('/404')
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        getSubscription().then()
        document.title = 'Edit subscription payment data'
    }, [])

    const addCard = async () => {
        setLoading(true)
        try {
            const { status, data } = await axios.post(`${process.env.REACT_APP_STRIPE_API_URL}/stripe-other/setup/${subscription.customer}`, {
                url: process.env.REACT_APP_SITE_LINK + '/settings/stripe-payment-method/' + id
            })
            if (status === 200) {
                window.location.href = data
            }
        } catch (e) {}
        setLoading(false)
    }

    const setCardAsDefault = async (payment_method: string) => {
        setLoading(true)
        try {
            const { status, data } = await axios.post(`${process.env.REACT_APP_STRIPE_API_URL}/stripe-other/update_payment_method/${id}`, {
                payment_method
            })
            if (status === 200) {
                await getSubscription()
            }
        } catch (e) {}
        setLoading(false)
    }

    const deleteCard = async (payment_method: string) => {
        setLoading(true)
        try {
            const { status, data } = await axios.delete(`${process.env.REACT_APP_STRIPE_API_URL}/stripe-other/delete_payment_method/${payment_method}`)
            if (status === 200) {
                await getSubscription()
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <div className={'stripe-payment-methods'}>
            <div className="stripe-payment-methods__header">
                <h4>Payment Method</h4>
                <button onClick={addCard} className="btn-black">Add Card</button>
            </div>
            <div className="stripe-payment-methods__content">
                {
                    (paymentItems || []).map(item => (
                        <div className="stripe-payment-methods__item">
                            <div className="stripe-payment-methods__card">
                                <div />
                                <p style={{ paddingTop: '12px' }}>
                                    •••• •••• •••• {item.card.last4}<br />
                                    <b style={{ marginTop: '10px', display: 'block', fontSize: '12px' }}>{item.card.exp_month}/{item.card.exp_year}</b>
                                </p>
                                {item.id !== subscription.default_payment_method ? <a onClick={() => deleteCard(item.id)}>Delete Card</a> : <div />}
                            </div>
                            <div className="stripe-payment-methods__checkbox">
                                <CustomCheckbox
                                    onChange={() => item.id !== subscription.default_payment_method && setCardAsDefault(item.id)}
                                    checked={item.id === subscription.default_payment_method}
                                    label={'Default Payment Method'}
                                    className={item.id === subscription.default_payment_method ? 'stripe-payment-methods__checked' : ''}
                                    isStopPropagation={false}
                                />
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}