import pushNotifications from "../helpers/pushNotifications";
import {useContext, useEffect, useState} from "react";
import {MainContextProvider} from "../context/MainContext";
import {GoogleLogin, GoogleLogout} from 'react-google-login';
import { gapi } from 'gapi-script';
import axios from "axios";
import useDrivePicker from 'react-google-drive-picker'
import customAxios from "../service/api/interceptors";
import {NewEditor} from "../components/UI/NewEditor/NewEditor";



export const TestRoute = () => {
    const { setLoading } = useContext(MainContextProvider)
    const [openPicker, authResponse] = useDrivePicker();
    // const customViewsArray = [new google.picker.DocsView()]; // custom view
    const handleOpenPicker = () => {
        openPicker({
            clientId: "829041807368-6rr03l1p83br8f4a42vsk1q7m07f3t0k.apps.googleusercontent.com",
            developerKey: "AIzaSyBXB2-AU8nY6nLxC-LvEHyB8ogBbPe7fFo",
            viewId: "DOCS",
            // token: token, // pass oauth token in case you already have one
            showUploadView: true,
            showUploadFolders: true,
            supportDrives: true,
            multiselect: true,
            // customViews: customViewsArray, // custom view
            callbackFunction: (data) => {
                if (data.action === 'cancel') {
                    console.log('User clicked cancel/close button')
                }
                console.log(data)
            },
        })
    }

    const [text, setText] = useState("aaaaa")
    console.log(text)

    return (
        <div>
            <NewEditor value={text} onChange={e => console.log(e)} />
        </div>
    )
}