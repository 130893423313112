import React from "react";
import icon from "../../../../assets/images/partnerRequest/taskAccepted.svg";
import {dateFormatter} from "../../../../helpers/dateFormatter";

export const OfferClientAccepted: React.FC<{ date: string }> = ({ date }) => {
    return (
        <div className={'pr-client-tasks-accepted'}>
            <div className="pr-client-tasks-accepted__header">
                <img src={icon} alt=""/>
                <span>THANK YOU FOR ACCEPTING THE OFFER</span>
            </div>
            <div className="pr-client-tasks-accepted__body">
                <em>UPDATED ON: {dateFormatter(date)}</em>
                <p>
                    Your VLO will now make necessary preparations to start the legal works according to the accepted estimates. They will inform you about the next steps soon.
                </p>
                {/*<a href="#">Expand Tasks & Estimates</a>*/}
            </div>
        </div>
    )
}