import {useContext, useEffect, useState} from "react";
import './manager_profile.scss'
import {MainContextProvider} from "../../../context/MainContext";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";
import customAxios from "../../../service/api/interceptors";
import avatar from '../../../assets/images/profileIcons/avatar.svg'
import location from '../../../assets/images/profileIcons/location.svg'
import emailIcon from '../../../assets/images/profileIcons/emailIcon.svg'
import companyIcon from '../../../assets/images/profileIcons/companyIcon.svg'

type ManagerProfileType = {
    avatar_path: string
    email: string
    fullname: string
    id: string
    location: {
        id: number
        location: string
        user_id: number
    }[]
    title: string
    type: string
    company: {
        entry_data: string
        id: number
        manager_id: number
        name: string
        type: string
    }[]
}

export const ClientProfile = () => {
    const { modalData, userData } = useContext(MainContextProvider)
    const [loading, setLoading] = useState(true)
    const [managerInfo, setManagerInfo] = useState<ManagerProfileType>({} as ManagerProfileType)

    const getManagerData = async () => {
      if(!modalData) {
          return
      }
      const { status, data } = await customAxios.get(userData?.status.type_code === 400 ? `/user/${modalData}/profile/skills` : `/user/client/skills?id=${modalData}`)
        if(status === 200) {
            setManagerInfo(data)
        }
        setLoading(false)
    }

    useEffect(() => {
        getManagerData().then()
    }, [modalData])

    if(loading) {
        return <div style={{ position: 'relative' }}>
            <ModalLoader/>
        </div>
    }

    return (
        <div className={'manager_profile'} style={{ position: 'relative' }}>
            <div className="manager_profile__heading">
                <img style={{ height: '96px' }} src={managerInfo.avatar_path || avatar} alt=""/>
                <div className={'manager_profile__main'}>
                    <div className={'manager_profile__title'}>
                        <div>
                            <div className={'manager_profile__title-name'}>{managerInfo.fullname}</div>
                        </div>
                        <div className={'manager_profile__sum'} />
                    </div>
                    <div className="manager_profile__position">
                        {managerInfo?.title}
                    </div>
                    {
                        managerInfo?.location && managerInfo?.location[0] && managerInfo.location.map((item) => (
                            <div className="manager_profile__location">
                                <img src={location} alt=""/>
                                <span>{item.location}</span>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="manager_profile__body client-profile__body">
                <div className={'client-profile__row'}>
                    <div className={'client-profile__col'}>
                        <div className="client-profile__label">
                            <img src={emailIcon} alt=""/>
                            EMAIL
                        </div>
                        <div className="client-profile__email">
                            {managerInfo.email}
                        </div>
                    </div>
                    {userData?.status.type_code === 30000 && <div className={'client-profile__col'}>
                        <div className="client-profile__label">
                            <img src={companyIcon} alt=""/>
                            COMPANY
                        </div>
                        <div className="client-profile__company">
                            {
                                managerInfo?.company.map((item, index) => (
                                    <div className="client-profile__company-item">
                                        {managerInfo.company.length > 1 ?
                                            <div className="client-profile__company-item-name">
                                                {index + 1}. {item.name}
                                            </div> : <div className="client-profile__company-item-name">
                                                {item.name}
                                            </div>}
                                        {/*<div className="client-profile__company-item-link">
                                            www.stripe.com
                                        </div>*/}
                                    </div>
                                ))
                            }
                        </div>
                    </div>}
                </div>
            </div>
            {/*<div className="manager_profile__body">
                <div className="manager_profile__info">
                    <div>
                        <img src={cup} alt=""/>
                        <b>Years of experience</b>
                    </div>
                    <p>{managerInfo.experience}</p>
                </div>
                <div className="manager_profile__info">
                    <div>
                        <img src={service} alt=""/>
                        <b>SERVICE</b>
                    </div>
                    <p>{managerInfo.service}</p>
                </div>
                <div className="manager_profile__info">
                    <div>
                        <img src={education} alt=""/>
                        <b>EDUCATION</b>
                    </div>
                    {
                        Object.keys(managerInfo.education).map(key => (
                            <p>
                                {managerInfo.education[key]}
                                <em>{key}</em>
                            </p>
                        ))
                    }
                </div>
                <div className="manager_profile__info">
                    <div>
                        <img src={bio} alt=""/>
                        <b>BIO</b>
                    </div>
                    <p>{managerInfo.bio}</p>
                </div>
            </div>*/}
        </div>
    )
}