import React from 'react'
import createIcon from '../../../../../../assets/images/createIcon.svg'
import { ButtonType } from './button.type'
import './button.scss'

const Button:React.FC<ButtonType> = ({ label, onChange }) => (
  <button onClick={onChange} className={'task-view-button'}>
    <div className={'task-view-button-add'}>
      <img src={createIcon} alt={''} />
    </div>
    <span className={'task-view-button-text'}>{label}</span>
  </button>
)

export default Button
