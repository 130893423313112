import React from 'react'
import { ErrorBoundaryComponent } from './ErrorBoundaryComponent'
import axios from 'axios'

const HEADERS = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin':  'http://localhost:10002/',
    'Access-Control-Allow-Methods': 'POST',
    'Access-Control-Allow-Headers': 'X-Requested-With,content-type'
};

class ErrorBoundary extends React.Component{
    state = {
        hasError: false
    };

    componentDidCatch(error: any, errorInfo: any) {
        this.setState({ hasError: true });
        if(error) {
            this.setState({ hasError: true })
        } else {
            this.setState({ hasError: false })
        }
    }

    saveError(){
        // axios.post(`https://legalnodes-errors-default-rtdb.europe-west1.firebasedatabase.app/errors`, {
        //     error: 'error',
        //     errorInfo: 'errorInfo',
        //     created_date: new Date()
        // }, { headers: HEADERS })
    }

    render() {
        if(this.state.hasError) {
            return (
                <div className="page errorComp">
                    <button style={{ display: 'none' }} onClick={this.saveError}>{'test'}</button>
                    <ErrorBoundaryComponent type={'error'} />
                </div>
            )
        } else return this.props.children;
    }
}

export default ErrorBoundary
