import { openPopupWidget } from "react-calendly";
import React, {useContext, useEffect, useState} from "react";
import {MainContextProvider} from "../../../../context/MainContext";
import './Onboarding.scss'
import {
    acceptOfferService,
    getHelloSignService,
    getStripeLinkService,
    onBoardingFlowService
} from "../../../../service/onboarding";
import waitIcon from '../../../../assets/images/waitIcon.svg'
import clickIcon from '../../../../assets/images/clickIcon.svg'
import checkIcon from '../../../../assets/images/onBoardingIconsOk.svg'
import toBottomIcon from '../../../../assets/images/onboarding-tobottom.svg'
import avatar from '../../../../assets/images/client.png'
import { CSSTransition } from 'react-transition-group'
import {useHistory} from "react-router-dom";
import customAxios from "../../../../service/api/interceptors";
import {toastifySuccess} from "../../../../service/toastify/toastify";
import {InvoiceType} from "../../../Settings/Routes/Checkout/Checkout";


const Icons: React.FC<{ type: string }> = ({ type }) => {
    switch (type) {
        case '1': return (
            <div className="onBoarding__colIcon onBoarding__colIcon-wait">
                <img src={waitIcon} alt=""/>
            </div>
        )
        case '2': return (
            <div className="onBoarding__colIcon onBoarding__colIcon-click">
                <img src={clickIcon} alt=""/>
            </div>
        )
        case '3': return (
            <div className="onBoarding__colIcon onBoarding__colIcon-ok">
                <img src={checkIcon} alt=""/>
            </div>
        )
        case '4': return (
            <div className="onBoarding__colIcon">
                {/*<img src={checkIcon} alt=""/>*/}
            </div>
        )
        default: return <div />
    }
}

let onBoardingTimeout: NodeJS.Timeout | null;
export const OnBoarding = (props: any) => {
    const [apiData, setApiData] = useState<any[]>([])
    const [viewOffers, setViewOffers] = useState(false)
    const [stripeLink, setStripeLink] = useState('')
    const [helloSignLink, setHelloSignLink] = useState('')
    const { setLoading, setModalType, setUserOnBoarding, userData, userOnBoarding, setModalData } = useContext(MainContextProvider)
    const [calendlyUrl, setCalendlyUrl] = useState('')
    const [unpaidInvoice, setUnpaidInvoice] = useState<InvoiceType | null>(null)
    const history = useHistory()


    const getStripeLink = async () => {
        console.log('stripeLink', stripeLink)
        if(stripeLink) {
            return
        }
        const { data } = await getStripeLinkService()
        setStripeLink(data)
    }

    const getHelloSign = async () => {
        if(helloSignLink) {
            return;
        }
        const { data } = await getHelloSignService()
        setHelloSignLink(data)
    }

    const getUrl = async () => {
        const { data } = await customAxios.get('/onboarding/calendly-request-widget')
        setCalendlyUrl(data)
    }

    const getOnBoardingList = async () => {
        if(props.history.location.pathname !== '/onboarding') {
            return false;
        }
        const { status, data } = await onBoardingFlowService()
        setApiData(data)
        if(+data[0].stage_result === 0 && !localStorage.getItem('showOnBoardingWelcomeMessage')) {
            setModalType('welcome_onBoarding')
            localStorage.setItem('showOnBoardingWelcomeMessage', 'true')
        }

        await getHelloSign()
        await getStripeLink()
        await getUrl()

        if(data[data.length - 1].stage_result === 1000 || userData?.flows.onboarding === 1) {
            setUserOnBoarding(true)
            history.push('/dashboard')
            //toastifySuccess('123')
        }
/*

        const fourStepData = data.find((el: any) => +el.id === 4)
        if(typeof fourStepData.offers === 'object' && fourStepData.offers.find((el:any) => el.offer.accepted === 1) && !helloSignLink) {
            await getHelloSign()
        }
        if(+data.find((el: any) => el.id === 5).stage_result === 1000 && !stripeLink) {
            await getStripeLink()
        }
        if(data.find((el: any) => el.id === 6).stage_result === 1000) {
            setUserOnBoarding(true)
            history.push('/dashboard')
        }
*/

        onBoardingTimeout = setTimeout(getOnBoardingList, 3000)
        setLoading(false)
    }

    const acceptOffer = async (id: number) => {
        setLoading(true)
        const { status, data } = await acceptOfferService(id)
        if(status === 200) {
            await getOnBoardingList()
        }
        setLoading(false)
    }

    const [allNeeds, setAllNeeds] = useState('')
    const getClientNeeds = async () => {
        /*const { data } = await customAxios.get(`/user/profile/get-registration-needs-list`)
        if(data && typeof data === 'object') {
            setAllNeeds((data || []).map((item: any) => (item.need)).join(','))
        }*/
    }

    const getKycLink = async () => {
        try {
            const { data } = await customAxios.get(`onboarding/kycaid-form-url`)
            /*const link = document.createElement('a');
            link.href = data.form_url;
            link.setAttribute('download', 'report.pdf');
            document.body.appendChild(link);
            link.click();*/
            setModalType('kyc')
            setModalData(data)
        } catch (e) {}
    }

    const getUnPainInvoices = async () => {
        try {
            const { status, data } = await customAxios.get(`/company/invoices`)
            if (status === 200) {
                const invoice = (data || []).find((el: InvoiceType) => el.status === "UNPAID")
                if (invoice) {
                    setUnpaidInvoice(invoice)
                }
            }
        } catch (e) {}
    }

    useEffect(() => {
        getOnBoardingList().then()
        getClientNeeds().then()
        getUnPainInvoices().then()

        document.title = "Onboarding"
        return () => {
            onBoardingTimeout && clearTimeout(onBoardingTimeout)
            onBoardingTimeout = null
        }
    }, [])


    const firstStep = apiData.find(el => +el.id === 1)
    const firstStepData = firstStep && firstStep.data && typeof firstStep.data.data === 'string' && JSON.parse(firstStep.data.data)
    const secondStep = apiData.find(el => +el.id === 2)
    const thirdStep = apiData.find(el => +el.id === 3)
    const fourthStep = apiData.find(el => +el.id === 4)
    const fiveStep = apiData.find(el => +el.id === 5)
    const fourStep = apiData.find(el => +el.id === 6)
    const sevenStep = apiData.find(el => +el.id === 7)

    const onBoardingItems = (item: any, index: number) => {
        switch (index) {
            case 1: return (
                <div className={'onBoarding__tbody-item'}>
                    <div className="onBoarding__iconCol">
                        {/*<Icons type={+item.stage_result === 1000 ? '3' : '1'} />*/}
                        {
                            fourStep?.stage_result === 0 ? <Icons type={'4'} /> : <Icons type={item?.stage_result === 1000 ? '3' : '1'} />
                        }
                    </div>
                    <div className="onBoarding__col">
                        <div className="onBoarding__col-id">#3</div>
                        <div className="onBoarding__col-title">
                            {/*<b>Title</b>*/}
                            {item.title}
                        </div>
                        <div className="onBoarding__col-info">
                            {firstStepData?.time}
                        </div>
                        <div className="onBoarding__col-action">
                            <button
                                disabled={fourStep?.stage_result === 0 || item.stage_result === 1000}
                                onClick={() => openPopupWidget(
                                    { url: `${calendlyUrl}${calendlyUrl.includes('?') ? '&' : '?'}email=${userData?.email}&name=${userData?.name}&a3=${allNeeds}` })}
                                className={'onBoarding__button'}>
                                {item.button}
                            </button>
                            {/*<img className={'onBoarding__open-item'} src={toBottomIcon} alt=""/>*/}
                        </div>
                    </div>
                </div>
            );
            case 2: return (
                <div>
                    {!apiData[2].offers && <div className={'onBoarding__tbody-item'}>
                        <div className="onBoarding__iconCol">
                            {
                                firstStep?.stage_result === 0 ? <Icons type={'4'} /> : <Icons type={item?.stage_result === 1000 ? '3' : '2'} />
                            }
                        </div>
                        <div className="onBoarding__col">
                            <div className="onBoarding__col-id">#4</div>
                            <div className="onBoarding__col-title">
                                {/*<b>Title</b>*/}
                                {item.title}
                            </div>
                            <div className="onBoarding__col-info">
                                {item.info}
                            </div>
                            <div className="onBoarding__col-action">
                                <a target={'_blank'} href={firstStepData?.link}>
                                    <button
                                        disabled={firstStep?.stage_result === 0 || item.stage_result === 1000 }
                                        className={'onBoarding__button'}>
                                        {item.button || 'View'}
                                    </button>
                                </a>
                                {/*<img className={'onBoarding__open-item'} src={toBottomIcon} alt=""/>*/}
                            </div>
                        </div>
                    </div>}
                </div>
            )
            case 3: return (
                <div style={{ display: +fourStep?.stage_result === 1000 ? 'none' : '' }} className={'onBoarding__tbody-item'}>
                    <div className="onBoarding__iconCol">
                        <Icons type={+item.status === 1000 ? '3' : '1'} />
                    </div>
                    <div className={'onBoarding__tbody-text'}>
                        {/*<b>Title</b>*/}
                        {item.title}
                    </div>
                </div>
            )
            case 7: return (
                <div style={{ display: +fourStep?.stage_result === 1000 ? 'none' : '' }} className={'onBoarding__tbody-item'}>
                    <div className="onBoarding__iconCol">
                        <Icons type={+item.status === 1000 ? '3' : '1'} />
                    </div>
                    <div className={'onBoarding__tbody-text'}>
                        {item.title}
                    </div>
                </div>
            )
            case 5: return (
                <div className={'onBoarding__tbody-item'}>
                    <div className="onBoarding__iconCol">
                        <Icons type={+item.stage_result === 1000 ? '3' : '1'} />
                    </div>
                    <div className="onBoarding__col">
                        <div className="onBoarding__col-id">#1</div>
                        <div className="onBoarding__col-title">
                            {/*<b>Title</b>*/}
                            {item.title}
                        </div>
                        <div className="onBoarding__col-info">
                            {/*11-12-22  16:30 Monday*/}
                        </div>
                        <div className="onBoarding__col-action">
                            {/*<a href={helloSignLink} target={'_blank'}>*/}
                            <a onClick={getKycLink}>
                                <button
                                    disabled={item.stage_result === 1000}
                                    className={'onBoarding__button'}>
                                    {item.button || 'View & Sign'}
                                </button>
                            </a>
                            {/*<img className={'onBoarding__open-item'} src={toBottomIcon} alt=""/>*/}
                        </div>
                    </div>
                </div>
            )
            case 6: return (
                <div className={'onBoarding__tbody-item'}>
                    <div className="onBoarding__iconCol">
                        {
                            +fiveStep.stage_result === 0 || sevenStep?.stage_result === 0 ?
                                <Icons type={'4'} /> :
                                <Icons type={+item.stage_result === 1000 ? '3' : '1'} />
                        }
                    </div>
                    <div className="onBoarding__col">
                        <div className="onBoarding__col-id">#2</div>
                        <div className="onBoarding__col-title">
                            {/*<b>Title</b>*/}
                            {item.title}
                        </div>
                        <div className="onBoarding__col-info">
                            {/*11-12-22  16:30 Monday*/}
                        </div>
                        <div className="onBoarding__col-action" style={{ display: 'flex' }}>
                            <a target={'_blank'}>
                                <button
                                    //onClick={() => history.push(`/checkout/${item?.invoice?.id}`)}
                                    onClick={() => setModalType('choose_payment_plan')}
                                    disabled={+fiveStep.stage_result === 0 || +item.stage_result === 1000 || sevenStep}
                                    className={'onBoarding__button'}
                                >
                                    {item.button || 'Pay'}
                                </button>
                            </a>
                            {/*<img className={'onBoarding__open-item'} src={toBottomIcon} alt=""/>*/}
                        </div>
                    </div>
                </div>
            )
            default: return <div>Not defined</div>
        }
    }

    const checkOnBoardingStep = (index: number, type: string) => {
        const item = apiData[index]
        const prev_item = apiData[index - 1] || { stage_result: 1000 }

        if (item.action === 'Calendly-book') {
            return (
                <div className={'onBoarding__tbody-item'}>
                    <div className="onBoarding__iconCol">
                        {
                            (prev_item?.stage_result === 0) ? <Icons type={'4'} /> : <Icons type={item?.stage_result === 1000 ? '3' : '1'} />
                        }
                    </div>
                    <div className="onBoarding__col">
                        <div className="onBoarding__col-id">#{index + 1}</div>
                        <div className="onBoarding__col-title">
                            {item.title}
                        </div>
                        <div className="onBoarding__col-info">
                            {item?.time} {item?.time && '(GMT+0)'}
                        </div>
                        <div className="onBoarding__col-action">
                            <button
                                disabled={prev_item?.stage_result === 0 || item.stage_result === 1000}
                                onClick={() => openPopupWidget(
                                    { url: `${calendlyUrl}${calendlyUrl.includes('?') ? '&' : '?'}email=${userData?.email}&name=${userData?.name}&a3=${allNeeds}` })}
                                className={'onBoarding__button'}>
                                {item.button}
                            </button>
                        </div>
                    </div>
                </div>
            )
        } else if(item.action === 'Calendly-join') {
            return (
                <div className={'onBoarding__tbody-item'}>
                    <div className="onBoarding__iconCol">
                        {
                            prev_item?.stage_result === 0 ? <Icons type={'4'} /> : <Icons type={item?.stage_result === 1000 ? '3' : '2'} />
                        }
                    </div>
                    <div className="onBoarding__col">
                        <div className="onBoarding__col-id">#{index + 1}</div>
                        <div className="onBoarding__col-title">
                            {item.title}
                        </div>
                        <div className="onBoarding__col-info">
                            {item.info}
                        </div>
                        <div className="onBoarding__col-action">
                            <a target={'_blank'} href={firstStepData?.link}>
                                <button
                                    disabled={prev_item?.stage_result === 0 || item.stage_result === 1000 }
                                    className={'onBoarding__button'}>
                                    {item.button || 'View'}
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            )
        } else if (item.action === 'Stripe') {
            return (
                <div>
                    {<div className={'onBoarding__tbody-item'}>
                        <div className="onBoarding__iconCol">
                            {
                                +prev_item?.stage_result === 0 ?
                                    <Icons type={'4'} /> :
                                    <Icons type={+item?.stage_result === 1000 ? '3' : '1'} />
                            }
                        </div>
                        <div className="onBoarding__col">
                            <div className="onBoarding__col-id">#{index + 1}</div>
                            <div className="onBoarding__col-title">
                                {/*<b>Title</b>*/}
                                {item.title}
                            </div>
                            <div className="onBoarding__col-info" />
                            <div className="onBoarding__col-action" style={{ display: 'flex' }}>
                                {
                                    unpaidInvoice ? <button className={'onBoarding__button'} disabled={+prev_item.stage_result === 0 || +item.stage_result === 1000 || sevenStep} onClick={() => history.push(`/settings/history`)}>
                                        {'Pay'}
                                    </button> : <button
                                        onClick={() => setModalType('choose_payment_plan')}
                                        disabled={+prev_item.stage_result === 0 || +item.stage_result === 1000 || sevenStep}
                                        className={'onBoarding__button'}
                                    >
                                        {item.button || 'Pay'}
                                    </button>
                                }
                            </div>
                        </div>
                    </div>}
                </div>
            )
        } else if (item.action === "KYCAID") {
            return (
                <div className={'onBoarding__tbody-item'}>
                    <div className="onBoarding__iconCol">
                        <Icons type={+item.stage_result === 1000 ? '3' : '1'} />
                    </div>
                    <div className="onBoarding__col">
                        <div className="onBoarding__col-id">#{index + 1}</div>
                        <div className="onBoarding__col-title">
                            {/*<b>Title</b>*/}
                            {item.title}
                        </div>
                        <div className="onBoarding__col-info">
                            {/*11-12-22  16:30 Monday*/}
                        </div>
                        <div className="onBoarding__col-action">
                            {/*<a href={helloSignLink} target={'_blank'}>*/}
                            <a onClick={getKycLink}>
                                <button
                                    disabled={item.stage_result === 1000}
                                    className={'onBoarding__button'}>
                                    {item.button || 'View & Sign'}
                                </button>
                            </a>
                            {/*<img className={'onBoarding__open-item'} src={toBottomIcon} alt=""/>*/}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={'onBoarding__tbody-item'}>
                    <div className="onBoarding__iconCol">
                        <Icons type={+item.status === 1000 ? '3' : '1'} />
                    </div>
                    <div className={'onBoarding__tbody-text'}>
                        {item.title}
                    </div>
                </div>
            )
        }
    }

    return (
        <div className={'onBoarding'}>
            {/*<button onClick={() => setModalType('choose_payment_plan')}>aaaaaaaa</button>*/}
            <div className="onBoarding__header">
                <div className={'onBoarding__header-left'}>
                    <h1>Onboarding</h1>
                    <span>Follow these steps to complete your onboarding</span>
                </div>
                <div className={'onBoarding__statuses'}>
                    <div className="onBoarding__status">
                        <div className="onBoarding__status-circle" />
                        <span>Done</span>
                    </div>
                    <div className="onBoarding__status">
                        <div className="onBoarding__status-circle onBoarding__status-circle-progress" />
                        <span>In Progress</span>
                    </div>
                </div>
            </div>
            {/*<h1>OnBoarding</h1>
            <button onClick={() => openPopupWidget({ url: 'https://calendly.com/todoca8602/30min' })}>Custom Button</button>*/}
            <div className="onBoarding__table">
                <div className="onBoarding__thead">
                    <div className="onBoarding__thead-th onBoarding__thead-th-id">#</div>
                    <div className="onBoarding__thead-th onBoarding__thead-th-title">Title</div>
                    <div className="onBoarding__thead-th onBoarding__thead-th-info">Info</div>
                    <div className="onBoarding__thead-th onBoarding__thead-th-id-action">Action</div>
                </div>
                <div className="onBoarding__tbody">
                    {
                        apiData.map((item, index) => checkOnBoardingStep(index, 'sadds'))
                    }
                </div>
            </div>
        </div>
    )
}
