import axios, { AxiosResponse } from 'axios'
import { methodPost } from '../api/api'
import { apiPath } from '../api/constants'
import {
  setTokenChunk,
  getRefreshToken,
  getAccessToken,
  setAccessToken,
  setExpiresInToken,
  setRefreshToken,
} from '../token/token'
import {
  LoginResponse, LoginType, LoginResponseRequest, RefreshTokenResponseRequest,
} from './auth.types'
import customAxios from "../api/interceptors";

const baseUrl = process.env.REACT_APP_BASE_API

const calcExpiresIn = (expiresIn: number): string => {
  const milliseconds = expiresIn * 1000
  return String(Date.now() + milliseconds)
}

const authLogin = async ({ email, password } :LoginType): Promise<LoginResponse> => {
  const body = {
    grant_type: process.env.REACT_APP_LOGIN_GRAND_TYPE as string,
    client_id: process.env.REACT_APP_CLIENT_ID as string,
    client_secret: process.env.REACT_APP_CLIENT_SECRET as string,
    username: email,
    password,
  }

  try {
    const { data: { access_token, expires_in, refresh_token } }: AxiosResponse<LoginResponseRequest> = await methodPost({
      path: apiPath.login,
      body,
    })

    setTokenChunk({
      accessToken: access_token,
      refreshToken: refresh_token,
      expiresIn: calcExpiresIn(expires_in).toString(),
    })

    return {
      status: true,
    }
  } catch (err) {
    return {
      status: false,
      // @ts-ignore  FIX_ME
      message: err?.response?.data?.message,
    }
  }
}

const refreshToken = async (): Promise<void> => {
  const body = {
    grant_type: process.env.REACT_APP_REFRESH_TOKEN_GRAND_TYPE as string,
    client_id: process.env.REACT_APP_CLIENT_ID as string,
    client_secret: process.env.REACT_APP_CLIENT_SECRET as string,
    refresh_token: getRefreshToken() as string,
  }

  const accessToken = getAccessToken()
  // we shouldn't use an instance axios
  const { data: { access_token, expires_in, refresh_token } }: AxiosResponse<RefreshTokenResponseRequest> = await axios.post(
    `${baseUrl}/${apiPath.refreshToken}`,
    { ...body },
    { headers: { Authorization: `Bearer ${accessToken}` } },
  )

  setAccessToken(access_token)
  setExpiresInToken(calcExpiresIn(expires_in).toString())

  if (refresh_token) {
    setRefreshToken(refresh_token)
  }
}

type ResetPassword = {
  token: string
  password1: string
  password2: string
}

export const forgotPassword = (email: string) => {
  let bodyFormData = new FormData();
  bodyFormData.set('email', email);
  return customAxios.post(`/user/reset-password/send-token`, bodyFormData, {
    headers:{ 'Content-Type': 'multipart/form-data' }
  })
}
export const checkResetPasswordToken = (token: string) => customAxios.get(`/user/reset-password/check-token/?token=${token}`)
export const resetPassword = (data: ResetPassword) => {
  let bodyFormData = new FormData();
  bodyFormData.set('reset_token', data.token);
  bodyFormData.set('password1', data.password1);
  bodyFormData.set('password2', data.password2);
  return customAxios.post(`/user/reset-password/reset`, bodyFormData, {
    headers:{ 'Content-Type': 'multipart/form-data' }
  })
}

export {
  authLogin,
  refreshToken,
}
