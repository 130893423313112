import { KeyValueType } from '../../types/custom/keyValue.type'

export const apiPath: KeyValueType = {
  login: 'oauth2/token',
  refreshToken: 'oauth2/token',
  userProfile: 'user/profile/simple',
  kanbanTasks: 'company/tasks/kanbam',
  projects: 'company/projects',
  dashboardTasks: 'company/tasks/dashboard',
  tasks: 'company/tasks',
  filter: 'company/projects/subscription-filter',
  legalTicket: 'forms/legal-tickets',
}
