import React, {useContext, useEffect, useState} from 'react'
import './client_kanban_request.scss'
import createRequestIcon from '../../../assets/images/createRequest.svg'
import callWithManagerIcon from '../../../assets/images/callWithManager.svg'
import {MainContextProvider} from "../../../context/MainContext";
import { openPopupWidget } from "react-calendly";
import customAxios from "../../../service/api/interceptors";


export const ClientKanbanRequest: React.FC = () => {
    const { setModalType, userData, setModalData } = useContext(MainContextProvider)
    const [calendlyUrl, setCalendlyUrl] = useState('')

    const getUrl = async () => {
        const { data } = await customAxios.get('/onboarding/get-kanban-calendly-widget')
        setCalendlyUrl(data)
    }

    useEffect(() => {
        getUrl().then()
    }, [])

    return (
        <div>
            <h4>
                Pick the options that apply to you. You don’t need to choose one from each category, and you can change your mind later about which services you wish to use.
            </h4>
            <div className={'client_kanban_request__body'}>
                <div className="client_kanban_request__col">
                    <div className="client_kanban_request__image">
                        <img src={createRequestIcon} alt=""/>
                    </div>
                    <div className="client_kanban_request__title">
                        <img style={{ display: 'none' }} src={createRequestIcon} alt=""/>
                        CREATE A NEW REQUEST
                    </div>
                    <div className="client_kanban_request__description">
                        Pick the options that apply to you. You don’t need to choose
                        one from each category, and you can change your mind later
                        about which services you wish to use.
                    </div>
                    <button
                        onClick={() => {
                            setModalType('create_legal_request')
                        }}
                        className="client_kanban_request__button btn-black">
                        CREATE A NEW REQUEST
                    </button>
                </div>
                <div className="client_kanban_request__col">
                    <div className="client_kanban_request__image">
                        <img src={callWithManagerIcon} alt=""/>
                    </div>
                    <div className="client_kanban_request__title" style={{ textTransform: 'uppercase' }}>
                        <img style={{ display: 'none' }} src={callWithManagerIcon} alt=""/>
                        Book a call with your VLO
                    </div>
                    <div className="client_kanban_request__description">
                        Book a call with our VLO and explain your
                        business need to them with your own words.
                    </div>
                    <button
                        onClick={() => {
                            openPopupWidget({ url: `${calendlyUrl}${calendlyUrl.includes('?') ? '&' : '?'}email=${userData?.email}&name=${userData?.name}`})
                            setModalType('')
                        }}
                        className="client_kanban_request__button btn-black" style={{ textTransform: 'uppercase' }}>
                        Book a call with your VLO
                    </button>
                </div>
            </div>
        </div>
    )
}