import React, { useContext, useEffect, useState } from 'react'
import { Input, ReactSelect } from '../../../components/UI'
import './styles.scss'
import { getClients } from '../../../service/client'
import { createProject } from '../../../service/project'
import { ProjectPostType } from '../../../types/project.type'
import { MainContextProvider } from '../../../context/MainContext'
import customAxios from '../../../service/api/interceptors'
import { toastifyError } from '../../../service/toastify/toastify'
import { useTranslation } from 'react-i18next'
import { SubscriptionsType } from '../../../service/settings'
import { CompanyType } from '../../../types/company.type'
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";

type El = { value: string; label: string; }

const ProjectTypes = [
  { value: '1', label: 'Raise' },
  { value: '2', label: 'Funds' },
  { value: '3', label: 'IP' },
]

interface Props {
  history?: {
    push(url: string): void;
  };
}

const CreateProject:React.FC<Props> = ({ history }) => {
  const { setModalType, setModalData } = useContext(MainContextProvider)
  const [loader, setLoader] = useState(true)
  const [companies, setCompanies] = useState<El[]>([])
  const [company, setCompany] = useState('')
  const [projectType, setProjectType] = useState('')
  const [title, setTitle] = useState('')
  const [titleValid, setTitleValid] = useState(true)
  const [subscriptions, setSubscriptions] = useState<{ value: string, label: string }[]>([])
  const [subscription, setSubscription] = useState('')
  const { t } = useTranslation()

  const getCompanies = async () => {
    const res = await getClients()
    setCompanies(res.data.map((el: CompanyType) => ({ value: el.id, label: el.name })))
    if (res.data[0]) setCompany(res.data[0].id)
    setProjectType(ProjectTypes[0].value)
  }

  const getSubscription = async () => {
    if (company) {
      customAxios.get(`company/companies/${company}/subscriptions`).then((res) => {
        if (res.data && res.data[0]) {
          setSubscriptions(res.data.map((el: SubscriptionsType) => ({ value: el.id, label: el.title })))
          setSubscription(res.data[0].id)
        }
      })
    }
    setLoader(false)
  }

  useEffect(() => {
    (async () => {
      await getSubscription()
    })()
  }, [company])

  const createNewProject = async () => {
    if (!title) {
      setTitleValid(false)
      toastifyError(t('requiredFields'))
      return
    }
    const data:ProjectPostType = {
      name: title,
      project_type: +projectType,
      adm_unit_id: 1, // FIX_ME
      company_id: +company,
      status: 1, // FIX_ME
      subscription_id: subscription,
    }

    setLoader(true)
    const res = await createProject(data)
    if (res.status === 200 || res.status === 201) {
      history?.push(`/projects/${res.data.id}`)
      setModalType('')
      setModalData(null)
    }
    setLoader(false)
  }

  useEffect(() => {
    (async () => {
      await getCompanies()
    })()
  }, [])

  return (
    <div style={{ position: 'relative' }}>
      {loader && <ModalLoader />}
      <h3 className={'create_project__second-heading'}>
        {t('projectModal.heading')}
      </h3>
      <ReactSelect
        label={t('projectModal.company')}
        value={company}
        options={companies}
        onChange={(e) => setCompany(e.value)}
        valid
      />
      {/* <ReactSelect
                label="Type of project"
                value={projectType}
                options={ProjectTypes}
                onChange={e => setProjectType(e.value)}
                valid={true}
            /> */}
      <Input
        label={t('projectModal.title')}
        otherProps={{
          placeholder: t('projectModal.title'),
          value: title,
          onChange: (event) => setTitle(event.target.value),
          onKeyDown: event => (event.keyCode === 13) && createNewProject()
        }}
        valid={titleValid}
      />
      {/* <Input
                label="Executor"
                otherProps={{
                    placeholder: "William Green"
                }}
                valid={false}
            /> */}
      {/* <Options
                options={[{value: "1", label: "William"},{value: "2", label: "Green"}]}
                chooseValue={test}
                className="create_project__options"
                onChange={(e) => setTest(e)}
            /> */}
      {/* <ReactSelect
        label={t('projectModal.subscriptions')}
        value={subscription}
        options={subscriptions}
        onChange={(e) => setSubscription(e.value)}
        valid
      /> */}
      <button style={{ marginTop: '15px' }} onClick={createNewProject} className={'create_project__button btn-black'}>{'NEXT'}</button>
    </div>
  )
}

export default CreateProject
