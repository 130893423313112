import React from "react";

function pushNotifications(title: string, message: string, link: string) {
    if (window.Notification && Notification.permission === "granted") {
        let n = new Notification(title, {tag: 'soManyNotification', body: message});
        n.onclick = function () {
            window.open(link);
        }
    } else if (window.Notification && Notification.permission !== "denied") {
        Notification.requestPermission(function (status) {
            if (status === "granted") {
                let n = new Notification(message, {tag: 'soManyNotification'});
                n.onclick = function () {
                    window.open(link);
                }
            } else {
                // alert("Hi!");
            }
        });
    } else {
        // alert("Hi!");
    }
}

export default pushNotifications