import React, {useContext, useEffect, useRef, useState} from "react";
import navDots from '../../../../assets/images/navDots.svg'
import waitingIcon from '../../../../assets/images/waitingIcon.svg'
import './OfferHeader.scss'
import {dateFormatter} from "../../../../helpers/dateFormatter";
import {DropDownNotifications} from "../DropDownNotifications/DropDownNotifications";
import {TASK_STATUSES} from "../../../../containers/Client/Task/TaskView/taskHelpers";
import {toastifyError} from "../../../../service/toastify/toastify";
import TaskStatus from "../../../../containers/Client/Task/TaskView/other/TaskStatus/TaskStatus";
import {MainContextProvider} from "../../../../context/MainContext";
import backIcon from '../../../../assets/images/backBlackIcon.svg'
import squareBlack from '../../../../assets/images/square-icon-black.svg'
import squareWhite from '../../../../assets/images/square-icon-white.svg'
import {useHistory} from "react-router-dom";
import {useOnClickOutside} from "../../../../hooks/useOnClickOutside";
import customAxios from "../../../../service/api/interceptors";

type OfferHeaderType = {
    statusText?: string
    statusBackground?: string
    isWaitIcon?: boolean
    updated_at?: number
    isCreateRequestFromOffer?: boolean
    createRequestFromOffer?: () => void
    isMoveToPayment?: boolean
    moveToPayment?: () => void
    isStartDelivery?: boolean
    startDelivery?: () => void
    isMoveToPublic?: boolean
    moveToPublic?: () => void
    isMoveToDone?: boolean
    moveToDone?: () => void
    notifications?: any[]
    accepted?: number
    is_done?: number
    changeStatus?: (status: string) => void
    moveToUnDone?: () => void
    isOffer?: boolean
    copyOfferInfoToBuffer?: () => void
    hideBackButton?: boolean,
    deleteItem?: () => void
    isAccessDelete?: boolean
    isEdit?: boolean
    statusData?: {
        text?: string
        color?: string
        background?: string
        icon?: any
    }
    heading?: string
    text?: string
    company_id?: number
    id?: number
    isUpdateLegalRequest?: boolean
    moveToArchive: (key: number) => void
    is_archived: 1 | 0
    createGeneralChat?: () => void
    chatCount?: number
}

export const OfferHeader: React.FC<OfferHeaderType> = ({
    chatCount = 0,
    statusBackground,
    statusText,
    isWaitIcon,updated_at,
    isCreateRequestFromOffer,
    createRequestFromOffer,
    isMoveToPayment,
    moveToPayment,
    isStartDelivery,
    startDelivery,
    isMoveToPublic,
    moveToPublic,
    isMoveToDone,
    moveToDone,
    notifications,
    accepted,
    is_done,
    changeStatus,
    copyOfferInfoToBuffer, moveToUnDone, isOffer, hideBackButton,
    deleteItem,isAccessDelete, statusData, isEdit, id, heading, text, company_id, isUpdateLegalRequest, moveToArchive, is_archived, createGeneralChat
}) => {
    const { userData, setModalData, setModalType } = useContext(MainContextProvider)
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })
    const [acceptedPrCount, setAcceptedPrCount] = useState(0)

    const switchStatus = () => {
        console.log(accepted, is_done)
        if (Number(accepted) === 5 && is_done !== 1) {
            return [
                {value: '6', label: 'MOVE TO DONE', color: '#000', textColor: '#000'},
                {value: '2', label: 'Return to Offer has to be accepted', color: '#000', textColor: '#000'},
            ]
        }
        switch (accepted) {
            case 0: return [
                {value: '6', label: 'MOVE TO DONE', color: '#000', textColor: '#000'},
            ]
            case 1: return [
                {value: '6', label: 'MOVE TO DONE', color: '#000', textColor: '#000'},
                {value: '3', label: 'MOVE TO PAYMENT', color: '#000', textColor: '#000'},
                {value: '5', label: 'JUMP TO "IN PROGRESS"', color: '#000', textColor: '#000'},
                {value: '2', label: 'Return to Offer has to be accepted', color: '#000', textColor: '#000'},
            ];
            case 2: return [
                {value: '6', label: 'MOVE TO DONE', color: '#000', textColor: '#000'},
                {value: '0', label: 'MOVE TO DRAFT', color: '#000', textColor: '#000'},
            ]
            case 3: return [
                {value: '6', label: 'MOVE TO DONE', color: '#000', textColor: '#000'},
                {value: '5', label: 'START DELIVERY', color: '#000', textColor: '#000'},
                {value: '2', label: 'Return to Offer has to be accepted', color: '#000', textColor: '#000'},
            ];
            default: return []
        }
    }

    const getAcceptedPr = async () => {
        const { status, data } = await customAxios.get(`/partner-request/count/offer/${id}?status=5`)
        if (status === 200) setAcceptedPrCount(data)
    }

    useEffect(() => {
        getAcceptedPr().then()
    }, [id])


    return (
        <div className={'offerHeader'} style={{ background: statusData?.background, margin: '-20px -30px 0 -30px', padding: '12px 20px', borderRadius: '10px 10px 0 0' }}>
            <div className={'offerHeader__left'}>
                <div className="offerHeader__status" style={{ color: statusData?.color, display: 'flex', alignItems: 'center' }}>
                    <img style={{ marginRight: '9px' }} src={statusData?.icon} alt=""/>
                    {statusData?.text}
                </div>
            </div>
            <div className="offerHeader__right">
                <div>
                    <DropDownNotifications
                        show={{
                            wait: isWaitIcon,
                            comments: 0,
                            notifications: (notifications || []).length
                        }}
                        isShowNotificationsDropDown
                        notificationsItems={notifications}
                    />
                </div>
                {userData?.status.type_code === 300 && accepted === 0 &&
                    <button onClick={() => changeStatus && changeStatus('2')} className="offerHeader__publish-button btn-black">Publish Offer</button>}
                {/*{isOffer && userData?.status.type_code === 300 && accepted !== 2 && accepted !== 0 && is_done !== 1 && <TaskStatus
                    arr={switchStatus()}
                    current={switchStatus()[0] && switchStatus()[0].value}
                    onChange={(e) => {
                        if(changeStatus && moveToDone) {
                            (e === '6') ? moveToDone(): changeStatus(e)
                        }
                    }}
                />}*/}

                {userData?.status.type_code === 300 && <div className="offerHeader__dropdown" ref={dropdownRef}>
                    <img style={{width: '30px', height: '30px'}} onClick={() => setIsOpen(!isOpen)}
                         className={'offerHeader__right-dots'}
                         src={statusData?.text === 'DRAFT' ? squareBlack : squareWhite} alt=""/>
                    {isOpen && <div className={'offerHeader__dropdown-container task-status__body'}>
                        {
                            switchStatus().map(item => (
                                <>
                                    {is_archived === 0 && <div
                                        onClick={() => {
                                            if (changeStatus && moveToDone) {
                                                (item.value === '6') ? moveToDone() : changeStatus(item.value)
                                            }
                                        }}
                                        className={'task-status__body-item'}>
                                        {item.label}
                                    </div>}
                                </>
                            ))
                        }
                        {isCreateRequestFromOffer &&
                            <div onClick={createRequestFromOffer} className="task-status__body-item">TURN INTO
                                OFFER</div>}
                        {userData?.status.type_code === 300 && is_done === 1 && accepted === 5 &&
                            <div onClick={moveToUnDone} className="task-status__body-item">Return to In Progress</div>}
                        {isEdit && is_archived === 0 && <div
                            className={'task-status__body-item'}
                            onClick={() => {
                                setModalData({
                                    id,
                                    company_id,
                                    step: 2,
                                    title: heading,
                                    description: text,
                                    configTitle: 'Update offer'
                                })
                                setModalType('create_manager_offer')
                            }}>Edit</div>}
                        {isUpdateLegalRequest && is_archived === 0 && <div
                            className={'task-status__body-item'}
                            onClick={() => {
                                setModalData({
                                    id,
                                    company_id,
                                    step: 2,
                                    title: heading,
                                    description: text
                                })
                                setModalType('update_legal_request')
                            }}>Edit</div>}
                        {is_archived === 0 && <div style={{color: 'red'}}
                                                   onClick={() => moveToArchive(1)}
                                                   className={'task-status__body-item'}>
                            Archive
                        </div>}
                        {is_archived === 1 && <div style={{color: 'green'}}
                                                   onClick={() => moveToArchive(0)}
                                                   className={'task-status__body-item'}>
                            Bring back from the archive
                        </div>}
                        {chatCount !== 2 && Number(accepted) >= 1 && acceptedPrCount > 0 && <div onClick={createGeneralChat} className="task-status__body-item">
                            Create General Chat
                        </div>}
                    </div>}
                </div>}
            </div>
        </div>
    )
}