import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import './react-editor.scss'
import {NewEditor} from "../NewEditor/NewEditor";
import 'react-quill/dist/quill.snow.css';

type Props = {
  value: string
  onChange: (key: string) => void
  onKeyDown?: (key: React.KeyboardEvent) => void
  valid?: boolean
  errorMessage?: string,
  height?: number,
  disabled?: boolean
  className?: string
  placeholder?: string
  onInit?: () => void
}

const ReactEditor:React.FC<Props> = ({
  value, onChange, onKeyDown, valid, errorMessage, height= 300, disabled, className, placeholder, onInit
}) => {
  const { t } = useTranslation()
    const [load, setLoad] = useState(false)

    useEffect(() => {
        setLoad(true)
    }, [])

    const changeText = (text: string) => {
      // let changedText = text.replace(/(style=".*?)font-family.*?;/g, '$1')
        let changedText = text
        changedText = changedText.replace(/<h[1-6].*?>(.*?)<\/h[1-6]>/g, '<p>$1</p>')
        changedText = changedText.replace(/class="[^"]*"/, '');
        const regex = /style="[^"]*(font-size|line-height|background-color|border-radius|color|margin|margin-top|margin-bottom|margin-right|margin-left|padding|padding-top|padding-bottom|padding-right|padding-left|opacity|position|display|width|height|text-align|list-style|list-style-type|flex-direction)[^"]*"/g;
        changedText = changedText.replace(regex, '')
        return changedText
    }

    return  (
    <div className={`${className} react-editor`}>
        <NewEditor
            value={value}
            onChange={e => {
                if (load) onChange(e)
            }}
        />
      {!valid && <span className={'react-editor__error-message'}>{errorMessage || t('requiredField')}</span>}
    </div>
  )
}

export default ReactEditor
