import React, {useContext, useEffect, useState} from "react";
import {Input, ReactEditor, ReactSelect, TextArea} from "../../../components/UI";
import {createOfferService, updateOfferService} from "../../../service/client/offer";
import {MainContextProvider} from "../../../context/MainContext";
import {useHistory} from "react-router-dom";
import controlErrorValid, {controlErrorFunc} from "../../../components/UI/ControlValid/controlErrorValid";
import {toastifyError} from "../../../service/toastify/toastify";
import {getClients} from "../../../service/client";
import {ClientType} from "../../../types/client.type";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";
import {LegalRequestItemType} from "./CreateOffer";

type Props = {
    needs: LegalRequestItemType[]
    choose: string
}

const validationRules = {
    client_id: { required: true }, title: { required: true }, description: { required: true, maxLength: 5001 }}

export const CreateOfferSecondStep: React.FC<Props> = ({ choose, needs }) => {
    const { setModalType, setModalData, setLoading, modalData, loading } = useContext(MainContextProvider)
    const history = useHistory()
    const [errors, setErrors] = useState<{[key: string]: string}>({})
    const [clients, setClients] = useState<ClientType[]>([])
    const [values, setValues] = useState({
        client_id: '',
        title: '',
        description: ''
    })

    const createOffer = async () => {
        const validReq = controlErrorFunc({ obj: values, config: validationRules })

        if(Object.keys(validReq).length >= 1) {
            setErrors(validReq)
            toastifyError('Please correctly fill all fields in the form')
            return false;
        }

        setLoading(true)
        try {
            const { status, data } = await createOfferService({
                title: values.title, description: values.description, company_id: +values.client_id, status: 0
            })
            if(status === 200 && data.id) {
                setModalType('')
                setModalData(null)
                history.push(`/offer/${data.id}`)
            }
        } catch (e) {}
        setLoading(false)
    }

    const updateOffer = async () => {
        const validReq = controlErrorFunc({ obj: values, config: validationRules })

        if(Object.keys(validReq).length >= 1) {
            setErrors(validReq)
            toastifyError('Please correctly fill all fields in the form')
            return false;
        }

        setLoading(true)
        try {
            const { status, data } = await updateOfferService({
                title: values.title, description: values.description, company_id: modalData.company_id
            }, modalData.id)

            if(status === 200 && data.id) {
                history.push(`/offer/${modalData.id}`)
                setModalType('')
                setModalData({ update: true })
            }

        } catch (e) { }

        setLoading(false)
    }

    const getClientsList = async () => {
        setLoading(true)
        try {
            const { status, data } = await getClients()
            if(status === 200 && data) {
                setClients(data)
            }
        } catch (e){}
        setLoading(false)
    }

    useEffect(() => {
        getClientsList().then()

        if(modalData && modalData.id) {
            setValues({
                client_id: modalData.company_id,
                title: modalData.title,
                description: modalData?.description
            })
            return;
        }

        if (!choose) return;

        const data: {id: number, title: string, description: string} | undefined = needs.find(el => String(el.id) === choose)
        if(data) {
            setValues({
                ...values,
                title: data.title,
                description: data.description
            })
        }
    }, [choose, needs, modalData])

    const clearFormat = (text: string) => {
        return text
    }

    const chaster_left = 5001 - +(values.description.replace(/&nbsp;/gi, '').trim().replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '').trim().length < 1 || (values.description.trim().replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '')).length || 0)

    return loading ? <ModalLoader /> : (
        <div>
            {!modalData?.id && <ReactSelect
                className={'create_manager_offer__react-select'}
                value={values.client_id}
                options={clients.map(item => ({ value: item.id, label: item.name }))}
                onChange={e => setValues({ ...values, client_id: e.value })}
                valid={!errors?.client_id}
                errorMessage={errors.client_id}
            />}
            <Input
                otherProps={{
                    value: values.title,
                    onChange: e => setValues({ ...values, title: e.target.value})
                }}
                className={'create_manager_offer__input'}
                valid={!errors?.title}
                errorMessage={errors.title}
            />
            <div style={{ marginTop: '10px' }}>
                <ReactEditor
                    value={values.description}
                    onChange={text => setValues({ ...values, description: clearFormat(text) })}
                    valid={!errors?.description}
                    errorMessage={errors.description}
                />
                <em style={{ fontSize: '12px', color: '#9b9b9b', paddingLeft: '5px' }}>
                    {chaster_left > 0 ? chaster_left : 0} characters left
                </em>
            </div>
            <button
                onClick={modalData?.id ? updateOffer : createOffer}
                // disabled={!values.title.trim() || !values.description.trim()}
                className="create_manager_offer__button-second-step btn-black">{modalData?.id ? 'Update offer' : 'Create offer'}</button>
        </div>
    )
}