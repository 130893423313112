import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { MainContext } from './context/MainContext'
import AppStack from './containers/Layout/AppStack'

const App:React.FC = () => (
  <>
    <MainContext>
      <Router>
        <AppStack />
      </Router>
    </MainContext>
  </>
)

export default App
