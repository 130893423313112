import React, { useContext, useEffect, useState } from 'react'
import {
  CheckboxItem, Input, ReactEditor, ReactSelect,
} from '../../../components/UI'
import './create-task.scss'
import { formatDate } from '../../../helpers/helperFunctions'
import { getProjects } from '../../../service/project'
import { createTask } from '../../../service/tasks'
import { MainContextProvider } from '../../../context/MainContext'
import { toastifyError, toastifySuccess } from '../../../service/toastify/toastify'
import { useTranslation } from 'react-i18next'
import { ProjectTasksType } from '../../../types/custom/projectTasks.type'
import {TaskType} from "../../../types/task.type";
import { controlErrorFunc } from "../../../components/UI/ControlValid/controlErrorValid";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";

type Props = {
  history?: any,
  modalData: {
      item: TaskType,
      updateTask: () => void;
      updateTaskFunc: (data: any, postType?: string) => void;
      initialData: { [key: string]: unknown }
  }
}

const validationConfig = {
    client_story: { required: true, minLength: 10, maxLength: 1000, editor: true },
    title: { required: true, minLength: 3, maxLength: 126 },
    project_id: { required: true },
    deadline: { required: true },
}

const dateForInput = (date: any = new Date()) => {
    const parsed = Date.parse(date)
    return parsed.toString().substring(0, parsed.toString().length - 3)
}

const CreateTask:React.FC<Props> = ({ history, modalData = {} }) => {
  const { item, updateTaskFunc } = modalData
  const [loading, setLoading] = useState(true)
  const { setModalType } = useContext(MainContextProvider)
  const { t } = useTranslation()
  const [isSubscriptions, setIsSubscriptions] = useState(false)
  const [errors, setErrors] = useState<{[key: string]: string}>({})
  const [values, setValues] = useState({
    client_story: '',
    project_id: '',
    title: '',
    price_estimate: '',
    time_estimate: '',
    deadline: formatDate(new Date()),
    isCovered: false
  });

  const [projects, setProjects] = useState<{ label: string, value: string, subscription: null | { id: number }[] }[]>([])

  const parseData = async () => {
      const { data } = await getProjects();
      const initialData = modalData?.initialData || {};

      setProjects((data || []).map(({ name, id, company, subscription }: ProjectTasksType) => ({
          label: `${name} - ${company?.name}`,
          value: id,
          subscription: subscription
      })))

      if (item) {
          const { project_id, title, time_estimate, deadline, client_story, price_estimate } = item;
          setValues(prevState => ({
              ...prevState,
              project_id,
              title,
              price_estimate,
              time_estimate,
              deadline: formatDate(deadline + '000'),
              client_story,
              type: 'DEFAULT'
          }))

          // @ts-ignore FIX_ME
          const project = project_id && data.find(({ id }) => +id === +project_id);
          if(project && project.subscription && project.subscription[0]) setIsSubscriptions(true)
      } else {
          if(data[0]){
              setValues(prevState => ({ ...prevState, project_id: data[0].id, client_story: '<p></p>', ...initialData }))
              // @ts-ignore FIX_ME
              const initialProjectIndex = initialData?.project_id ? data.findIndex(({ id }) => id == initialData.project_id) : 0
              if(data[initialProjectIndex].subscription[0]) setIsSubscriptions(true)
          } else {
              setValues(prevState => ({ ...prevState, client_story: '<p></p>', ...initialData }))
          }
      }
      setLoading(false)
  }

  useEffect(() => {
    (async () => {
        await parseData();
    })()
  }, [item])

  const { project_id, title, price_estimate, time_estimate, deadline, client_story, isCovered } = values

  const postData = async () => {
    let validReq = {};
    const validationConfigClone = JSON.parse(JSON.stringify(validationConfig))
    if(!isSubscriptions) validationConfigClone['price_estimate'] = { required: true, minNumber: 0 }

    validReq = controlErrorFunc({ obj: values, config: validationConfigClone })
    if(Object.keys(validReq).length >= 1) {
        setErrors(validReq)
        toastifyError('Please correctly fill all fields in the form')
        return false;
    }

    const data = {
      title: `${title}`,
      price_estimate: isCovered ? 0 : +price_estimate,
      deadline: dateForInput(new Date(deadline)),
      time_estimate: String(deadline).substring(0, String(time_estimate).length - 3),
      project_id: +project_id,
      client_story,
      status: `${!item ? '2' : item.status}`,
      type: 'DEFAULT'
    }

    setLoading(true)
    if (!item) {
      if(isSubscriptions) data.price_estimate = 0
      const res = await createTask(data)
      if (res.status === 201) {
        history.push(`/tasks/${res.data.id}`)
        toastifySuccess(t('createTaskModal.wasCreated'))
        setModalType('')
      }
    } else {
        if(updateTaskFunc) updateTaskFunc(data, 'singleItem')
    }
    setLoading(false)
  }

    return (
    <div className={'create_task__body'} style={{ position: "relative" }}>
        {loading && <ModalLoader />}
        <ReactSelect
            label={t('createTaskModal.project')}
            value={project_id}
            options={projects}
            className={'create_task__select create_task__mt'}
            onChange={(e) => {
                setValues(prevState => ({...prevState, project_id: e.value}))
                setIsSubscriptions(!!e.subscription[0])
            }}
            valid={!errors.project_id} errorMessage={errors.project_id}
            disabled={typeof item?.project_id === 'number'}
        />
      <Input
        label={t('createTaskModal.title')}
        otherProps={{
          value: title,
          onChange: event => setValues(prevState => ({ ...prevState, title: event.target.value }))
        }}
        className={'create_task__input create_task__mt'}
        valid={!errors.title} errorMessage={errors.title}
      />
      <div
        className={'create_task__mt'}
        //style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        {/*<CheckboxItem
          label={t('createTaskModal.subscription')}
          active={isCovered}
          onChange={() => setValues(prevState => ({ ...prevState, isCovered: !values.isCovered }))}
        />*/}

        {!isSubscriptions &&
            <Input
                label={'Price'}
                otherProps={{
                    placeholder: t('createTaskModal.price'),
                    style: {
                        flexGrow: 1,
                        // marginLeft: "15px"
                    },
                    value: values.isCovered ? 0 : price_estimate,
                    disabled: values.isCovered,
                    onChange: event => setValues(prevState => ({ ...prevState, price_estimate: event.target.value })),
                    type: 'number',
                }}
                className={'create_task__input'}
                valid={!errors.price_estimate} errorMessage={errors.price_estimate}
            />
        }
      </div>
      <Input
        label={t('createTaskModal.deadline')}
        otherProps={{
          placeholder: t('createTaskModal.deadline'),
          type: 'date',
          value: deadline,
          onChange: (event) => {
              setValues(prevState => ({...prevState, deadline: event.target.value }));
          },
        }}
        className={'create_task__input create_task__mt'}
        valid={!errors.deadline} errorMessage={errors.deadline}
      />
      <div>
          <label className={'create_task__label'} htmlFor={''}>{t('createTaskModal.story')}</label>
          <ReactEditor
              value={client_story}
              onChange={(e: string) => setValues(prevState => ({ ...prevState, client_story: e }))}
              valid={!errors.client_story} errorMessage={errors.client_story}
          />
        </div>
      <button onClick={postData} className={'btn-black create_task__mt create_task__save'}>
        {t('createTaskModal.button')}
      </button>
    </div>
  )
}

export default CreateTask
