import React, {useContext, useEffect, useState} from "react";
import {Input, ReactEditor} from "../../../components/UI";
import customAxios from "../../../service/api/interceptors";
import {MainContextProvider} from "../../../context/MainContext";
import {controlErrorFunc} from "../../../components/UI/ControlValid/controlErrorValid";
import {toastifyError} from "../../../service/toastify/toastify";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";

export const UpdateLegalRequest: React.FC = () => {
    const { setModalType, modalData, setModalData } = useContext(MainContextProvider)
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState({title: '', description: '' })
    const [errors, setErrors] = useState<{ [key: string]: string }>({})

    useEffect(() => {
        setValues({
            ...values,
            title: modalData?.title,
            description: modalData?.description
        })
    }, [])

    const validateData = () => {
        const validReq = controlErrorFunc({ obj: values, config: { title: { required: true, maxLength: 255 }, description: { maxLength: 2501 } } })
        if(Object.keys(validReq).length >= 1) {
            setErrors(validReq)
            toastifyError('Please correctly fill all fields in the form')
            return false;
        }
        setErrors({})

        updateLegalRequest().then()
    }

    const updateLegalRequest = async () => {
        const { title, description } = values
        setLoading(true)
        try {
            const { status, data } = await customAxios.put(`company/offers/${modalData.id}`, { title, description })
            if (status === 200) {
                setModalType('')
                setModalData({ update: true })
            }
        } catch (e) {
            console.log('error', e)
        }
        setLoading(false)
    }

    const chaster_left = 2501 - +(values.description.replace(/&nbsp;/gi, '').trim().replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '').trim().length < 1 || (values.description.trim().replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '')).length || 0)

    return (
        <div style={{ position: "relative" }}>
            {loading && <ModalLoader />}
            <Input
                className={'create_legal_request__custom-request-input'}
                otherProps={{
                    value: values.title,
                    onChange: (e) =>
                        setValues({ ...values, title: e.target.value }),
                    placeholder: 'Title',
                    // disabled: !!choose
                }}
                valid={!errors.title} errorMessage={errors?.title}
            />
            <div style={{ marginTop: '10px' }}>
                <ReactEditor
                    value={values.description}
                    onChange={text => setValues({ ...values, description: text })}
                    valid={!errors.description} errorMessage={errors?.description}
                />
                <em style={{ fontSize: '12px', color: '#9b9b9b', paddingLeft: '5px' }}>
                    {chaster_left > 0 ? chaster_left : 0} characters left
                </em>
            </div>
            <button onClick={validateData} className={'btn-black w100 mt25 text-uppercase'}>Update</button>
        </div>
    )
}