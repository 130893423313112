import customAxios from "../api/interceptors";

// https://stag.api.legalnodes.co
export const needListService = () => customAxios.get(`/user/legal-need/needs-list`)

export const createLegalRequestService = (ids: string, companyId: string) => customAxios.post(`/company/client/kanban/create-legal-request`, {
    legalNeedId: ids, companyId
})

export const createNeedService = (title: string, description: string, companyId: string) => customAxios.post(`/company/client/kanban/create-custom-legal-request`, {
    title, description, companyId
})

export const getLegalRequestService = (id: number) => customAxios.get(`/company/client/kanban/${id}?expand=manager,lawyers,legalRequestComments,next_step,notifications,client,company,project`)

export const createLegalRequestCommentService = (request_id: number, text: string) => {
    return customAxios.post(`/company/comment/comment/create-legal-request`, { legal_request_id: +request_id, text: text })
}

export const createOfferFromRequestService = (id: number) => customAxios.post(`/company/offer/create-from-legal-request`, { id })

export const getAllRequestsService = () => customAxios.get(`/company/offer/legal-requests-for-manager`)