import { AxiosPromise, AxiosResponse } from 'axios'
import customAxios from './api/interceptors'
import { getClients } from './client'

type PutUserData = {
  firstname: string
  lastname: string
  email: string
  password: string
  password_repeat: string
  cropData: any
}

export type BillingHistoryType = {
  id: number
  sum: number
  service_list: string
  status: number
  timestamp: string
  invoice_url: string
}

export type SubscriptionsType = {
  active: string
  description: string
  id: string
  next_billing_date: number
  price: string
  purchase_url: string
  title: string
  type: string
  url: string
  payment_method: string
  stripe_subscription_id: string
  activate_date: number
  is_retainer: 1 | 0
  price_monthly: number

}
export const getUserPhoto = () => customAxios.get('forms/account-information/get-avatar-120-path')

export const getUserInfo = () => customAxios.get('user/profile/simple')

export const changeProfileData = (data: PutUserData) => {
  const { firstname, lastname, email, password, password_repeat, cropData } = data
  let bodyFormData = new FormData();
  bodyFormData.set('firstname', firstname)
  bodyFormData.set('lastname', lastname)
  bodyFormData.set('email', email)
  bodyFormData.set('password', password)
  bodyFormData.set('password_repeat', password_repeat)
  bodyFormData.set('avatar_url', cropData)

  return customAxios.post('forms/account-information', bodyFormData, {
    headers:{ 'Content-Type': 'multipart/form-data' }
  })
}

export const billingHistory = (): Promise<AxiosResponse> => customAxios.get<BillingHistoryType>('user/billings')

export const getAvatar = () => customAxios.get(`user/info/get-avatar-path`)
export const deleteAvatarService = () => customAxios.post(`forms/account-information/remove-avatar`)

export const getSubscriptions = async (): Promise<AxiosResponse> => {
  const res = await getClients()
  return customAxios.get<SubscriptionsType>(`user/client/info/subscriptions`)
}

export const getConsentGiven = () => customAxios.get('/user/info/get-consent-given')
export const setConsentGiven = () => {
  let bodyFormData = new FormData();
  bodyFormData.set('consent-given', '1')
  return customAxios.post('/user/info/set-consent-given', bodyFormData, {
    headers:{ 'Content-Type': 'multipart/form-data' }
  })
}