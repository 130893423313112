import { toast, ToastOptions } from 'react-toastify'

const toastConfig: ToastOptions = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

export const toastifySuccess = (text: string) => {
  toast.success(text, { ...toastConfig })
}

export const toastifyError = (text: string) => {
  toast.error(text, { ...toastConfig })
}
