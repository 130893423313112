import React, {useContext, useEffect, useRef, useState} from "react";
import {MainContextProvider} from "../../../context/MainContext";
import customAxios from "../../../service/api/interceptors";
import {ClientKanbanItem} from "../../../components/other/ClientKanbanBoard/ClientKanbanItem/ClientKanbanItem";
import {useHistory} from "react-router-dom";
import {CSSTransition} from "react-transition-group";
import {Input} from "../../../components/UI";
import {useOnClickOutside} from "../../../hooks/useOnClickOutside";

const offerStatus = (offerData: any) => {
    if(+offerData['is_done'] === 1) {
        return "4"
    }
    if(+offerData?.accepted === 0 ) {
        return "5"
    }
    /*if(+offerData?.accepted === 1 || +offerData?.accepted === 2 || +offerData?.accepted === 3) {
        return "2"
    }*/
    if(+offerData?.accepted === 5) {
        return "9"
    }
    if(+offerData?.accepted === 2) {
        return "2"
    }
    if(+offerData?.accepted === 3) {
        return "3"
    }
    if(+offerData?.accepted === 4) {
        return "4"
    }
    if(+offerData?.accepted === 6) {
        return "6"
    }
    return "1"
}

export const ArchiveBoard: React.FC = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const query = Object.fromEntries(urlSearchParams.entries());
    const { client } = Object.fromEntries(urlSearchParams.entries()) || {  };
    const dropdownRef = useRef<HTMLDivElement>(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })
    const history = useHistory()
    const { setLoading, userData } = useContext(MainContextProvider)
    const [items, setItems] = useState([])
    const [companies, setCompanies] = useState<any[]>([])
    const [isOpen, setIsOpen] = useState(false)
    const [search, setSearch] = useState('')

    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`company/company/get-companies?expand=client,company&per-page=500`)
            setCompanies([
                {id:'', name: 'All'},
                ...data
            ])
            getArchiveItems().then()
        } catch (e) {

        }
    }
    
    const getArchiveItems = async () => {
        try {
            const { status, data } = await customAxios.get(`${userData?.status.type_code === 400 ? 'company/viewer/kanban/archived-offers' : 'company/manager/kanban/archived-offers'}${client ? `?id=${client}` : ''}`)
            if (status === 200) setItems(data)
        } catch (e) {}
        setLoading(false)

    }

    useEffect(() => {
        getAllCompanies().then()
        document.title = "Archive"
    }, [])

    const current = companies.find((el: any) => +el.id === (+query?.client || +companies[0].id))
    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2 className={'client-kanban-header__heading'}>Archive</h2>
                <div style={{ position: 'relative', minWidth: '500px', marginLeft: '188px' }} ref={dropdownRef}>
                    <div className="kanban-dropdown-mobile__current" onClick={() => setIsOpen(!isOpen)}>
                        <div className={'kanban-dropdown-mobile__current-icon'}>&#10095;</div>
                        <div>{current?.name}</div>
                    </div>
                    <CSSTransition in={isOpen} timeout={{ enter: 200, exit: 200 }} classNames={'my-node'} unmountOnExit>
                        <div className="kanban-dropdown-mobile__dropdown">
                            <div style={{ marginBottom: '5px' }}>
                                {companies.length > 6 && <Input
                                    otherProps={{
                                        placeholder: 'Search ...',
                                        value: search,
                                        onChange: e => setSearch(e.target.value)
                                    }}
                                />}
                            </div>

                            {
                                companies.filter((el: { name: string }) => el.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())).map(({ id, name }: any) => (
                                    <div className={`kanban-dropdown-mobile__dropdown-item ${+current?.value === +id && 'kanban-dropdown-mobile__dropdown-item-current-item'}`}
                                         title={name}
                                         onClick={() => {
                                             // history.push(`/dashboard?client=${id}`)
                                             window.location.href = `/archive?client=${id}`
                                             // onGroupDropdownChange(value, groupValue || '')
                                             setIsOpen(false)
                                             setSearch('')
                                         }}
                                    >
                                        {/*{groupValue === FiltersEnum.subscription && <img src={brilliantIcon} alt={''} />}*/}
                                        <span>{name}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </CSSTransition>
                </div>

            </div>
            <div style={{ marginTop: '40px' }}>
                <b style={{ fontSize: '13px', fontWeight: '700' }}>PROJECTS</b>
                <div className={"flex mt5"} style={{ flexWrap: 'wrap' }}>
                    {
                        items.map((item: any) => (
                            <div style={{ marginRight: '15px' }}>
                                <ClientKanbanItem
                                    to={`/offer/${item.id}`}
                                    key={item.id}
                                    { ...item }
                                    statusText={offerStatus(item)}
                                    nextStep={item.status === 300 ? 'Preparing Offer' : 'Discuss with VLO'}
                                    stars={[1,0,0,0,0]}
                                    onClick={() => history.push(`/offer/${item.id}`)}
                                    isWaitIcon={false}
                                    notifications={+item.notifications}
                                    comments={+item.comments}
                                    { ...item }
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}