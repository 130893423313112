import React, {useContext, useEffect, useState} from "react";
import "./crypto-payment-page.scss"
import {useHistory, useParams} from "react-router-dom";
import {InvoiceType} from "../Checkout";
import Icon from "./icons/Icon.png"
import QrIcon from "./icons/QR_code.svg.png"
import copyIcon from "./icons/copyIcon.svg"
import customAxios from "../../../../../service/api/interceptors";
import {MainContextProvider} from "../../../../../context/MainContext";
import axios from "axios";
import {toastifyError} from "../../../../../service/toastify/toastify";
import {intercomCatchErrorMessage} from "../../../../../helpers/intercomCatchErrorMessage";
import {useIntercom} from "react-use-intercom";

export const CryptoPaymentPage: React.FC = () => {
    const history = useHistory()
    const { setLoading } = useContext(MainContextProvider)
    const [invoiceInfo, setInvoiceInfo] = useState<InvoiceType>({} as InvoiceType)
    const [code, setCode] = useState('')
    const { showNewMessages } = useIntercom()
    const { id }: { id: string } = useParams()
    const [qrCodeImage, setQrCodeImage] = useState('');
    const [taxSum, setTaxSum] = useState(0)

    const checkTax = async () => {
        try {
            const { status, data } = await customAxios.get(`/company/billing-info/get-by-first-company?company_id=${invoiceInfo.company.id}`)
            const { data: taxData } = await axios.post(`${process.env.REACT_APP_STRIPE_API_URL}/stripe/check-tax`, {
                amount: +invoiceInfo?.sum,
                country: data.stripe_country_code,
                type: invoiceInfo?.service,
                city: data.city,
                state: data.state,
                line1: data.address,
                postal_code: data.postal_code,
            })

            if (+data.payment_method === 0 || +data.payment_method === 3 || +data.payment_method === 5)
                setTaxSum(+invoiceInfo?.sum / 5)
            else {
                if (taxData.success) {
                    setTaxSum(taxData?.tax_data?.tax / 100)
                }
            }
        } catch (e) {}
    }

    const getInvoiceInfo = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`/invoices/${id}`)
            if (status === 200) {
                setInvoiceInfo(data)
                const { status: codeStatus, data: codeData } = await axios.post(`${process.env.REACT_APP_STRIPE_API_URL}/crypto-payment`, {
                    inv_id: data.id
                })

                if (codeStatus === 200 && codeData) {
                    setCode(codeData?.data?.crypto_key)

                    const { status: qrStatus , data: qrData } = await axios.get(`${process.env.REACT_APP_STRIPE_API_URL}/crypto-payment/qr?url=${codeData?.data?.crypto_key}`)
                    setQrCodeImage(qrData)
                }
            }
        } catch (e) {
            toastifyError('Something went wrong')
            history.push(`/settings/history`)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (invoiceInfo?.id) {
            checkTax()
        }
    }, [invoiceInfo])

    useEffect(() => {
        getInvoiceInfo().then()
        document.title = 'Cryptocurrency Transfer'
    }, [id])


    return (
        <div className={'crypto-payment-page'}>
            <div className="crypto-payment-page__container">
                <div className="crypto-payment-page__left">
                    <h2>Cryptocurrency Transfer from Personal Wallet</h2>
                    <h4>Please use the payment details on the right for this payment.</h4>
                    <p>
                        <b>IMPORTANT!</b> Before proceeding with your payment, please carefully verify the following details:<br/>
                        - Ensure you are using the correct blockchain network;<br />
                        - Confirm the token type and the exact amount being sent;<br />
                        - Double-check that you are sending to the correct wallet address.
                    </p>
                    <p>
                        <b>Warning:</b> Errors in any of these details can result in the irreversible loss of your funds or delays with payment processing.
                    </p>
                    <p>
                        The amount must be transferred <b>in one transaction</b>, payment by several transactions is not supported.
                    </p>
                    <p>
                        <b>There is no need to provide proof of payment (unless requested),</b> the payment will be processed automatically after
                        20 confirmations by the blockchain network. Your account will be automatically updated after the payment is confirmed.
                    </p>
                    <p>
                        This payment address is for <b>one-time use only.</b> Do not reuse it for future payments. For subsequent
                        payments, please request a new payment address.
                    </p>
                    <p>
                        If there is any issue with sending the funds, please contact our <a onClick={() => showNewMessages("")}>support team</a> to resolve the issue.
                    </p>
                </div>
                <div className="crypto-payment-page__right">
                    <div className="crypto-payment-page__body">
                        <h2>Payment Details:</h2>
                        <div className={'crypto-payment-page__block mt20'}>
                            <div className={'flex'} style={{ alignItems: 'flex-end' }}>
                                <img src={Icon} alt=""/>
                                <span>Network:</span>
                            </div>
                            <b>Ethereum</b>
                        </div>
                        <div className={'crypto-payment-page__block mt15'}>
                            <div className={'flex'} style={{ alignItems: 'center' }}>
                                <img src={Icon} alt=""/>
                                <span>
                                    Amount to send: <br />
                                    <em>Please note that this amount may include VAT (if applicable)</em>
                                </span>
                            </div>
                            <b>{+invoiceInfo?.sum + taxSum} USDC</b>
                        </div>

                        <div className={'crypto-payment-page__block mt15'} style={{ alignItems: "flex-start", flexDirection: 'column' }}>
                            <div className={'flex'} style={{ alignItems: 'flex-end' }}>
                                <img src={Icon} alt=""/>
                                <span>
                                To the address:
                            </span>
                            </div>
                            <div className={'crypto-payment-page__qr'}>
                                <div className={'crypto-payment-page__qr-text'}>
                                    Scan the QR code:
                                </div>
                                <img style={{ width: '150px' }} src={qrCodeImage} alt=""/>
                                <div className={'crypto-payment-page__qr-text'}>
                                    ... or copy the address <img onClick={() => navigator.clipboard.writeText(code)} src={copyIcon} alt=""/>
                                </div>
                                <div className={'crypto-payment-page__qr-text mt15'}>
                                    {code}
                                </div>
                            </div>
                        </div>
                        <button onClick={() => history.push(`/settings/history`)} className="w100 text-uppercase btn-black mt30">I Have Paid</button>
                    </div>
                </div>
            </div>
        </div>
    )
}