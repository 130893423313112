import controlValid from '../components/UI/ControlValid/controlValid'

export function pushElement(array:any[], item:any) {
  const newArray = JSON.parse(JSON.stringify(array))
  newArray.push(item)
  return newArray
}

export function updateElement(array:any[], item:any, index:any, identIndex:any = 'id') {
  const newArray = JSON.parse(JSON.stringify(array))
  const elIndex = array.findIndex((el) => el[identIndex] === index)
  newArray[elIndex] = item
  return newArray
}

export function deleteElement(array:any[], index:any, identIndex:any = 'id') {
  const newArray = JSON.parse(JSON.stringify(array))
  const elIndex = array.findIndex((el) => el[identIndex] === index)
  newArray.splice(elIndex, 1)
  return newArray
}

export function changeValue(formControl:any, name:string, value:string | number) {
  const newArr = JSON.parse(JSON.stringify(formControl))
  newArr[name].value = value
  return newArr
}

export function formatDate(date:any) {
  if (date !== null) {
    date = new Date(+date)
    let dd = date.getDate()
    if (dd < 10) dd = `0${dd}`
    let mm = date.getMonth() + 1
    if (mm < 10) mm = `0${mm}`
    let yy:any = date.getFullYear() % 10000
    if (yy < 10) yy = `0${yy}`
    return `${yy}-${mm}-${dd}`
  }
  return ''
}

export const formControlValid = (obj:any) => {
  let isValid = true
  const formControl = JSON.parse(JSON.stringify(obj))
  Object.keys(formControl).map((key) => {
    const valid = controlValid(formControl[key].value, formControl[key].validation)
    formControl[key].valid = valid
    isValid = valid && isValid
  })
  return { formControl, isValid }
}
