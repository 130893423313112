import React, {useContext, useEffect, useState} from "react";
import {NoCreditsModal} from "./NoCreditsModal";
import {MainContextProvider} from "../../../context/MainContext";
import {CreditApproveModal} from "./CreditApproveModal";

type Props = {
    creditsSum: number
    commonTitle: string
    noCreditsSubtitle: string
    noCreditsDescription: string
    close: () => void
    submit: () => void
    acceptTitle?: string
    continueButtonTitle?: string
}

export const CreditControlModal: React.FC<Props> = ({
    creditsSum, commonTitle, noCreditsSubtitle, noCreditsDescription, close, submit, acceptTitle, continueButtonTitle
}) => {
    const { creditCount } = useContext(MainContextProvider)
    const [isOpenNoCreditModal, setIsOpenNoCreditModal] = useState(false)
    const [isOpenSubmitModal, setIsOpenSubmitModal] = useState(false)

    useEffect(() => {
        if (!creditCount.isCreditSubscription) {
            submit()
            return
        }

        if (creditCount.total_count < creditsSum) {
            setIsOpenNoCreditModal(true)
        } else {
            setIsOpenSubmitModal(true)
        }
    }, [])

    const closeModal = () => {
        setIsOpenNoCreditModal(false)
        close()
    }

    return (
        <>
            <NoCreditsModal
                isOpen={isOpenNoCreditModal}
                close={closeModal}
                title={commonTitle}
                subtitle={noCreditsSubtitle}
                description={noCreditsDescription}
            />
            <CreditApproveModal
                ok={submit}
                isOpen={isOpenSubmitModal}
                close={closeModal}
                title={commonTitle}
                subtitle={acceptTitle || '1 credit will be charged. This will enable your VLO to begin working on your request.'}
                continueButtonTitle={continueButtonTitle || 'SUBMIT TO VLO'}
            />
        </>
    )
}