import React from "react";
import LogoIcon from "../../assets/images/logo.svg";
import {useHistory} from "react-router-dom";
import envelopeImg from '../../assets/images/icon-clients.png'

export const OfflineLayout: React.FC = ({ children }) => {
    const history = useHistory()

    return (
        <div className={'layout'}>
            <div className={'layout__container'}>
                <header className={`header`}>
                    <div className={'header__left'}>
                        <img onClick={() => history.push('/')} className={'header__logo'} src={LogoIcon} alt={''} />
                        <ul className={'header__nav'} />
                    </div>
                </header>
                <div className={'layout__wrap'}>
                    <div className={'layout__sidebar'}>
                        <ul className={'layout__sidebar-nav'}>
                            <li>
                                <a href={'/'} className={'layout__sidebar-nav-link layout__sidebar-nav-link_active'}>
                                    <span className={'layout__sidebar-nav-icon'}>
                                        <img className={'layout__sidebar-nav-icon'} src={envelopeImg} alt=""/>
                                    </span>
                                    Client Canban
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className={'layout__container'}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}