import {UpdateNps} from "./UpdateNps";
import logoIcon from "../../../assets/images/logo.svg"
import {useHistory} from "react-router-dom";
import Input from "../../UI/Input/Input";
import {useContext, useEffect, useState} from "react";
import axios, {AxiosError} from "axios";
import {MainContextProvider} from "../../../context/MainContext";
import {toastifyError} from "../../../service/toastify/toastify";

export const NpsNoAuthRoute = () => {
    const history = useHistory()
    const { setLoading } = useContext(MainContextProvider)

    useEffect(() => {
        setLoading(false)
    }, [])

    if (!localStorage.getItem("nps-token")) {
        return <NpsAuth />
    }

    return (
        <div className={'layout'}>
            <header className={'header'}>
                <div className="header__left">
                    <img src={logoIcon} alt="" className="header__logo"/>
                </div>
                <div className="header__right mr10">
                    <div />
                    <button onClick={() => history.push('/')} className={'btn btn-black text-uppercase'}>Sign in</button>
                </div>
            </header>
            <div style={{ maxWidth: '1600px' }}>
                <UpdateNps isUserAuth={true} />
            </div>
        </div>
    )
}

const NpsAuth = () => {
    const { setLoading } = useContext(MainContextProvider)
    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")
    const history = useHistory()

    const auth = async () => {
        setLoading(true)
        try {
            const { request, status, data } = await axios.post(`${process.env.REACT_APP_STRIPE_API_URL}/login`, {
                email: login,
                password
            })

            if (status === 200) {
                localStorage.setItem("nps-token", data.token)
                history.push(`/update-nps/admin`)
            }

        } catch (e) {
            const error = e as AxiosError;
            if (error?.response?.status === 422) {
                toastifyError("User not found or incorrect password")
            }
        }
        setLoading(false)
    }

    return (
        <div style={{ width: "500px", margin: "0 auto" }}>
            <h2 style={{ fontSize: "24px", textAlign: "center", fontWeight: 800, marginBottom: "10px" }}>Login</h2>

            <Input
                label={"Login"}
                otherProps={{
                    value: login,
                    onChange: event => setLogin(event.target.value)
                }}
            />
            <Input
                className={"mt15"}
                label={"Password"}
                otherProps={{
                    value: password,
                    onChange: event => setPassword(event.target.value)
                }}
            />
            <button onClick={auth} className={"w100 btn-black text-uppercase mt15"}>Login</button>
        </div>
    )
}