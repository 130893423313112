import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {FiltersConfigType, FiltersOptionsConfigType} from "../../../types/custom/filters.type";
import {KeyStringArrayType} from "../../../types/custom/keyValue.type";
import {FiltersEnum} from "../../../enums/filters";
import {setUrlSearchQueryNew} from "../../../helpers/urlSearchQuery";
import {useHistory, useLocation} from "react-router-dom";
import '../../../components/other/KanbanProjectTabs/KanbanProjectTabs.scss'
import brilliantIcon from '../../../assets/images/icons/briliant.svg'
import {CSSTransition} from "react-transition-group";
import {useOnClickOutside} from "../../../hooks/useOnClickOutside";
import {Input} from "../../../components/UI";
import {MainContextProvider} from "../../../context/MainContext";
import {FrozenController} from "../../../components/other/FrozenController/FrozenController";

interface TasksProjectKanbanHeaderDropdownProps {
    onChange: (filters: KeyStringArrayType) => void;
    value: string;
    filterConfig: FiltersConfigType[]
}

const sliderLength = 5;

const KanBanMobileDropDown: React.FC<any> = ({ dropdownOptions, onGroupDropdownChange }) => {
    const { unPainInvoice } = useContext(MainContextProvider)
    const [isOpen, setIsOpen] = useState(false)
    const history = useHistory()
    const urlSearchParams = new URLSearchParams(window.location.search);
    const query = Object.fromEntries(urlSearchParams.entries());
    const current = dropdownOptions.find((el: any) => +el.id === (+query?.client || +dropdownOptions[0].id))
    const dropdownRef = useRef<HTMLDivElement>(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })
    const [search, setSearch] = useState('')

    const dept = () => {
        if (current && current.name === 'All' && unPainInvoice > 0) {
            return 'clientUnPainAllCompanies'
        } else {
            return current?.debt
        }
    }

    //             {window.innerWidth > 500 && <h2 className={'client-kanban-header__heading'}>Legal Board</h2>}
    return <>
        {window.innerWidth > 500 && <h2 className={'client-kanban-header__heading'} style={{ width: '290px' }}>Legal Board</h2>}
            <div className={'kanban-dropdown-mobile'} ref={dropdownRef} style={{ maxWidth: "350px" }}>
            <FrozenController debt={dept()} />
            <div>
                <div className="kanban-dropdown-mobile__current" onClick={() => setIsOpen(!isOpen)}>
                    <div className={'kanban-dropdown-mobile__current-icon'}>&#10095;</div>
                    <div>{current?.name}</div>
                </div>
                <CSSTransition in={isOpen} timeout={{ enter: 200, exit: 200 }} classNames={'my-node'} unmountOnExit>
                    <div className="kanban-dropdown-mobile__dropdown">
                        <div style={{ marginBottom: '5px' }}>
                            {dropdownOptions.length > 6 && <Input
                                otherProps={{
                                    placeholder: 'Search ...',
                                    value: search,
                                    onChange: e => setSearch(e.target.value)
                                }}
                            />}
                        </div>

                        {
                            dropdownOptions.filter((el: { name: string }) => el.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())).map(({ id, name }: any) => (
                                <div className={`kanban-dropdown-mobile__dropdown-item ${+current?.value === +id && 'kanban-dropdown-mobile__dropdown-item-current-item'}`}
                                     title={name}
                                     onClick={() => {
                                         // history.push(`/dashboard?client=${id}`)
                                         window.location.href = `/dashboard?client=${id}`
                                         // onGroupDropdownChange(value, groupValue || '')
                                         setIsOpen(false)
                                         setSearch('')
                                     }}
                                >
                                    {/*{groupValue === FiltersEnum.subscription && <img src={brilliantIcon} alt={''} />}*/}
                                    <span>{name}</span>
                                </div>
                            ))
                        }
                    </div>
                </CSSTransition>
            </div>
        </div>
    </>
}

export const KanbanTabs: React.FC<{ hideFrozen?: boolean, dropdownOptions: {
        debt?: string | "";
        id:string, name: string }[] }> = ({ dropdownOptions, hideFrozen }) => {
    const { unPainInvoice } = useContext(MainContextProvider)
    const { search } = useLocation()
    const history = useHistory()
    const urlSearchParams = new URLSearchParams(window.location.search);
    const query = Object.fromEntries(urlSearchParams.entries());

    const updateAllFilters = useCallback((filter) => {
        setUrlSearchQueryNew({
            filter,
            search,
            history,
        })
    }, [search, history])

    const onDropdownChange = (filters: KeyStringArrayType) => {
        updateAllFilters(filters)
    }

    const onGroupDropdownChange = (value: string | number, groupValue: string) => {
        const filters: KeyStringArrayType = {
            [FiltersEnum.subscription]: [value as string],
        }
        onDropdownChange(filters)
    }

    const [sliderIndex, setSliderIndex] = useState(0)

    const isNextSlide = () => {
        const maxLength = dropdownOptions.length - sliderLength
        return sliderIndex === (maxLength - (maxLength * 2));
    }

    if(dropdownOptions.length <= 2 && !hideFrozen) {
        return <div>
            <FrozenController debt={dropdownOptions[1] ? (dropdownOptions[1].debt || '') : ''} />
        </div>
    }

    if(window.innerWidth < 60600 && dropdownOptions.length > 0) {
        return <KanBanMobileDropDown
            dropdownOptions={dropdownOptions}
            onGroupDropdownChange={onGroupDropdownChange}
        />
    }

    return (
        <>
            <div className={'kanban-project-tabs-container'}>
                {dropdownOptions.length > sliderLength &&
                <button
                    className={'kanban-project-tabs-arrows'}
                    disabled={sliderIndex === 0}
                    onClick={() => setSliderIndex(prevState => (prevState +1))}
                >
                    &#x276E;
                </button>
                }
                <ul className={`${dropdownOptions.length > sliderLength && 'kanban-project-tabs-withArrows'} kanban-project-tabs`}>
                    {
                        dropdownOptions.map(({ id, name }, index) => (
                            <li
                                title={name}
                                style={{ marginLeft: index === 0 ? `${sliderIndex * 20}%` : 0 }}
                                className={`${(+query?.client || dropdownOptions[0].id) === id && 'kanban-project-tabs__item-active'} kanban-project-tabs__item`}
                                onClick={() => {
                                    // history.push(`/dashboard?client=${id}`)
                                    window.location.href = `/dashboard?client=${id}`
                                }}>
                                {/*{groupValue === FiltersEnum.subscription && <img src={brilliantIcon} alt={''} />}*/}
                                <span>{name}</span>
                            </li>
                        ))
                    }
                </ul>
                {dropdownOptions.length > sliderLength &&
                <button
                    className={'kanban-project-tabs-arrows'}
                    disabled={isNextSlide()}
                    onClick={() => setSliderIndex(prevState => (prevState -1))}
                >
                    &#x276F;
                </button>
                }
            </div>
            <div className={'kanban-project-tabs-margin'} />
        </>
    )
}