import { setStorageItem, getStorageItem, clearStorageItem } from '../storage/storage'
import { TokenEnum, TokenType } from './token.type'

export const setTokenChunk = (tokenChunk : TokenType): void => {
  Object.entries(tokenChunk).forEach(([key, value]) => {
    setStorageItem({ value, key })
  })
}

export const setAccessToken = (value : string): void => setStorageItem({ value, key: TokenEnum.accessToken })
export const setRefreshToken = (value : string): void => setStorageItem({ value, key: TokenEnum.refreshToken })
export const setExpiresInToken = (value : string): void => setStorageItem({ value, key: TokenEnum.expiresIn })

export const getAccessToken = (): string | null => getStorageItem(TokenEnum.accessToken as string)
export const getRefreshToken = (): string | null => getStorageItem(TokenEnum.refreshToken as string)
export const getExpiresInToken = (): string | null => getStorageItem(TokenEnum.expiresIn as string)

export const clearToken = (): void => {
  Object.keys(TokenEnum).forEach((itemKey) => {
    clearStorageItem(itemKey)
  })
}
