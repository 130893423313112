import clickIcon from "../../../../assets/images/partnerRequest/click.svg"
import React from "react";

export const ConfirmPartnerRequest: React.FC<{ updatePartnerRequest: (data: any) => void }> = ({ updatePartnerRequest }) => {
    return (
        <div className={'confirm-partner-request'}>
            <div className="confirm-partner-request__heading">
                <img src={clickIcon} alt=""/>
                <p style={{ color: 'white' }}>PLEASE CONFIRM YOUR INTEREST IN THIS PROJECT</p>
            </div>
            <div className="confirm-partner-request__body">
                By clicking “I am interested”, you will be invited to provide further details for your work on this project.
                This action is <b style={{ fontWeight: 'bold' }}>not binding and you are not obligated to proceed with works.</b> Instead, you will be able to provide
                details of the tasks necessary for this project, along with price and time estimates.
            </div>
            <div className="confirm-partner-request__buttons">
                <div onClick={() => updatePartnerRequest({ status: '2' })} className="confirm-partner-request__buttons-not">
                    I AM NOT INTERESTED
                </div>
                <div onClick={() => updatePartnerRequest({ status: 1 })} className="confirm-partner-request__buttons-yes">
                    I AM INTERESTED
                </div>
            </div>
        </div>
    )
}