import haveAQuestionsIcon from '../../../../../assets/images/have-a-questions.svg'
import whiteArrow from '../../../../../assets/images/white-right-arrow.svg'
import './have-questions.scss'
import React from "react";

export const HaveQuestions: React.FC<{ showMessages: () => void }> = ({ showMessages }) => {
    return (
        <div className={'have-questions'} onClick={showMessages}>
            <h2>Have a question?</h2>
            <img className={'have-questions__icon'} src={haveAQuestionsIcon} alt=""/>
            <div className="have-questions__footer">
                <span>Contact us <a>here</a></span>
                <a className={'have-questions__button'}>
                    <img src={whiteArrow} alt=""/>
                </a>
            </div>
        </div>
    )
}