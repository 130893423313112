import React, {useEffect, useState} from "react";
import "./plan-information.scss"
import moment from "moment-timezone";
import ModalContainer from "../../../../../components/ModalContainer";
import logoIcon from "../../../../../assets/images/logo.svg"
import plusIcon from "../../../../../assets/images/icons/plus.svg";
import {UnsubscribeModalConfirm} from "../UnsubscribeModals/UnsubscribeModalConfirm";
import {UnsubscribeModalQuiz} from "../UnsubscribeModals/UnsubscribeModalQuiz";
import {UnsubscribeModalSuccess} from "../UnsubscribeModals/UnsubscribeModalSuccess";
import customAxios from "../../../../../service/api/interceptors";

type Props = {
    companyCredits: {
        total_count: number,
        covered_by_monthly_plan: number,
        purchased: number,
        expiration_purchased: number
        expiration_covered_by_monthly_plan: number | null
    }
    plan_name: string
    payment_period: string | null
    plan_date: string | null
    cancelPlan: () => void
    plan_id: string
    updateSubscriptions: () => void
    is_sub_paused: string
    showNewMessages: (key: string) => void
    isUpcomingTab: boolean
    isUpcoming: boolean
    isSubscriptionActive: boolean
}

const calcValidToDate = (isUpcoming: boolean, date: string, isSubscriptionActive: boolean): string => {
    let dateF = moment.tz(date, "DD/MM/YYYY");
    dateF.add(1, 'days');

    if (!isSubscriptionActive) {
        return `Expired on ${date}`
    }

    return isUpcoming ? `Start from ${moment.tz(dateF, 'UTC').format("DD/MM/YYYY")}` : `Valid to ${date}`
}

export const PlanInformation: React.FC<Props> = ({
    cancelPlan,
    companyCredits,
    plan_name,
    payment_period,
    plan_date,
    plan_id,
    updateSubscriptions,
    is_sub_paused,
    showNewMessages,
    isUpcomingTab,
    isUpcoming,
    isSubscriptionActive
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isUnsubscribeModalOpen, setIsUnsubscribeModalOpen] = useState(false)
    const [isUnsubscribeReason, setIsUnsubscribeReason] = useState(false)
    const [isUnsubscribeSuccess, setIsUnsubscribeSuccess] = useState(false)

    const getPlanPeriodName = (key: string) => {
        switch (key + '') {
            case '1': return 'Monthly'
            case '3': return 'Quarterly'
            case '12': return 'Yearly'
        }
    }

    return (
        <div className="payment-plan-information">
            <div className="payment-plan-information-left-side" style={{ background: (!isSubscriptionActive && !isUpcomingTab) ? '#FF602E' : '#2361FF' }}>
                <div className="payment-plan-information-left-side-name">{(plan_name || '').replace(/\s*\(.*?\)\s*/g, '')} {getPlanPeriodName(payment_period + '')}</div>
                <div className="payment-plan-information-left-side-period">{plan_date && calcValidToDate(isUpcomingTab, plan_date, isSubscriptionActive)}</div>
                {/*<div className="payment-plan-information-left-side-valid-to" style={{ color: is_sub_paused === '1' ? '#FFFFFF' : '#99B6FF' }}>
                    {plan_date && `(Valid to ${plan_date})`}
                </div>*/}
                {!isUpcoming && <>
                    {plan_date && is_sub_paused === '0' && !isUpcomingTab &&
                        <div onClick={() => setIsUnsubscribeModalOpen(true)}
                             className={'payment-plan-information-left-side-cancel'}>
                            Cancel Plan
                        </div>}
                    {is_sub_paused === '1' && !isUpcomingTab &&
                        <div style={{color: '#FFFFFF'}} onClick={() => showNewMessages(`Reactivate ${plan_name}`)}
                             className={'payment-plan-information-left-side-cancel'}>
                            Reactivate Plan
                        </div>}
                </>}
            </div>
            <div className="payment-plan-information-right-side">
                <div className="payment-plan-information-right-side-total-balance">
                    My Total Credits Balance: {companyCredits.total_count || 0}
                </div>
                <div className="payment-plan-information-right-side-balances">
                    <span>Covered by monthly plan: {companyCredits.covered_by_monthly_plan || 0} {companyCredits.expiration_covered_by_monthly_plan && <em>(Valid
                        to {moment.tz(+companyCredits.expiration_covered_by_monthly_plan * 1000, 'UTC').format('DD/MM/YYYY')})</em>}</span>
                    <span>Purchased: {companyCredits.purchased || 0} <a href="#payment-plan-credits"><img src={plusIcon} alt=""/></a></span>
                </div>
                <div onClick={() => setIsModalOpen(true)} className="payment-plan-information-right-side-how-use">
                    How can I use my credits?
                </div>
            </div>

            <InfoModal
                isOpen={isModalOpen}
                close={() => setIsModalOpen(false)}
            />
            <UnsubscribeModalConfirm
                isOpen={isUnsubscribeModalOpen}
                close={() => setIsUnsubscribeModalOpen(false)}
                subscription={{ id: plan_id, title: plan_name, date: plan_date }}
                ok={() => {
                    setIsUnsubscribeReason(true)
                    setIsUnsubscribeModalOpen(false)
                }}
            />
            <UnsubscribeModalQuiz
                isOpen={isUnsubscribeReason}
                close={() => setIsUnsubscribeReason(false)}
                subscription={{ id: plan_id }}
                ok={() => {
                    setIsUnsubscribeSuccess(true)
                    setIsUnsubscribeReason(false)
                }}
            />
            <UnsubscribeModalSuccess
                isOpen={isUnsubscribeSuccess}
                close={() => {
                    setIsUnsubscribeSuccess(false)
                    updateSubscriptions()
                }}
                subscription={{ id: plan_id, title: plan_name, date: plan_date }}
            />
        </div>
    )
}




const InfoModal: React.FC<{ isOpen: boolean, close: () => void }> = ({ isOpen, close }) => {
    return (
        <div className={'payment-plan-information-modal'}>
            <ModalContainer isOpen={isOpen} close={close}>
                <div>
                    <div className={'payment-plan-information-modal__logo'}>
                        <img src={logoIcon} alt=""/>
                    </div>
                    <div className={'payment-plan-information-modal__text'}>
                        <h3>How can I use my credits?</h3>
                        <p>
                            Use your Credits to create new legal requests. For your VLO to begin working on your legal request,
                            you must pay 1 Credit per request. Some legal requests are simple and can be completed without additional cost.
                        </p>
                        <p>
                            For more complex legal requests, your VLO will provide an estimate on any additional Credits that may be required.
                            You will be notified each time credits are deducted from your balance.
                        </p>
                        <h3>Credits included in your subscription</h3>
                        <p>
                            Each month that your subscription is active, you will receive a number of Credits to spend on your business project.
                            Make sure to use them within the month, as they cannot be transferred to the next month of your subscription. Please check
                            the expiry dates and use your credits before they expire. We will always redeem your credits covered by your monthly plan
                            first before redeeming any additional credits that you have purchased.
                        </p>
                        <h3>What if my Credits balance is insufficient?</h3>
                        <p>
                            If you are unable to proceed with a request, you can buy additional Credits to top up your balance.
                            We recommend purchasing Credits in advance to avoid delays in processing your requests. You can use our
                            Credit bundles to quickly top up your balance.
                        </p>
                        <button onClick={close} className={'btn-black w100 mt20'}>OK</button>
                    </div>
                </div>
            </ModalContainer>
        </div>
    )
}