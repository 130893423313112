import {useContext, useEffect, useState} from "react";
import './manager_profile.scss'
import {MainContextProvider} from "../../../context/MainContext";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";
import customAxios from "../../../service/api/interceptors";
import avatar from '../../../assets/images/profileIcons/avatar.svg'
import bio from '../../../assets/images/profileIcons/bio.svg'
import cup from '../../../assets/images/profileIcons/cup.svg'
import education from '../../../assets/images/profileIcons/education.svg'
import location from '../../../assets/images/profileIcons/location.svg'
import service from '../../../assets/images/profileIcons/service.svg'
import verify from '../../../assets/images/profileIcons/verify.svg'
import langIcon from '../../../assets/images/profileIcons/emailIcon.svg'

type ManagerProfileType = {
    avatar_path: string
    bio: string
    education: {
        education: string
        id: number
        user_id: number
        years: string
    }[]
    location: {
        id: number
        location: string
        user_id: number
    }[]
    experience: string
    fullname: string
    hourly_pay: string
    languages: string
    projects: {project: string}[]
    service: string
    title: string
}

export const ManagerProfile = () => {
    const { modalData } = useContext(MainContextProvider)
    const [loading, setLoading] = useState(true)
    const [managerInfo, setManagerInfo] = useState<ManagerProfileType>({} as ManagerProfileType)

    const getManagerData = async () => {
      if(!modalData) {
          return
      }
        const type = typeof modalData === 'object' ? modalData.type : '300'
        const id = typeof modalData === 'object' ? modalData.id : modalData
      const { status, data } = await customAxios.get(`/user/${type === '300' ? 'manager' : 'provider'}/skills?id=${id}`)
        if(status === 200) {
            setManagerInfo(data)
        }
        setLoading(false)
    }

    useEffect(() => {
        getManagerData().then()
    }, [modalData])

    if(loading) {
        return <div style={{ position: 'relative' }}>
            <ModalLoader/>
        </div>
    }

    return (
        <div className={'manager_profile'} style={{ position: 'relative' }}>
            <div className="manager_profile__heading">
                <img style={{ height: '96px' }} src={managerInfo?.avatar_path || avatar} alt=""/>
                <div className={'manager_profile__main'}>
                    <div className={'manager_profile__title'}>
                        <div>
                            <div className={'manager_profile__title-name'}>{managerInfo?.fullname}</div>
                            <img src={verify} alt=""/>
                        </div>
                        <div className={'manager_profile__sum'}>
                            {managerInfo?.hourly_pay}
                        </div>
                    </div>
                    <div className="manager_profile__position">
                        {managerInfo?.title}
                    </div>
                    {
                        managerInfo?.location && managerInfo?.location[0] && managerInfo.location.map((item) => (
                            <div className="manager_profile__location">
                                <img src={location} alt=""/>
                                <span>{item.location}</span>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="manager_profile__body">
                <div style={{ display: 'flex' }}>
                    {managerInfo?.languages && <div className="manager_profile__info" style={{ width: '50%' }}>
                        <div>
                            <img src={langIcon} alt=""/>
                            <b>Languages</b>
                        </div>
                        <p>{managerInfo?.languages}</p>
                    </div>}
                    {managerInfo?.experience && <div className="manager_profile__info" style={{ width: '50%' }}>
                        <div>
                            <img src={cup} alt=""/>
                            <b>Years of experience</b>
                        </div>
                        <p>{managerInfo?.experience}</p>
                    </div>}
                </div>
                {managerInfo?.service && <div className="manager_profile__info">
                    <div>
                        <img src={service} alt=""/>
                        <b>SERVICE</b>
                    </div>
                    <p>{managerInfo?.service}</p>
                </div>}
                {managerInfo?.education && managerInfo?.education[0] && <div className="manager_profile__info">
                    <div>
                        <img src={education} alt=""/>
                        <b>EDUCATION</b>
                    </div>
                    {
                        managerInfo?.education.map((item) => (
                            <p>
                                {item.education}
                                <em>{item.years}</em>
                            </p>
                        ))
                    }
                </div>}
                {managerInfo?.projects && managerInfo?.projects[0] && <div className="manager_profile__info">
                    <div>
                        <img src={education} alt=""/>
                        <b>Projects</b>
                    </div>
                    {
                        managerInfo?.projects.map((item) => (
                            <p>
                                {item.project}
                            </p>
                        ))
                    }
                </div>}
                {managerInfo?.bio && <div className="manager_profile__info">
                    <div>
                        <img src={bio} alt=""/>
                        <b>BIO</b>
                    </div>
                    <p>{managerInfo?.bio}</p>
                </div>}
            </div>
        </div>
    )
}