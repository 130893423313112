import React from "react";
import {CSSTransition} from "react-transition-group";
import './auth-modal.scss'

export type AuthModalProps = {
    isOpen: boolean
    close: () => void
    col?: boolean
}

export const AuthModal: React.FC<AuthModalProps> = ({ children, isOpen, close, col }) => {
    return (
        <CSSTransition in={isOpen} classNames={'slide'} timeout={200} unmountOnExit>
            <>
                <div className={`${col && 'auth-cols'} auth-modal`}>
                    {children}
                </div>
                <div onClick={() => close()} className={'modal__bg'} />
            </>
        </CSSTransition>
    )
}