import "./terms_use_privacy_policy.scss"
import {CustomCheckbox} from "../../../components/other/Registration/CustomCheckbox/CustomCheckbox";
import {useContext, useState} from "react";
import customAxios from "../../../service/api/interceptors";
import {MainContextProvider} from "../../../context/MainContext";
import imgIcon from "./icon.svg"

export const TermsUsePrivacyPolicy = () => {
    const { userData, setModalData, setModalType } = useContext(MainContextProvider)
    const [checked, setChecked] = useState(false)
    const [loading, setLoading] = useState(false)
    
    const confirmPrivacyPolicy = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.put(`/user/provider/${userData?.id}/terms/accept`)
            if (status === 200) {
                setModalData(null)
                setModalType('')
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <div className={'terms_use_privacy_policy-modal__body'}>
            <div className="terms_use_privacy_policy-modal__body-logo">
                <img src={imgIcon} alt=""/>
            </div>
            <div className="terms_use_privacy_policy-modal__text mt25">
                <h2>Welcome to Legal Nodes</h2>
                <p className={''}>
                    Before proceeding, please read and accept our
                    the <a target={'_blank'} href="https://drive.google.com/file/d/1Ei4Kp4hWT5VuC-yNL-kqM4jp3bAnwZM8/view?usp=drive_link">Terms of Service</a> and the <a target={'_blank'} href="https://drive.google.com/file/d/1SjuP_NyIPlz2aHcfhCAGYOOOgUylZNWe/view">Privacy Policy</a>.
                </p>
                <p>
                    Upon agreeing to these conditions, your account will be activated, and you will begin to receive project requests from Legal Nodes.
                </p>
                <div className={'mt20 terms_use_privacy_policy-modal__checkbox'}>
                    {/*<CustomCheckbox
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                        isStopPropagation={true}
                        label={`I have read and agreed to the` + ` <a target={'_blank'} href="https://drive.google.com/file/d/1Ei4Kp4hWT5VuC-yNL-kqM4jp3bAnwZM8/view?usp=drive_link">Terms of Service</a> and the <a target={'_blank'} href="https://drive.google.com/file/d/1SjuP_NyIPlz2aHcfhCAGYOOOgUylZNWe/view">Privacy Policy</a>.`}
                    />*/}
                    <div onClick={() => setChecked(!checked)} className={'terms_use_privacy_policy-modal__checkbox-container'}>
                        <div className="terms_use_privacy_policy-modal__checkbox-squire">
                            {checked && <div className={'terms_use_privacy_policy-modal__checkbox-active'} />}
                        </div>
                        <div className="terms_use_privacy_policy-modal__checkbox-label">
                            I have read and agreed to the <a onClick={e => e.stopPropagation()} target={'_blank'} href="https://drive.google.com/file/d/1Ei4Kp4hWT5VuC-yNL-kqM4jp3bAnwZM8/view?usp=drive_link">Terms of Service</a> and the <a onClick={e => e.stopPropagation()} target={'_blank'} href="https://drive.google.com/file/d/1SjuP_NyIPlz2aHcfhCAGYOOOgUylZNWe/view">Privacy Policy</a>.
                        </div>
                    </div>
                </div>
                <button onClick={confirmPrivacyPolicy} className={'btn-black w100 text-uppercase mt20'} disabled={!checked}>CONTINUE</button>
            </div>
        </div>
    )
}