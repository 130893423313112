import cardIcon from '../../../../assets/images/mastercard-blue.svg'
import plusIcon from '../../../../assets/images/createIcon.svg'
import './payment-methods.scss'
import basketIcon from '../../../../assets/images/basket-black.svg'
import { useContext } from 'react'
import { MainContextProvider } from '../../../../context/MainContext'

const PaymentMethods = () => {
  const { setModalType } = useContext(MainContextProvider)
  return (
    <div className={'payment-method'}>
      <div className={'payment-method__table'}>
        <div className={'payment-method__table-thead'}>
          <span className={'payment-method__th payment-method__thead-cols payment-method__thead-cols-w40'}>{'Payment Method'}</span>
          <span className={'payment-method__th payment-method__thead-cols payment-method__thead-cols-w30'}>{'Billing Address'}</span>
          <span className={'payment-method__th payment-method__thead-cols'}>{'Expiration Date'}</span>
          <span className={'payment-method__th payment-method__thead-cols'}>{'Actions'}</span>
        </div>
        <div className={'payment-method__table-tbody'}>
          <div className={'payment-method__thead-cols payment-method__thead-cols-w40'}>
            <span className={'payment-method__card'}>
              <img src={cardIcon} alt={''} />
              <p>
                {'MASTERCARD..6259'}
                <br />
                {'Expires 09/24\r'}
              </p>
            </span>
          </div>
          <div className={'payment-method__thead-cols payment-method__thead-cols-w30'}>
            <b className={'payment-method__h4'}>{'DPO Startup Oversight'}</b>
            <span className={'payment-method__span'}>{'$580/mo'}</span>
          </div>
          <div className={'payment-method__thead-cols'}>
            <span className={'payment-method__date'}>{'Jul 7, 2022'}</span>
          </div>
          <div className={'payment-method__thead-cols payment-method__actions'}>
            <a href={''}><img src={basketIcon} alt={''} /></a>
          </div>
        </div>
      </div>
      <div onClick={() => setModalType('payment_method')} className={'payment-method__button'}>
        <div className={'payment-method__button-container'}>
          <div className={'payment-method__button-icon'}>
            <img src={plusIcon} alt={''} />
          </div>
          <span>{'ADD PAYMENT METHOD'}</span>
        </div>
      </div>
    </div>
  )
}

export default PaymentMethods
