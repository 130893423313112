import {Input, ReactSelect, TextArea} from "../../../components/UI";
import './CreateLegalTask.scss'
import React, {useContext, useEffect, useState} from "react";
import customAxios from "../../../service/api/interceptors";
import {MainContextProvider} from "../../../context/MainContext";
import {createTask, getSingleTask, updateTask} from "../../../service/tasks";
import {controlErrorFunc} from "../../../components/UI/ControlValid/controlErrorValid";
import {toastifyError} from "../../../service/toastify/toastify";
import {TASK_STATUSES} from "../../Client/Task/TaskView/taskHelpers";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";
import {CheckboxItem} from "../../../components/UI/Checkbox";

const staticValidationRules = {
    title: { required: true, maxLength: 255 },
    //provider_id: { required: true },
    status: { required: true },
}

const validationRules = {
    ...staticValidationRules,
    price_estimate: { required: true, number: true, minNumber: 0 },
    time_estimate: { required: true, number: true, minNumber: 0 },
    credit_price: { required: false, number: true, minNumber: 0 }
}

const validationRulesPerHour = {
    ...staticValidationRules,
    price_per_hour: { required: true, number: true, minNumber: 1, maxLength: 255 },
}

const dateForInput = (date: any = new Date()) => {
    const parsed = Date.parse(date)
    return parsed.toString().substring(0, parsed.toString().length - 3)
}

export const CreateLegalTask = () => {
    const { modalData, setModalData, setModalType, creditCount } = useContext(MainContextProvider)
    const [loading, setLoading] = useState(true)
    const [providers, setProviders] = useState([])
    const [errors, setErrors] = useState<{[key: string]: string}>({})
    const [isPartTime, setIsPartTime] = useState(false)
    const [isApproved, setIsApproved] = useState(false)
    const [isCoveredBySubscription, setIsCoveredBySubscription] = useState(false)
    const [values, setValues] = useState({
        title: '',
        provider_id: +'',
        status: '2',
        price_estimate: '',
        time_estimate: '',
        project_id: +'',
        price_per_hour: '',
        deliverable: '',
        deadline: formatDate(new Date()),
        government_fee: false,
        is_no_deliverable: 0,
        credit_price: 0,
        type: "DEFAULT"
    })

    const checkDeliverableExists = async () => {
      if(!modalData?.id) {
          return;
      }
      const { status, data } = await customAxios.get(`/company/deliverable?per-page=150&expand=task`)
      if((data || []).find((el: any) => +el.task_id === modalData.id)) {
          setIsApproved(true)
      }
    }

    const getTaskData = async () => {
        setLoading(true)
        const { status, data } = await getSingleTask(modalData?.id)
        setValues(prevState => ({
            ...prevState,
            title: data.title,
            status: data.status,
            provider_id: data?.executors && data?.executors[0]?.id,
            price_estimate: data.price_estimate + '',
            time_estimate: data.time_estimate + '',
            price_per_hour: data.price_per_hour,
            deadline: formatDate(data.deadline + '000'),
            government_fee: !!Number(data.is_fee_exempt),
            is_no_deliverable: +data.is_no_deliverable,
            credit_price: +data?.credit_price || 0,
            type: data.type
        }))
        if (data.type === 'COVERED_BY_SUBSCRIPTION') {
            setIsCoveredBySubscription(true)
        }
        if(+data.price_per_hour > 0) {
            setIsPartTime(true)
        }
        if(data?.deliverables) {
            setIsApproved(true)
        }
        setLoading(false)
    }

    const getProviders = async () => {
        const { status, data } = await customAxios.get(`/company/task/get-all-providers`)
        if(status === 200 && data) {
            setProviders(data)
            setValues({ ...values, provider_id: (data || [])[0]?.id })
            if(modalData?.id) {
                await getTaskData()
            }
        }
        setLoading(false)
    }
    useEffect(() => {
        getProviders().then()
        // checkDeliverableExists().then()
    }, [])


    const saveTask = async () => {
        const validationObj = JSON.parse(JSON.stringify(isPartTime ? validationRulesPerHour : validationRules))
        /*if (+values.status === 5 && !isApproved) {
            validationObj.deliverable = { required: true, url: true }
        }*/

        if (!isPartTime) {
            if (+values.credit_price > 0) {
                validationObj.price_estimate.required = false
                validationObj.credit_price.required = true
            } else {
                validationObj.price_estimate.required = true
                validationObj.credit_price.required = false
            }
        }

        const validReq = controlErrorFunc({ obj: values, config: validationObj })
        /*if (+values.status === 5 && !isApproved && !values.deliverable.includes('docs.google.com')) {
            validReq.deliverable = 'Please enter valid link for google docs file'
        }*/

        if(Object.keys(validReq).length >= 1) {
            setErrors(validReq)
            toastifyError('Please correctly fill all fields in the form')
            return false;
        }
        setErrors({})

        setLoading(true)
        const { title, price_estimate, time_estimate, status, provider_id, price_per_hour, deliverable, credit_price } = values
        const isCreditPrice = credit_price > 0

        const postData = {
            client_story: "",
            deadline: dateForInput(new Date(values.deadline)),
            price_per_hour: isPartTime ? price_per_hour : '',
            price_estimate: (isPartTime || isCreditPrice) ? 0 : +price_estimate,
            time_estimate: isPartTime ? '0' : time_estimate,
            title, status, provider_id: values.provider_id, project_id: modalData?.project_id,
            type: isCoveredBySubscription ? 'COVERED_BY_SUBSCRIPTION' : (values.type === 'COVERED_BY_SUBSCRIPTION' ? "" : values.type),
            partner_request_id: modalData?.partner_request_id,
            is_no_deliverable: values.is_no_deliverable,
            credit_price: isPartTime ? 0 : +values.credit_price
            //is_fee_exempt: values.government_fee ? '1' : 0
        }

        const { status: requestStatus, data } = modalData?.id ? await updateTask(modalData?.id, postData) : await createTask(postData)
        const requestData = data
        if(requestStatus === 200 || requestStatus === 201) {
            setModalData({ update: true })
            setModalType('')
        }

        if(+values.status === 5 && !isApproved) {
            const { status, data } = await customAxios.post(`/company/deliverables`, {
                name: title,
                description: deliverable,
                url: deliverable,
                task_id: modalData?.id ? modalData.id : requestData.id
            })
        }

        setLoading(false)
    }

    function formatDate(date:any) {
        if (date !== null) {
            date = new Date(+date)
            let dd = date.getDate()
            if (dd < 10) dd = `0${dd}`
            let mm = date.getMonth() + 1
            if (mm < 10) mm = `0${mm}`
            let yy:any = date.getFullYear() % 10000
            if (yy < 10) yy = `0${yy}`
            return `${yy}-${mm}-${dd}`
        }
        return ''
    }

    return (
        <div style={{ position: 'relative' }}>
            {loading && <ModalLoader />}
            {/*<p>
                You can create an offer based on a customer request or create an empty offer template.
            </p>*/}
            <div>
                <Input
                    label={'Title'}
                    otherProps={{
                        placeholder: 'Title',
                        value: values.title,
                        onChange: e => setValues({ ...values, title: e.target.value })
                    }}
                    valid={!errors?.title}
                    errorMessage={errors.title}
                    className={'mt15'}
                />
            </div>
            {/*<div style={{ marginTop: '15px' }}>
                <ReactSelect
                    label={'Provider'}
                    value={+values.provider_id}
                    options={providers.map((item: any) => ({ value: +item.id, label: item.fullname }))}
                    onChange={(e) => setValues({ ...values, provider_id: e.value })}
                    valid={!errors?.provider_id}
                    errorMessage={errors.provider_id}
                />
            </div>*/}
            <div style={{ marginTop: '15px' }}>
                <ReactSelect
                    label={'Status'}
                    value={values.status}
                    options={TASK_STATUSES}
                    onChange={(e) => setValues({ ...values, status: e.value })}
                    valid={!errors?.status}
                    errorMessage={errors.status}
                />
            </div>
            <div className={'mt15'}>
                <Input
                    label={'Deadline'}
                    otherProps={{
                        type: 'date',
                        value: values.deadline,
                        onChange: e => setValues({ ...values, deadline: e.target.value })
                    }}
                />
            </div>
            {!isPartTime && !isCoveredBySubscription && <div style={{ display: 'flex', marginTop: '15px' }}>
                <div style={{ width: '50%', paddingRight: '5px' }}>
                    <div>
                        <Input
                            label={'Price estimate'}
                            otherProps={{
                                placeholder: 'Price',
                                value: values.price_estimate,
                                onChange: e => setValues({ ...values, price_estimate: e.target.value })
                            }}
                            valid={!errors?.price_estimate}
                            errorMessage={errors.price_estimate}
                        />
                    </div>

                </div>
                <div style={{ width: '50%', paddingLeft: '5px' }}>
                    <Input
                        label={'Time estimate'}
                        otherProps={{
                            placeholder: 'Time estimate',
                            value: values.time_estimate,
                            onChange: e => setValues({ ...values, time_estimate: e.target.value })
                        }}
                        valid={!errors?.time_estimate}
                        errorMessage={errors.time_estimate}
                    />
                </div>
            </div>}
            {!isPartTime && !isCoveredBySubscription && <div className={'w100 mt15'}>
                <Input
                    label={'Credit price'}
                    otherProps={{
                        placeholder: 'Credit price',
                        value: values.credit_price,
                        onChange: e => setValues({ ...values, credit_price: +e.target.value })
                    }}
                    valid={!errors?.credit_price}
                    errorMessage={errors.credit_price}
                />
            </div>}
            {isPartTime && <div className={'mt15'}>
                <Input
                    label={'Price per hour'}
                    otherProps={{
                        placeholder: 'Price per hour',
                        type: 'number',
                        value: values.price_per_hour,
                        onChange: e => setValues({ ...values, price_per_hour: e.target.value })
                    }}
                    valid={!errors?.price_per_hour}
                    errorMessage={errors.price_per_hour}
                />
            </div>}
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'column' }}>
                <div>
                    <CheckboxItem active={isPartTime} onChange={() => {
                        setIsPartTime(!isPartTime)
                        setIsCoveredBySubscription(false)
                    }} label={'Pay-per-hour task'} />
                </div>
                <div>
                    <CheckboxItem active={isCoveredBySubscription} onChange={() => {
                        setIsPartTime(false)
                        setIsCoveredBySubscription(!isCoveredBySubscription)
                        setValues({ ...values, price_estimate: '0', time_estimate: '0', price_per_hour: '' })
                    }} label={'Covered by Monthly Plan'} />
                </div>
                <div>
                    <CheckboxItem active={+values.is_no_deliverable === 1} onChange={() => {
                        setValues({ ...values, is_no_deliverable: +values.is_no_deliverable === 1 ? 0 : 1 })
                    }} label={'This task does not require a deliverable'} />
                </div>
                {/*<div>
                    <CheckboxItem
                        label={'Fee Exempt'}
                        active={values.government_fee}
                        onChange={() => setValues({ ...values, government_fee: !values.government_fee })}
                    />
                </div>*/}
            </div>
            <button onClick={saveTask} style={{ width: '100%', marginTop: '20px' }} className={'btn-black'}>SAVE</button>
        </div>
    )
}