import {useContext, useEffect} from "react";
import {MainContextProvider} from "../../../context/MainContext";
import logoIcon from "../../../assets/images/logo.svg"
import "./kyc_modal.scss"

export const KYCModal = () => {
    const { modalData } = useContext(MainContextProvider)

    useEffect(() => {

    }, [modalData])

    return (
        <div className={'kyc_modal__body'}>
            <div className="kyc_modal__body-logo">
                <img src={logoIcon} alt=""/>
            </div>
            <div className="kyc_modal__text">
                <p>
                    Thank you for choosing our services.

                    <b className={'mt15'}>Before you proceed with the KYC verification process, please make sure you have the following information available:</b>

                    <b className={'mt15'}>Proof of Address:</b>

                    &#9679; A scanned copy or screenshot of a utility bill or bank statement
                    <b className={'mt15'}>⚠️Please note that many KYC checks fail because incorrect documents are provided.</b>
                    <b className="mt15">
                        Your document must include your name and the address of your residence in order to satisfy KYC requirements.
                    </b>
                    <a
                        style={{ display: 'block' }}
                        target={'_blank'}
                        href="https://intercom.help/legal-nodes-ltd/en/articles/8680086-what-is-a-proof-of-address-and-what-does-it-look-like"
                        className="mt15">View examples of acceptable proofs of address.</a>
                </p>
                <p>
                    <b>Personal Details:</b>
                    &#9679; Full Name<br />
                    &#9679; Date of Birth<br />
                    &#9679; Residence Country<br />
                    &#9679; Email Address<br />
                    &#9679; Phone Number
                </p>
                <p>
                    <b>Identity Document:</b>
                    &#9679; A scanned copy of your Government ID, Passport, or Driver's License
                </p>

                <p>
                    <b>Liveness Verification:</b>
                    &#9679; You must be able to conduct a simple liveness selfie verification with your phone or device
                </p>
                <a
                    style={{ display: 'block' }}
                    target={'_blank'}
                    href="https://intercom.help/legal-nodes-ltd/en/articles/8680092-faq-about-kyc-verification"
                    className="mt15"> View our KYC FAQs.</a>
                <a href={modalData?.form_url}>
                    <button className={'btn-black w100 mt20 text-uppercase'}>I AM READY, BEGIN VERIFICATION</button>
                </a>
            </div>
        </div>
    )
}