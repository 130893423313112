import React from 'react'
import './pagination.scss'

type PaginationType = {
  currentPage: number;
  pagesAmount: number;
  setCurrentPage: (key:number) => void;
}

type PaginationItemType = {
  number: number;
  isActive?: boolean;
}

const generatePaginationItems = (currentPage: number, pagesAmount: number, setCurrentPage: (key: number) => void) => {
  const PaginationItem:React.FC<PaginationItemType> = ({ number, isActive }) => <div className={`pagination__item ${isActive ? 'pagination__item--active' : ''}`} onClick={() => setCurrentPage(number)}>{number}</div>
  const Dots = () => <div className={'pagination__item-dots'}>{'...'}</div>

  return (
    <>
      {!(currentPage < 3) ? <PaginationItem number={1} /> : null}
      {
                currentPage < 5
                  ? currentPage > 3
                    ? <PaginationItem number={currentPage - 2} />
                    : null
                  : <Dots />
            }

      {!(currentPage < 2) ? <PaginationItem number={currentPage - 1} /> : null}
      <PaginationItem isActive number={currentPage} />
      {!(currentPage > (pagesAmount - 1)) ? <PaginationItem number={currentPage + 1} /> : null}

      {
                (currentPage > (pagesAmount - 4))
                  ? (currentPage > (pagesAmount - 3))
                    ? null
                    : <PaginationItem number={currentPage + 2} />
                  : <Dots />
            }
      {!(currentPage > pagesAmount - 2) ? <PaginationItem number={pagesAmount} /> : null}
    </>
  )
}

const Pagination:React.FC<PaginationType> = ({ currentPage = 1, pagesAmount = 1, setCurrentPage }) => (
  <div className={'pagination'}>
    <img style={{ maxWidth: 20 }} className={'pagination__arrow-left'} src={'https://cdn2.iconfinder.com/data/icons/font-awesome/1792/angle-left-512.png'} onClick={() => (currentPage > 1 ? setCurrentPage(currentPage - 1) : null)} alt={''} />
    <div className={'pagination__container'}>
      {generatePaginationItems(currentPage, pagesAmount, setCurrentPage)}
    </div>
    <img style={{ maxWidth: 20 }} className={'pagination__arrow-right'} src={'https://cdn2.iconfinder.com/data/icons/font-awesome/1792/angle-left-512.png'} onClick={() => (currentPage < pagesAmount ? setCurrentPage(currentPage + 1) : null)} alt={''} />
  </div>
)

export default Pagination
