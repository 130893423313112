import React, {useContext, useEffect, useState} from "react";
import WaitingImage from "../../../../assets/images/waiting-image.png";
import deliverablesNotificationsImage from '../../../../assets/images/deliverablesNotifications.svg'

import customAxios from "../../../../service/api/interceptors";
import {MainContextProvider} from "../../../../context/MainContext";

export const DeliverablesHeaderNotifications: React.FC = () => {
    const { setModalType, setModalData, approvedDeliverables, setApprovedDeliverables } = useContext(MainContextProvider)

    const getData = async () => {
        try {
            const { status, data } = await customAxios.get(`/company/deliverable?per-page=150&expand=task`)
            if(status === 200) {
                const items = data.filter((el: any) => el.task.status === '5')
                setApprovedDeliverables(items)
                if(items.length > 0) {
                    // setModalType('all_approve_deliverables')
                    // setModalData({ items })
                }

            }
        } catch (e) {}
    }

    useEffect(() => {
        getData().then()
    }, [])

    return (
        <div>
            {approvedDeliverables.length > 0 && <a
                id={`header-deliverables-button`}
                onClick={() => {
                    setModalType('all_approve_deliverables')
                    setModalData({
                        items: approvedDeliverables
                    })
                }}
                className="header__right-link">
                <img style={{ marginLeft: '2px' }} src={deliverablesNotificationsImage} alt=""/>
                <span className={'header__right-counter'}>{approvedDeliverables.length > 9 ? '9+' : approvedDeliverables.length}</span>
            </a>}
        </div>
    )
}