import React, {useContext, useEffect, useState} from "react";
import offerIcon from "../../../../assets/images/kanbanIcons/offer.svg"
import createIcon from "../../../../assets/images/createIcon.svg"
import penIcon from "../../../../assets/images/pen-white.svg"
import waitIcon from "../../../../assets/images/partnerRequest/wait.svg"
import clickIcon from "../../../../assets/images/partnerRequest/click.svg"
import ModalContainer from "../../../../components/ModalContainer/ModalContainer";
import Input from "../../../../components/UI/Input/Input";
import CheckboxItem from "../../../../components/UI/Checkbox/CheckboxItem";
import customAxios from "../../../../service/api/interceptors";
import {TaskType} from "../../../../types/task.type";
import {ModalLoader} from "../../../../components/other/ModalLoader/ModalLoader";
import {toastifyError, toastifySuccess} from "../../../../service/toastify/toastify";
import {controlErrorFunc} from "../../../../components/UI/ControlValid/controlErrorValid";
import {MainContextProvider} from "../../../../context/MainContext";
import {PartnerRequestType} from "../PartnerRequestType";

type Props = {
    request_id: string
    acceptUpdateTasks: boolean
    status: string
    updatePrice: () => void
    requestData: PartnerRequestType
}

export const PartnerRequestTaskTab: React.FC<Props> = ({ request_id, acceptUpdateTasks, status, updatePrice, requestData }) => {
    const { userData } = useContext(MainContextProvider)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [items, setItems] = useState<TaskType[]>([])
    const [editedTask, setEditedTask] = useState<null | string>(null)
    const [loading, setLoading] = useState(false)

    const getData = async () => {
        setLoading(true)
        try {
            const { status, data } =
                await customAxios.get(`/company/tasks/partner-request/${request_id}`)

            if (status === 200) {
                setItems(data)
                setIsModalOpen(false)
                setEditedTask(null)
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        getData().then()
    }, [request_id])

    const deleteTask = async (id: string) => {
        if (!window.confirm('Are you sure?')) return;

        setLoading(true)
        try {
            const { status } = await customAxios.delete(`company/tasks/${id}`)
            if (status === 204) {
                await getData()
                toastifySuccess('Task has been deleted')
                updatePrice()
            }
        } catch (e) {}
        setLoading(false)
    }

    const statusText = () => {
        if (userData?.status.type_code === 200) {
            if (+status === 5 && +requestData?.offer?.accepted === 1) {
                return {
                    title: 'PROJECT APPROVED',
                    icon: clickIcon,
                    background: 'rgb(243, 183, 27)',
                    color: '#FFF',
                    width: '13px'
                }
            }
            switch (status) {
                case '1': return {
                    title: 'TASKS & ESTIMATES (DRAFT)',
                    icon: clickIcon,
                    background: 'rgb(243, 183, 27)',
                    color: '#FFF',
                    width: '13px'
                }
                case '3': return {
                    title: 'TASKS & ESTIMATES (Submitted to VLO)',
                    icon: waitIcon,
                    background: '#FFF',
                    color: '#000',
                    width: '30px'

                }
                case '4': return {
                    title: 'TASKS & ESTIMATES (SENT TO CLIENT)',
                    icon: waitIcon,
                    background: '#FFF',
                    color: '#000',
                    width: '30px'
                }
                case '5': return {
                    title: 'PLEASE CONFIRM THE RECEIVING OF THE PAYMENT FROM THE CLIENT',
                    icon: clickIcon,
                    background: 'rgb(243, 183, 27)',
                    color: '#FFF',
                    width: '13px'
                }
                case '7': return {
                    title: 'TASKS & ESTIMATES (SENT TO CLIENT)',
                    icon: waitIcon,
                    background: '#FFF',
                    color: '#000',
                    width: '30px'
                }
            }
        } else {
            switch (status) {
                case '1': return {
                    title: 'TASKS & ESTIMATES (DRAFT)',
                    icon: waitIcon,
                    background: '#FFF',
                    color: '#000',
                    width: '30px'
                }
                case '3': return {
                    title: 'PLEASE REVIEW ESTIMATES FROM THE PROVIDER',
                    icon: clickIcon,
                    background: 'rgb(243, 183, 27)',
                    color: '#FFF',
                    width: '13px'
                }
                case '4': return {
                    title: 'TASKS & ESTIMATES (SENT TO CLIENT)',
                    icon: waitIcon,
                    background: '#FFF',
                    color: '#000',
                    width: '30px'
                }
                case '5': return {
                    title: 'Accepted from client side',
                    icon: waitIcon,
                    background: '#FFF',
                    color: '#000',
                    width: '30px'
                }
            }
        }
    }

    return (
        <div className="partner-request-task-tab" style={{ position: 'relative' }}>
            {loading && <ModalLoader />}
            {!statusText() ? <div style={{ height: '10px' }} /> : <div>
                {+requestData?.offer?.accepted === 5 ? <div style={{ paddingTop: '15px' }} /> : <div className="partner-request-task-tab__heading"
                                                                                                     style={{borderBottom: '1px solid #e5e5e5', background: statusText()?.background}}>
                    <img style={{width: statusText()?.width }} src={statusText()?.icon}
                         alt=""/>
                    <span style={{color: statusText()?.color, fontWeight: '500'}}>{statusText()?.title}</span>
                </div>}
            </div>}
            {
                ((userData?.status.type_code === 300) && (String(status) === '3')) &&
                    <p className={'partner-request-task-tab__description'}>
                        The provider has sent the task list and price & time estimates. Please review them and accept if they are acceptable for this offer
                    </p>
            }
            {
                userData?.status.type_code === 200 && +status === 1 && <p className={'partner-request-task-tab__description'}>
                    Please provide a list of tasks that are required to complete this project.
                    Please include price and time estimates. Use the chat window to clarify any information that
                    you need to finalize your estimates. <b style={{ fontWeight: 'bold' }}>Please note that the information you provide here is unique to this project.</b>
                </p>
            }
            {
                userData?.status.type_code === 200 && +status === 3 && <p className={'partner-request-task-tab__description'}>
                    Thank you for providing the project details including the task list and price and time estimates.
                    This information will now be processed by the Virtual Legal Officer and sent to the client.
                    You will be notified if the client accepts your project details soon!
                </p>
            }
            {
                userData?.status.type_code === 200 && +status === 4 && <p className={'partner-request-task-tab__description'}>
                    Thank you for providing the project details including the task list and price and time estimates.
                    This information will now be processed by the Virtual Legal Officer and sent to the client.
                    You will be notified if the client accepts your project details soon!
                </p>
            }

            <button disabled={!acceptUpdateTasks} onClick={() => setIsModalOpen(true)} className={'partner-request-task-tab__button'} style={{ marginTop: '5px' }}>
                <div className="partner-request-task-tab__circle">
                    <img src={createIcon} alt=""/>
                </div>
                <span>ADD TASK</span>
            </button>

            <div className="task-tab-list__table mt10" style={{ padding: '0 15px' }}>
                {
                    (items || []).map(item => (
                        <div key={item.id} className="singleOffer__deliverables-task w100" style={{ border: '1px solid rgb(202, 213, 221)' }}>
                            <div className="singleOffer__deliverables-task-title">
                                <p>{item.title}</p>
                                {!!Number(item.is_fee_exempt) && <div className={'singleOffer__deliverables-task-title-fee_exempt'}>
                                    Fee Exempt
                                </div>}
                            </div>
                            <div className="singleOffer__deliverables-task-info" style={{ padding: '0 15px' }}>
                                <div className="singleOffer__deliverables-task-status">
                                    <div className="singleOffer__deliverables-task-label">
                                        Estimate (in days)
                                    </div>
                                    <div className="singleOffer__deliverables-task-text">
                                        {Number(item.price_per_hour) ? '-' : `${item.time_estimate} days`}
                                    </div>
                                </div>
                                <div className="singleOffer__deliverables-task-status">
                                    <div className="singleOffer__deliverables-task-label">
                                        Price Estimate (in USD)
                                    </div>
                                    <div className="singleOffer__deliverables-task-text">
                                        {Number(item.price_per_hour) ? `$${item.price_per_hour}/hour` : `$${item.price_estimate}`}
                                    </div>
                                </div>
                                {(userData?.status.type_code === 200 || userData?.status.type_code === 300) && <div className="deliverables_tasks__button-with-tooltip flex-center"
                                      style={{justifyContent: 'flex-end', padding: '5px 0'}}>
                                    <button
                                        disabled={!acceptUpdateTasks}
                                        onClick={() => {
                                            setIsModalOpen(true);
                                            setEditedTask(item.id)
                                        }}
                                        className="deliverables__desc-edit"
                                        style={{background: 'rgb(35, 97, 255)', border: 'none', cursor: 'pointer'}}>
                                        <img src={penIcon} alt=""/>
                                    </button>
                                    <button
                                        disabled={!acceptUpdateTasks}
                                        onClick={() => deleteTask(item.id)} className="milestones__delete"
                                        style={{marginLeft: '10px'}}>✕
                                    </button>
                                </div>}
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className="create_task">
                {<Modal
                    isModalOpen={isModalOpen}
                    close={() => { setIsModalOpen(false); setEditedTask(null) }}
                    partner_request_id={request_id}
                    updateList={() => {
                        getData().then()
                        updatePrice()
                    }}
                    editedTask={editedTask}
                    provider_id={requestData?.provider?.user_id}
                />}
            </div>
        </div>
    )
}

const initialData = {
    title: '',
    time_estimate: '',
    price_estimate: '',
    government_fee: false,
    partner_request_id: null
}

export const Modal: React.FC<any> = ({ isModalOpen, close, partner_request_id, editedTask, updateList, provider_id }) => {
    const [loading, setLoading] = useState(true)
    const [payPerHourTask, setPayPerHourTask] = useState(false)
    const [errors, setErrors] = useState<any>({})
    const [values, setValues] = useState(JSON.parse(JSON.stringify(initialData)))

    /*useEffect(() => {
        if (payPerHourTask) {
            setValues({ ...values, time_estimate: '' })
        }
    }, [payPerHourTask])*/

    const getTaskData = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`/company/tasks/${editedTask}`)
            if (status === 200) {
                setPayPerHourTask(!!Number(data.price_per_hour))
                setValues({
                    ...JSON.parse(JSON.stringify(initialData)), ...data,
                    price_estimate: Number(data.price_per_hour) || data.price_estimate || 0,
                    government_fee: !!Number(data.is_fee_exempt)
                })
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        if(isModalOpen) {
            if(editedTask)
                getTaskData().then()
            else {
                setValues(JSON.parse(JSON.stringify(initialData)))
                setLoading(false)
                setPayPerHourTask(false)
            }
        }
    }, [editedTask, isModalOpen])

    const saveTask = async () => {
        const validReq = controlErrorFunc({ obj: values, config: {
            title: { required: true, maxLength: 255 },
            price_estimate: { required: true, maxLength: 10, minNumber: 0 },
            time_estimate: { required: !payPerHourTask, maxLength: 10, minNumber: 0 },
        }})
        if(Object.keys(validReq).length >= 1) {
            setErrors(validReq)
            toastifyError('Please correctly fill all fields in the form')
            return false;
        } else {
            setErrors({})
        }

        const { title, time_estimate, price_estimate } = values
        const sendData = {
            partner_request_id, title, time_estimate: +time_estimate,
            price_estimate: payPerHourTask ? 0 : price_estimate,
            price_per_hour: payPerHourTask ? price_estimate : 0,
            total_price: payPerHourTask ? 0 : price_estimate,
            provider_id, deadline: ((+(new Date())) / 1000).toFixed(),
            is_fee_exempt: values.government_fee ? '1' : 0
        }

        setLoading(true)
        try {
            const { status, data } = editedTask
                ? await customAxios.put(`/company/tasks/${editedTask}`, sendData)
                : await customAxios.post(`/company/tasks`, sendData)

            if (status === 200 || status === 201) {
                updateList()
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <ModalContainer isOpen={isModalOpen} heading={'Task'} close={close} headingClass={'create_task__heading'}>
            <div className={'mt20'}>
                {loading && <div style={{ position: 'relative' }}><ModalLoader /></div>}
                <Input
                    label={'Title'}
                    otherProps={{
                        value: values.title,
                        onChange: e => setValues({ ...values, title: e.target.value })
                    }}
                    valid={!errors?.title}
                    errorMessage={errors.title}
                />
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                    <Input
                        label={payPerHourTask ? 'Price estimate(in USD/hour)' : 'Price Estimate (in USD)'}
                        className={payPerHourTask ? 'w100' : 'w50'}
                        otherProps={{
                            value: values.price_estimate,
                            onChange: e => setValues({ ...values, price_estimate: e.target.value }),
                            style: { marginRight: '5px' },
                            type: "number"
                        }}
                        valid={!errors?.price_estimate}
                        errorMessage={errors.price_estimate}
                    />
                    {!payPerHourTask && <Input
                        label={'Time estimate (in days)'}
                        className={'w50'}
                        otherProps={{
                            value: values.time_estimate,
                            onChange: e => setValues({ ...values, time_estimate: e.target.value }),
                            style: {marginLeft: '5px'},
                            type: "number"
                        }}
                        valid={!errors?.time_estimate}
                        errorMessage={errors.time_estimate}
                    />}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '5px' }}>
                    <CheckboxItem
                        label={'Pay-per-hour task'}
                        active={payPerHourTask}
                        onChange={() => {
                            if (!payPerHourTask) setValues({ ...values, time_estimate: '' })
                            setPayPerHourTask(!payPerHourTask)
                        }}
                    />
                    <CheckboxItem
                        label={'Fee Exempt'}
                        active={values.government_fee}
                        onChange={() => setValues({ ...values, government_fee: !values.government_fee })}
                    />
                </div>
                <button onClick={saveTask} className={'btn-black w100 mt15 text-uppercase'}>Save</button>
            </div>
        </ModalContainer>
    )
}