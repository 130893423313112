import {useContext, useState} from "react";
import {MainContextProvider} from "../../../context/MainContext";
import customAxios from "../../../service/api/interceptors";
import {toastifyError, toastifySuccess} from "../../../service/toastify/toastify";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";
import {useHistory} from "react-router-dom";

export const TransitionFromTrial = () => {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const { setModalType } = useContext(MainContextProvider)

    const createRetainer = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.post(`v1/user/client/invoice/create-retainer`)
            if (status === 200 && data.id) {
                toastifySuccess('An invoice has been created, please pay it.')
                history.push(`/checkout/${data.id}`)
                setModalType('')

            }
        } catch (e: any) {
            setModalType('')
            toastifyError(e.response?.data?.message)
        }
        setLoading(false)

    }

    return (
        <div style={{ position: 'relative' }}>
            {loading && <ModalLoader />}
            <p className={'create_manager_offer__description'}>
                Hit the button below to confirm your upgrade. You’ll be able to make a payment and get started with your Monthly Plan
            </p>

            <button onClick={createRetainer} className={'btn-black w100 text-uppercase mt25'}>Upgrade now</button>
        </div>
    )
}