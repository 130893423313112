import {useContext, useEffect, useState} from "react";
import {MainContextProvider} from "../../../context/MainContext";
import axios from "axios";
import moment from "moment";
import {TextArea} from "../../UI";

export const NpsErrors = () => {
    const { setLoading } = useContext(MainContextProvider)
    const [items, setItems] = useState([])

    const getErrors = async () => {
        try {
            const { status, data } = await axios.get(`${process.env.REACT_APP_STRIPE_API_URL}/errors`)
            if (status === 200) {
                setItems(data?.items || [])
            }
        } catch (e) {}
    }

    useEffect(() => {
        getErrors().then()
        setLoading(false)
    },[])

    return (
        <div style={{ maxWidth: "1200px", margin: "50px auto" }}>
            <div className={"nps-results"}>
                <div className={"nps-results__item"}>
                    <div className="nps-results__item-heading">Errors</div>
                </div>

                {
                    items.map(item => (
                        <div className={"nps-results__item"}>
                            <div className="nps-results__item-heading">
                                <div>{item?.client}</div>
                                <div>{moment(item.created_at).format("DD-MM-YYYY HH:mm")}</div>
                            </div>
                            <div className="nps-results__item-body">
                                <TextArea
                                    otherProps={{
                                        rows: 10,
                                        value: JSON.stringify(JSON.parse(item.json), null, 4)
                                    }}
                                />
                            </div>
                        </div>
                    ))
                }

            </div>
        </div>
    )
}