import React, {useContext, useState} from "react";
import ModalContainer from "../../../../../components/ModalContainer";
import logoIcon from "../../../../../assets/images/logo.svg";
import {CheckboxItem, TextArea} from "../../../../../components/UI";
import customAxios from "../../../../../service/api/interceptors";
import axios from "axios";
import {MainContextProvider} from "../../../../../context/MainContext";

const canselSubscriptionReason = [
    { value: '1', label: 'The price is too high.' },
    { value: '2', label: 'I am not using the product/services fully.' },
    { value: '3', label: 'I am not happy with the quality of the product/services.' },
    { value: '4', label: 'I want to pause and come back later.' },
    { value: '5', label: 'Other (please specify)' },
]

export const UnsubscribeModalQuiz: React.FC<{
    isOpen: boolean, close: () => void, subscription: any, ok: () => void
}> = ({ isOpen, close, subscription, ok }) => {
    const { userData, setLoading } = useContext(MainContextProvider)
    const [reason, setReason] = useState('1')
    const [description, setDescription] = useState('')

    const cancelSubscription = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.post(`subscription/${subscription.id}/unsubscribe`)
            if (status === 200) {
                const { status: unStatus, data: unData } = await axios.post(`${process.env.REACT_APP_STRIPE_API_URL}/unsubscribes`, {
                    user_id: userData?.user_id,
                    questions_json: JSON.stringify({
                        reason, description
                    })
                })
                if (unStatus === 201) {
                    ok()
                }
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <div className={'payment-plan-information-modal'}>
            <ModalContainer isOpen={isOpen} close={close}>
                <div>
                    <div className={'payment-plan-information-modal__logo'}>
                        <img src={logoIcon} alt=""/>
                    </div>
                    <div className={'payment-plan-information-modal__text'}>
                        <h2 style={{ textAlign: 'center', marginTop: '40px' }}>We’re sorry to see you go!</h2>
                        <p style={{ textAlign: 'center', marginTop: '35px' }}>
                            Please tell us why you’ve unsubscribed. Your feedback helps<br /> us improve.
                        </p>
                        <div className={'mt20 payment-plan-information-modal__checkbox'} style={{ maxWidth: '450px', margin: '0 auto' }}>
                            {
                                canselSubscriptionReason.map(item => (
                                    <CheckboxItem
                                        active={reason === item.value}
                                        onChange={() => setReason(item.value)}
                                        label={item.label}
                                    />
                                ))
                            }
                            <TextArea
                                className={'mt20'}
                                otherProps={{
                                    placeholder: 'Your message here',
                                    rows: 5,
                                    value: description,
                                    onChange: e => setDescription(e.target.value)
                                }}
                            />

                            <button onClick={cancelSubscription} className={'btn-black w100 mt20'}>CANCEL SUBSCRIPTION</button>
                            <button
                                onClick={close}
                                style={{ border: '1px solid #000', background: '#fff', color: '#000' }}
                                className={'btn-black w100 mt5'}>
                                GO BACK
                            </button>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </div>
    )
}