import React from 'react'
import { CheckboxType } from './Checkbox.type'
import checkboxIcon from '../../../assets/images/checkbox.svg'
import './checkbox.scss'

const Checkbox:React.FC<CheckboxType> = ({ options, chooseValues, onChange }) => (
  <div className={'checkbox'}>
    {
                options.map(({ value, label }) => (
                  <div
                    key={value}
                    className={'checkbox__item'}
                    onClick={() => {
                      const custom = JSON.parse(JSON.stringify(chooseValues));
                      (custom.includes(value))
                        ? custom.splice(chooseValues.findIndex((el) => el === value), 1)
                        : custom.push(value)
                      onChange(custom)
                    }}
                  >
                    <div className={`checkbox__item-element ${chooseValues.find((el) => el === value) && 'checkbox__item-active'}`}>
                      <img src={checkboxIcon} alt={''} />
                    </div>
                    <span className={'checkbox__item-label'}>{label}</span>
                  </div>
                ))
            }
  </div>
)

export default Checkbox
