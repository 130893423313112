import {
  useContext, useState, useEffect, useCallback,
} from 'react'
import { MainContextProvider } from '../../context/MainContext'
import { CLIENT, LAWYER } from '../../routes'
import { getUserProfile } from '../../service/userProfile/userProfile'
import { RouterType } from '../../helpers/types'
import { UserType } from '../../enums/userType'
import LoginRouter from '../../routes/login'
import {getUserPhoto} from "../../service/settings";
import {MANAGER} from "../../routes/manager";
import Centrifuge from "centrifuge";
import pushNotifications from "../../helpers/pushNotifications";
import {getAllInvoicesService} from "../../service/settings/invoices";
import {sendEvent} from "../../service/tracker";

function setCookie(key: string, value: string, expirationInHours: number) {
  const date = new Date();
  date.setTime(date.getTime() + expirationInHours * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = `${key}=${value}; ${expires}; path=/`;
}

function checkUserSessionCookie() {
  const cookies = document.cookie.split("; ");
  for (const cookie of cookies) {
    const [name, value] = cookie.split("=");
    if (name === "user_session_storage") {
      return true
    }
  }
  return false
}

const clientStartUrl = '/dashboard'
const lawyerStartUrl = '/clients'
const loginStartUrl = '/'

const UseRoutes = () => {
  const {
    isLoggedIn, setRole, setLoading, setUserData, userOnBoarding, setUserOnBoarding, setCentrifuge, setUnPaidInvoice, getCreditCount
  } = useContext(MainContextProvider)

  const [routes, updateRoutes] = useState<RouterType[]>([])
  const [startUrl, updateStartUrl] = useState<string>('')

  const switchRoutes = (code: number) => {
    switch (code) {
      case 100: return CLIENT
      case 200: return LAWYER
      case 300: return MANAGER
      case 400: return MANAGER
      default: return CLIENT
    }
  }

  const switchUrl = (code: number) => {
    switch (code) {
      case 100: return '/dashboard'
      case 200: return '/dashboard'
      case 300: return '/dashboard'
      case 400: return '/dashboard'
      default: return '/dashboard'
    }
  }

  const allRoutes = useCallback(async () => {
    setLoading(true)

    let appRouts: RouterType[] = LoginRouter
    let redirectUrl: string = loginStartUrl

    if (isLoggedIn) {

      const { data } = await getUserPhoto()
      const response = await getUserProfile()

      if (!checkUserSessionCookie()) {
        await sendEvent('user_login', {
          user_id: response.data.user_id,
          timestamp: (+(new Date()) / 1000).toFixed(),
          user_type: response.data.status.type_code
        })
        setCookie("user_session_storage", "value", 8);
      }
      // response.data.status.type_code = 400

      localStorage.setItem('user_type', response.data.status.type_code + '')
      localStorage.setItem('user_name', response.data.name)
      localStorage.setItem('user_email', response.data.email)

      if (response.data.status.type_code === 100) {
        getCreditCount()
        const { status: invoicesStatus, data: invoicesData } = await getAllInvoicesService()

        if (invoicesStatus === 200 && invoicesData) {
          const count = invoicesData.filter((el: { status: string }) => el.status === 'UNPAID')?.length
          setUnPaidInvoice(count)
        }
      }

      if(localStorage.getItem(`initialLink`) && localStorage.getItem(`initialLink`) !== '/') {
        if(response.data.status.type_code === 100 && response.data.flows.onboarding === 0) {

        } else {
          window.location.href = localStorage.getItem(`initialLink`) || ''
          localStorage.removeItem('initialLink')
        }
      }

      setUserOnBoarding(response.data.flows.onboarding === 1)
      response.data && setUserData({  ...response.data, avatar_url: JSON.parse(data).message })
      const { data: { status: { type_code }, id } } = response


      // @ts-ignore
      window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "ylxg44qe",
        name: response.data.name,
        email: response.data.email,
        created_at: +(new Date()), // Signup date as a Unix timestamp
      }

      const isUserTypeClient = type_code === UserType.client
      appRouts = switchRoutes(response.data.status.type_code)
      localStorage.setItem('first_login', 'true')

     redirectUrl = switchUrl(response.data.status.type_code)

      if(isUserTypeClient) {
        if(response.data.flows.onboarding === 0) {
          redirectUrl = '/onboarding'
        } else {
          redirectUrl = '/dashboard'
        }
      }

      setRole(type_code)

      if (localStorage.getItem('first_login')) {
        //sendEvent({ event_name: 'user_login', user_id: `user_${id}`, user_type: type_code })
        localStorage.removeItem('first_login')
      }

      const centrifuge = new Centrifuge(process.env.REACT_APP_WEBSOCKET_SERVER as string);
      centrifuge.setToken(response.data.websocket_token)

      centrifuge.on('connect', function(ctx: any) {
        console.log("connected", ctx);
      });

      setCentrifuge(centrifuge)

      centrifuge.on('disconnect', function(ctx: any) {
        console.log("disconnected", ctx);
      });

      centrifuge.connect()
    } else {
    }

    updateRoutes(appRouts)

    updateStartUrl(redirectUrl)
    // setLoading(false)
  }, [isLoggedIn])

  useEffect(() => {
    allRoutes().then()
  }, [allRoutes])

  return {
    routes,
    startUrl,
  }
}

export default UseRoutes
