import toBottomIcon from "../../../assets/images/bottom.png";
import {CSSTransition} from "react-transition-group";
import React, {useRef, useState} from "react";
import {useOnClickOutside} from "../../../hooks/useOnClickOutside";
import {LegalRequestItemType} from "./CreateOffer";

type Props = {
    setChoose: (id: string) => void
    setStep: () => void
    needs: LegalRequestItemType[]
    choose: string
}

export const CreateOfferSelectContainer: React.FC<Props> = ({ setChoose, needs, choose, setStep }) => {
    const [chooseTitle, setChooseTitle] = useState('')
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const dropdownRef = useRef<HTMLDivElement>(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsDropdownOpen(false) })

    const chooseItem = (id: string, need: string) => {
        setChoose(id)
        setIsDropdownOpen(false)
        setChooseTitle(need)
    }

    return (
        <>
            <div className="create_legal_request__select" ref={dropdownRef}>
                <div onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="create_legal_request__select-container">
                    <span>{chooseTitle || 'Choose client request'}</span>
                    <img src={toBottomIcon} alt=""/>
                </div>
                <CSSTransition in={isDropdownOpen} classNames={'my-node'} timeout={200} unmountOnExit>
                    <div className="create_legal_request__dropdown" style={{ width: '100%' }}>
                        {
                            needs.map(({  id, title , created_at, company_name  }) => (
                                <div key={id} className={'create_legal_request__need-block'}>
                                    <div
                                        onClick={() => chooseItem(id + '', title)}
                                        className={'create_legal_request__need-item'}>
                                        <div className="create_legal_request__radio">
                                            {
                                                choose === String(id) && <div className="create_legal_request__radio-active" />
                                            }
                                        </div>
                                        <div>
                                            <div style={{ display: 'flex' }}><span>{title}</span></div>
                                            {company_name && <div><em>{created_at}</em><strong>by {company_name}</strong></div>}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </CSSTransition>
            </div>
            <button
                onClick={setStep}
                className="create_manager_offer__button btn-black">CREATE OFFER</button>
        </>
    )
}