import React, {useContext, useEffect, useRef, useState} from "react";
import createIcon from "../../../../assets/images/createIcon.svg";
import {MainContextProvider} from "../../../../context/MainContext";
import copyIcon from "../../../../assets/images/icons/copy.svg";
import moreVertical from "../../../../assets/images/moreVertical.svg";
import {toastifySuccess} from "../../../../service/toastify/toastify";
import {useOnClickOutside} from "../../../../hooks/useOnClickOutside";
import {TaskType} from "../../../../types/task.type";

type DeliverableType = {description: string, id: number, name: string, task_id: number, url: string, task: TaskType}

export const DeliverablesTab: React.FC<any> = ({ deliverables, offer_id, isUnSubscribe }) => {
    const { userData, setModalType, setModalData } = useContext(MainContextProvider)

    const DeliverablesTabDropDownItem = (item: DeliverableType) => {
        const [isOpen, setIsOpen] = useState(false)
        const dropdownRef = useRef<HTMLDivElement>(null)
        useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })

        return (
            <a style={{ width: '20px', marginLeft: '20px', display: 'inline-block', position: 'relative' }} onClick={() => setIsOpen(!isOpen)}>
                <div ref={dropdownRef}>
                    <img style={{ transform: 'rotate(90deg)' }} src={moreVertical} />
                    {isOpen && <div
                        onClick={() => {
                            setModalData(item)
                            setModalType('legal_task_deliverable')
                        }}
                        className="task-status__body" style={{ width: '100px', left: 'inherit', right: 0, top: 20, padding: '15px', cursor: 'pointer', zIndex: 2 }}>
                        <div className="task-status__body-item">Edit</div>
                    </div>}
                </div>
            </a>
        )
    }

    return (
        <div className={'deliverables deliverables-tab'}>
            <table className={'task-tab-list__table'} style={{ marginBottom: '30px' }}>
                {
                    (deliverables || []).map(({ name, url, id, description, task_id, task }: DeliverableType) => (
                        <tr>
                            <td className={'task-tab-list__text'} style={{ padding: '10px 0' }}>
                                {task?.title} {task?.status === '5' && userData?.status.type_code === 100 && '(Waiting for approval)'}
                            </td>
                            <td
                                onClick={() => {
                                    navigator.clipboard.writeText(url)
                                    toastifySuccess('Your task is complete!')
                                }}
                                style={{ cursor: 'pointer', color: '#2361FF', fontSize: '11px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '200px', overflow: 'hidden', padding: '0 10px' }}>
                                {url}
                            </td>
                            <td width={'100'}>
                                {userData?.status.type_code === 100 &&  <a href={`/deliverable-view/${id}`}
                                    title={'copy link'}>
                                    <img src={copyIcon} alt={''} />
                                </a>}
                                {userData?.status.type_code === 300 &&
                                    <DeliverablesTabDropDownItem {...{ name, url, id, description, task_id, task }} />}
                            </td>
                        </tr>
                    ))
                }
            </table>
        </div>
    )
}