import React from 'react'
import './loader.scss'

const Loader:React.FC = () => (
  <div className={'parent__lds-ripple'}>
    <div className={'book'}>
      <div className={'inner'}>
        <div className={'left'} />
        <div className={'middle'} />
        <div className={'right'} />
      </div>
      <ul>
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
      </ul>
    </div>
  </div>
)

export default Loader
