import React, {Component, useCallback, useContext, useEffect, useRef, useState} from "react";
import {MainContextProvider} from "../../context/MainContext";
import ReactEditor from "../UI/ReactEditor/ReactEditor";
import InfiniteScroll from "react-infinite-scroll-component";
// @ts-ignore
import IntersectionObserver from "intersection-observer";
import customAxios from "../../service/api/interceptors";
import {ModalLoader} from "./ModalLoader/ModalLoader";



export const Test = () => {
    const { setLoading } = useContext(MainContextProvider)
    const [value, setValue] = useState('')

    useEffect(() => {
        setLoading(false)
    }, [])

    return <div>
        <ScrollComponent />
    </div>
}

const ScrollComponent = () => {
    const [items, setItems] = useState([])
    const contentRef = useRef()
    const [page, setPage] = useState(3)
    const [startPage, setStartPage] = useState(3)
    const [count, setCount] = useState(0)
    const pageCount = Math.ceil(count / 10)
    const [loading, setLoading] = useState(false)

    const getData = async () => {
        const { status, data } = await customAxios.get(`/company/offer/939/comments?per_page_count=10&page=${page}`)

        //const { status, data } = await customAxios.get(`/company/file/{file_id}/comments/page?per_page_count=10`)
        setCount(data.count)
        setItems(data.comments)
    }

    useEffect(() => {
        getData().then()
    }, [])

    /*const onScroll = () => {
        // @ts-ignore
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;

        if (scrollTop + clientHeight >= scrollHeight) {
            getElementAtBottom();
        }

        if (scrollTop === 0) {
            getElementAtTop();
        }
    }*/

    const onScroll = () => {
        // @ts-ignore
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;

        if ((Math.abs(scrollTop) + clientHeight + 0.5) >= scrollHeight) {
            getElementAtBottom(page + 1).then();
        }

        if (scrollTop === 0) {
            getElementAtTop().then();
        }
    }

    const getElementAtBottom = async (pageData: number) => {
        console.log('Достигнут низ блока content');

        if (+page >= +pageCount) {
            return;
        }

        setLoading(true)
        const { status, data } = await customAxios.get(`/company/offer/939/comments?per_page_count=10&page=${pageData}`)
        if (status === 200) {
            // @ts-ignore
            setItems(prevState => ([ ...prevState, ...data.comments ]))
            setPage(pageData)
        }
        setLoading(false)
    }

    const scrollToForId = (elementId: string) => {
        if (contentRef.current) {
            // @ts-ignore
            const targetElement = contentRef.current.querySelector(`#el_${elementId}`);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        }
        setLoading(false)
    }

    const getElementAtTop = async () => {
        console.log('Достигнут верх блока content');

        if (+startPage === 1) {
            return
        }

        setLoading(true)
        const { status, data } = await customAxios.get(`/company/offer/939/comments?per_page_count=10&page=${startPage - 1}`)
        if (status === 200) {
            // @ts-ignore
            setItems(prevState => ([ ...data.comments, ...prevState ]))
            setTimeout(() => scrollToForId(data.comments[data.comments.length - 1].id), 500)
            setStartPage(startPage - 1)
        }
    }

    return (
        <div
            // @ts-ignore
            ref={contentRef}
            style={{ overflow: 'auto', height: '300px', display: 'flex', flexDirection: 'column-reverse' }}
            onScroll={onScroll}
        >
            {loading && <ModalLoader />}
            {
                (items || []).map((el: any) => (
                    <div id={`el_${el.id}`} style={{ height: '50px', border: '1px solid #bbb', flexShrink: 0 }}>
                        <p dangerouslySetInnerHTML={{ __html: el.text }} />
                    </div>
                ))
            }
        </div>
    );
}

export default ScrollComponent;