import WaitingImage from '../../../assets/images/waiting-image.png'
import {useContext} from "react";
import {MainContextProvider} from "../../../context/MainContext";
import customAxios from "../../../service/api/interceptors";
import {toastifySuccess} from "../../../service/toastify/toastify";

export const DeliverableAccept = ({  }) => {
    const { setModalType, setModalData, userData, modalData, setLoading } = useContext(MainContextProvider)

    const accept = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`/company/deliverable/accept?id=${modalData?.id}`)
            if (status === 200) {
                toastifySuccess('Success')
                setModalData({ update: true })
                setModalType('')
            }
        } catch (e) {}
        setLoading(false)
    }

    const deny = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`/company/deliverable/deny?id=${modalData?.id}`)
            if (status === 200) {
                toastifySuccess('Success')
                setModalData({ update: true })
                setModalType('')
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <div>
            <img src={WaitingImage} alt={''}
                 style={{ width: '100px', display: 'block', margin: '20px auto 0 auto' }} />
            <p style={{ marginTop: '15px', textAlign: 'center' }}>
                A link to your deliverable has been copied successfully. You can now open a new tab and paste the link to review the deliverable. When you are satisfied with the work, click Approve below. If you have any questions, click “Send back to VLO
            </p>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
                <button
                    onClick={accept}
                    disabled={userData?.status.type_code !== 100}
                    className={'btn-black'} style={{ width: '49%' }}>Approve</button>
                <button
                    onClick={deny}
                    disabled={userData?.status.type_code !== 100}
                    className={'btn-black'}
                        style={{ width: '49%', color: '#000', background: '#fff', border: '2px solid #000', fontWeight: 'bold' }}>
                    Deny
                </button>
            </div>
        </div>
    )
}