import React from "react";

type Props = {
    otherProps: React.InputHTMLAttributes<HTMLInputElement>
    icon: any,
    error?: string
}

export const AuthInput: React.FC<Props> = ({ otherProps, icon, error }) => {
    return (
        <>
            <div className="auth-modal__input">
                <div className="auth-modal__input-icon">
                    {icon}
                </div>
                <input
                    {...otherProps}
                />
            </div>
            {error && <p className={'auth-modal__input-error'}>{error}</p>}
        </>
    )
}