import React from "react";
import icon from "../../../../assets/images/partnerRequest/taskAccepted.svg"
import {PartnerRequestType} from "../PartnerRequestType";
import {dateFormatter} from "../../../../helpers/dateFormatter";

type Props = {
    request: PartnerRequestType
}

export const PartnerRequestTaskClientAccepted: React.FC<Props> = ({ request }) => {
    return (
        <div className={'pr-client-tasks-accepted'}>
            <div className="pr-client-tasks-accepted__header">
                <img src={icon} alt=""/>
                <span>TASKS & ESTIMATES - CLIENT HAS ACCEPTED</span>
            </div>
            <div className="pr-client-tasks-accepted__body">
                <em>UPDATED ON: {dateFormatter(request?.updated_at)}</em>
                <p>
                    The task list and price & time estimates that you have provided have been accepted by the client.
                    They have been sent the Engagement Letter. Once they sign the Engagement Letter and pay the invoice
                    for your services - the works will begin.
                </p>
                {/*<a href="#">Expand Tasks & Estimates</a>*/}
            </div>
        </div>
    )
}