import { methodPost } from './api/api'
import { apiPath } from './api/constants'

import { DefaultResponseType } from '../types/custom/defaultResponse.type'
import { RequestMethodType } from '../types/custom/apiMethod.type'

const sendLegalTicket = async ({ body = {}, cancelToken }: RequestMethodType): Promise<DefaultResponseType<any>> => {
  const { headers: responseHeaders, data } = await methodPost({
    path: apiPath.legalTicket,
    body,
    cancelToken,
  })

  return {
    data,
    headers: responseHeaders,
  }
}

export {
  sendLegalTicket,
}
