import React from "react";
import finishIcon from '../../../../assets/images/registration/onboarding_finish_step.png'
import modules from './FinishStep.module.scss'

export const FinishStep = () => {
  return (
      <div className={modules.component}>
          <div className={modules.container}>
              <img className={modules.container_image} src={finishIcon} alt=""/>
              <h1 className={modules.container_heading}>
                  Please activate your account
              </h1>
              <p className={modules.container_text}>
                  Please click on the link that has just been sent to your email account to verify your email and continue the registration process.
              </p>
              {/*<a className={modules.button} href="/">Go to cabinet</a>*/}
          </div>
      </div>
  )
}