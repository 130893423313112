import React, {useRef, useState} from 'react'
import './tast-status.scss'
import { CSSTransition } from 'react-transition-group'
import toBottom from '../../../../../../assets/images/arrow-to-bottom-blue.svg'
import { TaskStatusType } from './TaskStatus.type'
import {useOnClickOutside} from "../../../../../../hooks/useOnClickOutside";

const TaskStatus:React.FC<TaskStatusType> = ({
  arr, current, onChange, disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const currentElement = current !== null ? arr.find((el) => String(el.value) === String(current)) : ''
  const dropdownRef = useRef<HTMLDivElement>(null)

  useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })

  return (
    <div className={'task-status'} ref={dropdownRef}>
      <div
        className={'task-status__current'}
        onClick={() => setIsOpen(!isOpen)}
        style={{ borderColor: disabled ? 'white' : '', cursor: disabled ? 'default' : 'pointer' }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={'task-status__body-item-circle'} style={{ background: currentElement && currentElement.color }} />
          {currentElement && currentElement.label}
        </div>
        {!disabled && <img src={toBottom} alt={''} />}
      </div>
      <CSSTransition in={isOpen && !disabled} timeout={200} classNames={'my-node'} unmountOnExit>
        <div className={'task-status__body'}>
          {
                        arr.map(({
                          value, label, color, textColor,
                        }) => (
                          <div
                            key={value}
                            onClick={() => {
                              onChange(value)
                              setIsOpen(false)
                            }}
                            className={'task-status__body-item'}
                            style={{ color: textColor }}
                          >
                            <div
                              className={'task-status__body-item-circle'}
                              style={{ background: textColor }}
                            />
                            {label}
                          </div>
                        ))
                    }
        </div>
      </CSSTransition>
    </div>
  )
}

export default TaskStatus
