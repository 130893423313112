import React, {useContext, useEffect, useState} from "react";
import {MainContextProvider} from "../../../context/MainContext";
import './allApproveDeliverables.scss'
import customAxios from "../../../service/api/interceptors";
import {toastifyError, toastifySuccess} from "../../../service/toastify/toastify";
import copyIcon from '../../../assets/images/copy2.svg'
import {ReactEditor} from "../../../components/UI";
import sendIcon from "../../../assets/images/send.svg";

export const AllApproveDeliverables: React.FC = () => {
    const { modalData, setLoading, approvedDeliverables, setApprovedDeliverables } = useContext(MainContextProvider)
    const [text, setText] = useState('')
    const [editedId, setEditedId] = useState('')

    const accept = async (id: number) => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`/company/deliverable/accept?id=${id}`)
            if (status === 200) {
                setApprovedDeliverables(approvedDeliverables.filter(el => el.id !== id))
                toastifySuccess('Deliverable has been completed.')

            }
        } catch (e) {}
        setLoading(false)
    }

    const sendMessage = async () => {
        const findEl = approvedDeliverables.find(el => el.id === editedId)
        try {
            const { status, data } = await customAxios.post(`/company/comment/comment/create-offer`, {
                offer_id:findEl.offer_id, text
            })

            if(status === 200) {
                setEditedId('')
            } else {
                toastifyError('Something went wrong!')
            }
        } catch (e) {}
        setLoading(false)

    }

    const deny = async (id: number) => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`/company/deliverable/deny?id=${id}`)
            if (status === 200) {
                setApprovedDeliverables(approvedDeliverables.filter((el: any) => +el.id !== +id))
                toastifySuccess('Deliverable has been completed.')
                setText('')
            }
        } catch (e) {}
        await sendMessage()
    }

    return (
        <div className={'task-tab-list'}>
            <div className="all_approve_deliverables__tabs">
                {/*<div className="task-tab-list__tab-item">
                    Offers
                </div>*/}
                <div className="all_approve_deliverables__tab-item">
                    Deliverables <span className={'all_approve_deliverables__tab-item-icon'}>{approvedDeliverables.length}</span>
                </div>
            </div>
            <h4>
                These are the Deliverables prepared for you  by your Virtual Legal Team that await your approval. Please review and approve them or request edits if necessary.
            </h4>
            {approvedDeliverables.length >= 1 ? <div className="all_approve_deliverables__list">
                <div className="all_approve_deliverables__list-heading">
                    <span>Task Name</span>
                    <span>Option</span>
                </div>
                {
                    approvedDeliverables.map((item: any) => (
                        <div className="all_approve_deliverables__list-item">
                            <div className={'all_approve_deliverables__list-container'}>
                                <div className="all_approve_deliverables__list-description">
                                    <div className="all_approve_deliverables__list-name">
                                        {item?.task.title}
                                    </div>
                                    <div className="all_approve_deliverables__list-link">
                                        <a target={'_blank'} href={item?.url}>
                                            {item?.url.slice(0, 40)}
                                        </a>
                                        <div
                                            onClick={() => {
                                                navigator.clipboard.writeText(item.url)
                                                toastifySuccess('Link has been copied')
                                            }}
                                            className="all_approve_deliverables__list-copy">
                                            <img src={copyIcon} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="all_approve_deliverables__list-buttons">
                                    <button
                                        title={item.company.debt === 'unsubscribed' ? 'You do not have an active Monthly Plan so this feature is unavailable. Please activate the Monthly Plan to unlock this feature.' : ''}
                                        disabled={item.company.debt === 'unsubscribed'}
                                        onClick={() => accept(item.id)}
                                        className={'all_approve_deliverables__button'}>Approve</button>
                                    {editedId !== item.id && <button
                                        title={item.company.debt === 'unsubscribed' ? 'You do not have an active Monthly Plan so this feature is unavailable. Please activate the Monthly Plan to unlock this feature.' : ''}
                                        disabled={item.company.debt === 'unsubscribed'}
                                        onClick={() => setEditedId(item.id)}
                                        className={'all_approve_deliverables__deny all_approve_deliverables__button'}>
                                        Request Edits
                                    </button>}
                                </div>
                            </div>
                            {editedId === item.id && <div className={'all_approve_deliverables__list-editor comment-list__input-futter'}>
                                <ReactEditor
                                    className={'comment-list__editor'}
                                    value={text} onChange={(e) => setText(e)}
                                    height={150}
                                    valid={true}
                                    placeholder={'Please type what you want changed in this deliverable'}
                                />
                                <button disabled={!text} onClick={() => {
                                    deny(item.id)
                                    setText('')
                                }} className={'comment-list__send-button'}>
                                    <img src={sendIcon} alt={''} />
                                </button>
                                {/* <input className='comment-list__input-image' type="image" src={clipIcon} /> */}
                            </div>}
                        </div>
                    ))
                }
            </div> : <div style={{ fontFamily: 'Inter', fontSize: '32px', lineHeight: '40px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '50px 0', textAlign: 'center' }}>
                No Deliverables require your<br /> approval for now.
            </div>}
        </div>
    )
}