import React, {useContext, useEffect, useState} from "react";
import "./header_timer.scss"
import {MainContextProvider} from "../../../context/MainContext";
import customAxios from "../../../service/api/interceptors";
import {CompanyType} from "../../../types/company.type";
import {toastifySuccess} from "../../../service/toastify/toastify";
import {HeaderTimerType} from "../../../helpers/types";
import ModalContainer from "../../ModalContainer/ModalContainer";
import Select from "../../UI/Select/Select";
import TextArea from "../../UI/TextArea/TextArea";
import {ModalLoader} from "../ModalLoader/ModalLoader";
import internal from "stream";
import {useParams} from "react-router-dom";

function formatNumber(number: number) {
    if (number === 0) {
        return '00'
    } else if (number > 0 && number < 10) {
        return `0${number}`
    } else {
        return number
    }
}

function formatTime(seconds: number) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(remainingSeconds)}`;
}

let timerInterval: NodeJS.Timeout | null = null;

export const HeaderTimer: React.FC = () => {
    const { headerTimer, userData, setHeaderTimer, currentTimer, setCurrentTimer } = useContext(MainContextProvider)
    const [timeElapsed, setTimeElapsed] = useState(0);
    const [loading, setLoading] = useState(false)

    const updateTime = () => {
        if (headerTimer?.created_at) {
            const now_timestamp = (+(new Date()) / 1000).toFixed()
            const seconds = +now_timestamp - (Number(headerTimer?.created_at))
            setTimeElapsed(seconds)
        }
    }

    const startTimer = () => {
        if (!timerInterval) {
            timerInterval = setInterval(updateTime, 1000)
        }
    }
    
    const stopTimer = async () => {
        setCurrentTimer('stop')
    }

    const setTimerData = async () => {
        const created_at = +((+(new Date()) / 1000).toFixed())

        try {
            const { status, data } = await customAxios.post(`/timely/event/add`, { company_id: headerTimer?.company_id, offer_id: String(headerTimer?.offer_id) })
            if (status === 200) {
                // @ts-ignore
                setHeaderTimer({ ...headerTimer, created_at, timer_id: data.id })
            }
        } catch (e) {}
    }

    const getCurrentEvent = async () => {
        setLoading(true)
        try {
            const { data: companyItems } = await customAxios.get(`/company/company/get-companies?expand=client,company`)
            const { status, data } = await customAxios.get(`/timely/current-event`)
            if (!data?.offer_id) {
                setLoading(false)
                setCurrentTimer('')
                return
            }
            const { data: offerData } = await customAxios.get(`/company/client/kanban/${data?.offer_id}?expand=project,company`)
            if (status === 200 && data) {
                // @ts-ignore
                setHeaderTimer({
                    created_at: data.start_at,
                    company_name: companyItems.find((el: CompanyType) => +el.id === +data.company_id)?.name,
                    company_id: data.company_id,
                    type: `Communication re Project #${offerData?.offer?.id}`,
                    timer_id: data.id,
                    offer_id: data.offer_id
                })
            }
        } catch (e) {}
        setLoading(false)
        setCurrentTimer('')
    }

    useEffect(() => {
        getCurrentEvent().then()
    }, [])

    useEffect(() => {
        if (headerTimer?.created_at) {
            const now_timestamp = (+(new Date()) / 1000).toFixed()
            const seconds = +now_timestamp - (Number(headerTimer?.created_at))
            setTimeElapsed(seconds)
            startTimer()
        } else {

        }
        /*else {
            if (!headerTimer?.created_at && timerInterval) {
                clearInterval(timerInterval)
            }
        }*/


        /*if (localStorage.getItem('timer')) {
            // @ts-ignore
            setHeaderTimer(JSON.parse(localStorage.getItem('timer')))
        }*/
    }, [headerTimer?.created_at])

    useEffect(() => {
        if ((currentTimer === 'stop' || currentTimer === 'redirect') && !headerTimer?.created_at) {
            setHeaderTimer(null)
            setCurrentTimer('')
        }
    }, [currentTimer])

    if (!headerTimer) {
        return <div />
    }

    if (loading) {
        return <div className={'header_timer'}><div className={'header_timer__type'}>Loading...</div></div>
    }

    return (
        <div className={'header_timer'}>
            <div className={'header_timer__info'}>
                <div className={'header_timer__company'}>
                    {headerTimer?.company_name}&nbsp;-&nbsp;
                </div>
                <div className={'header_timer__type'}>
                    {headerTimer?.type}
                </div>
            </div>
            <div className={'header_timer__block'}>
                {(headerTimer?.created_at) && formatTime(timeElapsed)}
            </div>
            <button onClick={(headerTimer?.created_at) ? stopTimer : setTimerData} style={{ borderColor: (headerTimer?.created_at) ? '#FF5C5C' : '#888888' }} className={'header_timer__button'}>
                {(headerTimer?.created_at) && <div className={'header_timer__square'}/>}
                {(!headerTimer?.created_at) && <div className={'header_timer__triangle'}/>}
            </button>
            <StopTimer isOpen={!!((currentTimer === 'stop' || currentTimer === 'redirect') && headerTimer?.created_at)} headerTimer={headerTimer} close={() => setCurrentTimer('')} setTimeElapsed={setTimeElapsed} />
        </div>
    )
}

const StopTimer = ({ isOpen, headerTimer, close, setTimeElapsed }: { isOpen: boolean, headerTimer: HeaderTimerType | null, close: any, setTimeElapsed: any }) => {
    const [tag, setTag] = useState('')
    const [tags, setTags] = useState<{ label: string, value: string }[]>([])
    const { setHeaderTimer, setCurrentTimer, currentTimer } = useContext(MainContextProvider)
    const [loading, setLoading] = useState(false)
    const [note, setNote] = useState('')
    const [notePlaceholder, setNotePlaceholder] = useState('')

    const stopTimer = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.put(`/timely/event/${headerTimer?.timer_id}/stop-timer`, {
                tag_id: tag, note: note || notePlaceholder
            })
            if (status === 200) {
                if (window.location.pathname.includes('offer') || window.location.pathname.includes('legal-request')) {
                    // @ts-ignore
                    setHeaderTimer({ ...headerTimer, created_at: null, offer_id: window.location.pathname.split('/')[2], type: `Communication re Project #${window.location.pathname.split('/')[2]}` })
                } else {
                    setHeaderTimer(null)
                }
                setTimeElapsed(0)
                // @ts-ignore
                clearInterval(timerInterval)
                timerInterval = null
                setCurrentTimer('')

                //window.location.reload()
            }
        } catch (e) {}
        setLoading(false)
    }

    const getTags = async () => {
        try {
            const { status, data } = await customAxios.get(`/timely/tags`)
            const arr: { label: string, value: string }[] = []
            Object.keys(data).reverse().map(key => {
                arr.push({ value: key + '', label: data[key] })
            })
            setTags(arr)
            setTag(arr[0].value)
        } catch (e) {

        }
    }

    useEffect(() => {
        if (isOpen) {
            getTags().then()
            setNotePlaceholder(headerTimer?.type + '')
            setNote(headerTimer?.type + '')
        }
    }, [isOpen])

    const modalClose = async () => {
        if (currentTimer === "redirect") {
            stopTimer().then()
            close()
        } else {
            close()
        }
    }

    return (
        <div className="create_manager_offer" style={{ position: 'relative' }}>
            {loading && <ModalLoader />}
            <ModalContainer isOpen={isOpen} heading={'Stop timer'} close={modalClose}>
                <div style={{ padding: '10px 0' }}>
                    <Select
                        label={'Tag'}
                        value={tag}
                        options={tags}
                        onChange={(e) => setTag(e.value)}
                    />
                    <div className={'mt10'}>
                        <TextArea
                            label={'Comment'}
                            otherProps={{
                                rows: 8,
                                value: note,
                                onChange: e => setNote(e.target.value),
                                placeholder: notePlaceholder
                            }}
                        />
                    </div>

                    <button onClick={stopTimer} className={'w100 btn-black text-uppercase mt15'}>stop timer</button>
                </div>
            </ModalContainer>
        </div>
    )
}